import React from "react";
import { Row, Col } from "reactstrap";
import { Line, Doughnut, HorizontalBar } from "react-chartjs-2";
import axios from "axios";
import API from "../../../helpers/api.jsx";

var SERVERDIR = process.env.REACT_APP_API;
const backgroundColor = [
  "#71DECF",
  "#8DE8DB",
  "#90D8CE",
  "#4CEBD5",
  "#42DCC7",
  "#04DDBF",
  "#00D0B4",
  "#02B79E",
  "#00BFA5",
  "#66BEB2",
  "#3B9E91",
  "#55CFC3",
  "#5ACBBC",
  "#4C958B",
  "#51A397",
  "#12A692",
  "#00A395",
  "#3E887E",
  "#008976",
  "#119482",
  "#008377",
  "#007368",
  "#096659",
  "#00413B",
  "#8BF9EA",
  "#B3F5EC",
  "#B8EEE7",
];
const hoverBackgroundColor = [
  "#B8EFE7",
  "#C6F3ED",
  "#C7EBE6",
  "#A6F5EA",
  "#A0EDE3",
  "#81EEDF",
  "#80E8D9",
  "#80DBCF",
  "#80DFD2",
  "#B3DFD9",
  "#9DCFC8",
  "#AAE7E1",
  "#ACE5DD",
  "#A5CAC5",
  "#A8D1CB",
  "#88D2C8",
  "#80D1CA",
  "#9FC3BE",
  "#80C4BB",
  "#88C9C0",
  "#80C1BB",
  "#80B9B3",
  "#84B2AC",
  "#80A09D",
  "#C5FCF4",
  "#D9FAF6",
  "#DCF6F3",
];
var studentsByGenderData = {
  labels: ["Male", "Female"],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#80cbc4", "#4db6ac"],
      hoverBackgroundColor: ["#80cbc4", "#4db6ac"],
    },
  ],
};
const primaryColor = "#D43272";
const accentColor = "#ff8a65";
const purpleColor = "#7f8ff4";

class CoursesStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attendedSessionsData: {},
      studentsAttendedSessionsCount: 0,
      createdCoursesCount: 0,
      createdSessionsCount: 0,
      taughtSessionsCount: 0,
      openSessionsCount: 0,
      studentsByGenderData: studentsByGenderData,
    };
  }
  async componentWillMount() {
    await this.getAttendedSessionsData();
    await this.getStudentsAttendedSessionsCount();
    await this.getCreatedCoursesCount();
    await this.getCreatedSessionsCount();
    await this.getTaughtSessionsCount();
    await this.getOpenSessionsCount();
  }

  async getCreatedCoursesCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Courses/count`, {
        params: {
          access_token: accessToken,
          where: { status: "New", deleted: false },
        },
      });
      if (res.status === 200) {
        this.setState({ createdCoursesCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getCreatedSessionsCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Sessions/count`, {
        params: {
          access_token: accessToken,
          where: { status: { neq: "New Draft" } },
        },
      });
      if (res.status === 200) {
        this.setState({ createdSessionsCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getTaughtSessionsCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Sessions/count`, {
        params: { access_token: accessToken, where: { status: "Completed" } },
      });
      if (res.status === 200) {
        this.setState({ taughtSessionsCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getOpenSessionsCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Sessions/count`, {
        params: {
          access_token: accessToken,
          where: { status: { inq: ["Hosted", "Full"] } },
        },
      });
      if (res.status === 200) {
        this.setState({ openSessionsCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getStudentsAttendedSessionsCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Profiles/TotalStudentsAttendedSessions`, {
        params: { access_token: accessToken },
      });
      if (res.status === 200) {
        this.setState({
          studentsAttendedSessionsCount: res.data.studentsAttendedSessions,
        });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  getAttendedSessionsData = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const attendedSessionsResult = await API.get(
        `Sessions/topTaugthDomains`,
        {
          params: { access_token: accessToken },
        }
      );

      if (attendedSessionsResult.status === 200) {
        const attendedSessions = attendedSessionsResult.data;
        const domains = attendedSessions.map((x) => x.Domain);
        const values = attendedSessions.map((x) => x.count);
        const attendedSessionsData = {
          labels: domains,
          datasets: [
            {
              data: values,
              label: "users",
              backgroundColor: "rgba(212, 50, 114,1)",
              borderColor: "rgba(212, 50, 114,0.8)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(212, 50, 114,0.8)",
              hoverBorderColor: "rgba(212, 50, 114,1)",
            },
          ],
        };
        this.setState({ attendedSessionsData: attendedSessionsData });
      } else {
        console.log("ageRange status :", attendedSessionsResult.status);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  render() {
    const {
      attendedSessionsData,
      createdCoursesCount,
      createdSessionsCount,
      taughtSessionsCount,
      openSessionsCount,
    } = this.state;
    const options12 = {
      legend: {
        display: false,
      },
    };

    const options32 = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              display: false,
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            categoryPercentage: 0.6,
            barPercentage: 0.5,
            maxBarThickness: 15,
            gridLines: {
              color: "rgba(0, 0, 0, 0.01)",
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      tooltips: {
        bodySpacing: 4,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        xPadding: 30,
        yPadding: 10,
        caretPadding: 10,
        titleMarginBottom: 2,
      },
    };

    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Courses statistics</h1>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-people icon-lg icon-primary"
                      style={{ fontSize: "45px" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{this.state.studentsAttendedSessionsCount}</b>
                    </h2>
                    <h4 className="widtag">Students attended courses</h4>
                  </div>
                </div>
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-book-open icon-lg icon-primary"
                      style={{ fontSize: "45px" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{createdCoursesCount}</b>
                    </h2>
                    <h4 className="widtag">Courses created</h4>
                  </div>
                </div>
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-graduation icon-lg icon-primary"
                      style={{ fontSize: "45px" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{createdSessionsCount}</b>
                    </h2>
                    <h4 className="widtag">Created Sessions</h4>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-graduation icon-lg icon-primary"
                      style={{ fontSize: "45px", color: "#00BFA5" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{taughtSessionsCount}</b>
                    </h2>
                    <h4 className="widtag">Taught sessions</h4>
                  </div>
                </div>
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-graduation icon-lg icon-primary"
                      style={{ fontSize: "45px", color: "#505458" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{"0"}</b>
                    </h2>
                    <h4 className="widtag">Cancelled Sessions</h4>
                  </div>
                </div>
                <div
                  className="db_box iconbox col-md-4"
                  style={{
                    boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",
                    height: "230px",
                    borderRadius: "15px",
                    marginRight: "30px",
                    maxWidth: "31%",
                  }}
                >
                  <div className="widdata">
                    <i
                      className="widicon i-graduation icon-lg icon-primary"
                      style={{ fontSize: "45px", color: "#FF8A65" }}
                    ></i>
                    <h2 className="widtitle" style={{ fontSize: "30px" }}>
                      <b>{openSessionsCount}</b>
                    </h2>
                    <h4 className="widtag">Open Sessions</h4>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 d-flex chart-card"
                style={{
                  marginBottom: "30px",
                  width: "94%",
                  marginLeft: "15px",
                  paddingRight: "4px",
                }}
              >
                <section /* className="box " */>
                  <header className="panel_header">
                    <h2 className="title float-left chart-title">
                      Top 10 domains taught
                    </h2>
                  </header>
                  <div className="content-body">
                    {" "}
                    <div className="row">
                      <div className="col-12">
                        <div className="chart-container">
                          <div className="chart-area">
                            <HorizontalBar
                              data={attendedSessionsData}
                              options={options32}
                              height={325}
                              width={800}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CoursesStatistics;
