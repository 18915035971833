import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

var IMGDIR = process.env.REACT_APP_IMGDIR;
var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

export default function ModalProfilePreview({isOpen , close, profile}) {
    const renderObjectTags = (tagsList) => {
      if (tagsList && tagsList.length > 0) {
        return (
          <div>
            {tagsList.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag.name}</span>
              </li>
            ))}
          </div>
        );
      } else {
        return null;
      }
    };
    const renderStringTags = (tagsList) => {
      if (tagsList && tagsList.length > 0) {
        return (
          <div>
            {tagsList.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag}</span>
              </li>
            ))}
          </div>
        );
      } else {
        return null;
      }
    };
    return (
      <div>
        <Modal isOpen={isOpen} toggle={close}>
          <ModalHeader style={{ padding: "0.5rem 0.5rem" }} toggle={close}>
            <span style={{ fontWeight: "bold" }}>User Information</span>
          </ModalHeader>
          <ModalBody className="pt-1 pr-0 pb-1 pl-0">
            <div className="col-xl-12">
              <section>
                <div className="content-body">
                  <div className="col-12">
                    <div className="row uprofile">
                      <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                        <img
                          alt=""
                          src={
                            profile && profile.image && profile.image.name
                              ? `${SERVERDIR}Containers/img/download/${profile.image.name}`
                              : `${IMGDIR}/images/profile/profile-general.jpg`
                          }
                          className="img-fluid"
                        />
                      </div>
                      <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                        <h5 className="uprofile-owner">
                          <a href="#!">{profile && profile.fullName}</a>
                        </h5>
                        <div className="clearfix"></div>
                        <div className="social-media-icons">
                          <ul className="list-inline list-unstyled">
                            <li className="list-inline-item">
                              <a
                                href={
                                  (profile &&
                                    profile.email &&
                                    profile.email.length > 0 &&
                                    "mailto:" + profile.email) ||
                                  "#"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i
                                  className="fa fa-envelope-square"
                                  style={{
                                    color:
                                      profile &&
                                      profile.email &&
                                      profile.email.length
                                        ? "#D43272"
                                        : "#606060",
                                    fontSize: "23px",
                                  }}
                                ></i>
                              </a>
                            </li>
                            {profile.linkedinUrl && profile.linkedinUrl.length && (
                              <li className="list-inline-item">
                                <a
                                  href={`//${
                                    profile.linkedinUrl != null &&
                                    profile.linkedinUrl.replace(
                                      /(^\w+:|^)\/\//,
                                      ""
                                    )
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-linkedin-square "
                                    style={{
                                      color:
                                        profile.linkedinUrl &&
                                        profile.linkedinUrl.length
                                          ? "#D43272"
                                          : "#606060",
                                      fontSize: "23px",
                                    }}
                                  ></i>
                                </a>
                              </li>
                            )}
                            {profile.facebookUrl && profile.facebookUrl.length && (
                              <li className="list-inline-item">
                                <a
                                  href={`//${
                                    profile.facebookUrl != null &&
                                    profile.facebookUrl.replace(
                                      /(^\w+:|^)\/\//,
                                      ""
                                    )
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-facebook-square "
                                    style={{
                                      color:
                                        profile.facebookUrl &&
                                        profile.facebookUrl.length
                                          ? "#D43272"
                                          : "#606060",
                                      fontSize: "23px",
                                    }}
                                  ></i>
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                        <p className="uprofile-title">
                          {profile && profile.profession}
                        </p>
                        <div className="clearfix"></div>
                        <p className="uprofile-list">
                            <span><i className='i-phone'></i> {profile && profile.phone}</span>
                            <span><i className='i-map'></i> {profile && profile.country}</span>
                            <span><i className='i-pin'></i> {profile && profile.governorate}</span>
                            <span><i className='i-user'></i> {profile && profile.gender}</span>
                            {profile.age && <span><i className='i-calendar'></i> {profile && profile.age} ans</span>}
                          </p>
                      </div>
                    </div>
                    <div className="row profile-details">
                      {profile &&
                      profile.goal &&
                      profile.goal.length > 0 ? (
                        <>
                            <h5 className="font-weight-bold">Objectif</h5>
                            <p>{profile && profile.goal}</p>
                        </>
                      ) : null}
                      {profile &&
                      profile.state &&
                      profile.state.length > 0 ? (
                        <>
                            <h5 className="font-weight-bold">État</h5>
                            <p>{profile && profile.state}</p>
                        </>
                      ) : null}
                      {profile &&
                      profile.description &&
                      profile.description.length > 0 ? (
                        <h5 className="font-weight-bold">Biography</h5>
                      ) : null}
                      <p>{profile && profile.description}</p>
                      {profile &&
                      profile.interests &&
                      profile.interests.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Interests</h5>
                          {renderObjectTags(profile && profile.interests)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.interest &&
                      profile.interest.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Interests</h5>
                          {renderStringTags(profile && profile.interest)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.expertises &&
                      profile.expertises.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Expertises</h5>
                          {renderObjectTags(profile && profile.expertises)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.education &&
                      profile.education.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Education</h5>
                          {renderStringTags(profile && profile.education)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.competencies &&
                      profile.competencies.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Competencies</h5>
                          {renderStringTags(profile && profile.competencies)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.experience &&
                      profile.experience.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Experience</h5>
                          {renderStringTags(profile && profile.experience)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.subjects &&
                      profile.subjects.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Subjects</h5>
                          {renderStringTags(profile && profile.subjects)}
                        </div>
                      ) : null}
                      {profile &&
                      profile.certifications &&
                      profile.certifications.length > 0 ? (
                        <div>
                          <hr />
                          <h5 className="font-weight-bold">Certifications</h5>
                          {renderStringTags(profile && profile.certifications)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </ModalBody>
          <ModalFooter style={{ alignSelf: "center" }}>
            <Button
              style={{ height: "35px", width: "98px", padding: "0px" }}
              color="primary"
              onClick={close}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
}
