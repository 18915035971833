import axios from 'axios';
import {handleLogout} from "./common";

const instance =  axios.create({
  baseURL: process.env.REACT_APP_API
});

instance.interceptors.response.use((response) => {
  if (response) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  const {
    response: {
      data: {
        error: { code, message }
      }
    } = {}
  } = error;
  if(error.message === "Request failed with status code 401" && (!code.includes("LOGIN_FAILED")) && (!window.location.href.includes("/login"))) {
    handleLogout();
    window.location.href = "/";
  }

  return Promise.reject(error);
});

export default instance;
