import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import API from "../../../helpers/api.jsx";
/* import Loader from "react-loader-spinner";
import { members } from 'variables/general/members.jsx'; */
import { notify } from "../../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
/* import { ExportToCsv } from "export-to-csv"; */
import ModalConfirmation from "../../../components/modals/ModalConfirmation.jsx";
import ReactPaginate from "react-paginate";

import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";

import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
const statusList = ["Status", "New", "Hosted", "Pre Host", "Full"];
const difficultiesList = ["Level", "Beginner", "Intermediate", "Advanced"];
/* const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};
const csvExporter = new ExportToCsv(options); */

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
      overlayStyle={{ zIndex: 9999 }}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const wrapperStyle = {
  width: "100%",
  margin: "25px 0px 0px 5px",
  paddingRight: "15px",
};
const priceMarks = {
  0: <strong>0 TND</strong>,
  100: <strong>100&nbsp;TND</strong>,
};
const attendanceMarks = {
  0: <strong>0 %</strong>,
  100: <strong>100&nbsp;%</strong>,
};

class MySessions extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sessions: [],
      limit: 20,
      count: 0,
      totalCount: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      modalSearch: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      indexMute: "",
      indexRevoke: "",
      sessionToDelete: "",
      modalDelete: false,
      activated: false,
      tooltipOpen: false,
      modalConfirmAction: false,
      idSession: "",
      actionValue: false,
      selectedSessionId: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      modalTagsTitle: "",
      isSearchResult: false,
      searchQuery: "",
      filter: {
        keyword: "",
        price: 0,
        percent: 0,
        difficulty: "",
        status: "",
      },
      date: {
        start: "",
        end: "",
      },
      prevPrice: 0,
      prevPercent: 0,
      prevDate: moment.range(today.clone(), today.clone()),
      dateRangeValue: moment.range(today.clone(), today.clone()),
    };
  }

  onSelectDateRange = (dateRangeValue, states) => {
    this.setState({ dateRangeValue, states });
  };

  onToggleDateRangePicker = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  renderSelectionValue = () => {
    if (
      !(
        this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
          this.state.dateRangeValue.end.format("YYYY-MM-DD") &&
        this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
      )
    ) {
      console.log(this.state.dateRangeValue.start);

      return (
        <div>
          <div>Selection</div>
          <h5>
            {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
            {" - "}
            {this.state.dateRangeValue.end.format("YYYY-MM-DD")}
          </h5>
        </div>
      );
    } else {
      return null;
    }
  };

  async componentWillMount() {
    await this.getSessions(this.state.active);
    console.log("hello", this.state.sessions);
    /* this.getCount();
    
    console.log("my role", this.state.userProfile.role); */
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handlePriceInputChange = (value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        price: value,
      },
    });
  };
  handleAttendanceInputChange = (value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        percent: value,
      },
    });
  };
  handleDifficultiesInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          difficulty: target.value === "Level" ? "" : target.value,
        },
      },
      () => this.getSearchedSessions(1)
    );
  };
  handleStatusInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          status: target.value === "Status" ? "" : target.value,
        },
      },
      () => this.getSearchedSessions(1)
    );
  };

  /* toggleTooltip=(target)=> {

    this.setState({
      [target]: !this.state[target]
    });
  } */

  //Export DATA

  /* exportData = async () => {
    if (this.state.keyword === "") {
      const sessions = await axios.get(
        `${SERVERDIR}Sessions/export?type=members&all=true&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (sessions.status === 200) {
        csvExporter.generateCsv(sessions.data);
      } else {
        return;
      }
    } else {
      const filters = encodeURIComponent(
        JSON.stringify({ text: this.state.text })
      );
      const sessions = await axios.get(
        `${SERVERDIR}Sessions/export?type=members&all=true&filters=${filters}&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (sessions.status === 200) {
        csvExporter.generateCsv(sessions.data);
      } else {
        return;
      }
    }
  }; */

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getSessions = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const userId = localStorage.getItem("WeCodeLand_user");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        searchQuery: "",
      });
      const sessions = await API.get(
        `Sessions/getListSessions`,
        {
          params: {
            access_token: accessToken,
            limit: limit,
            skip: skip,
            teacherId: userId,
          },
        }
        /* ?limit=${limit}&skip=${skip}&access_token=${accessToken}` */
      );

      if (sessions.status === 200) {
        console.log("sessions content :", sessions.data);
        const pageCount = Math.ceil(sessions.data.count / this.state.limit);
        this.setState({ count: pageCount, totalCount: sessions.data.count });

        this.setState(
          {
            csvData: sessions.data.sessions,
            active: key,
            loading: false,
            sessions: sessions.data.sessions,
          },
          () => {}
        );
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };

  cancelSearch = () => {
    this.setState(
      {
        active: 1,
        filter: {
          ...this.state.filter,
          keyword: "",
          price: 0,
          percent: 0,
          difficulty: "",
          status: "",
        },
      },
      () => window.history.pushState(null, null, BASEDIR + `/mySessions/${1}`)
    );
    this.getSessions(1);
  };

  ///Search
  getSearchedSessions = async (key) => {
    const userId = localStorage.getItem("WeCodeLand_user");
    const limit = this.state.limit;
    const { filter } = this.state;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    console.log("this.checkProperties(filter)", this.checkProperties(filter));
    console.log("filter)", filter);

    if (
      this.checkProperties(filter) &&
      this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
        this.state.dateRangeValue.end.format("YYYY-MM-DD") &&
      this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    ) {
      this.getSessions(1);
      return;
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
          searchQuery: this.state.filter.keyword,
        });
        const params = {
          access_token: accessToken,
          content: filter,
          skip: (key - 1) * limit,
          limit: limit,
          teacherId: userId,
        };
        if (
          !(
            this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
              this.state.dateRangeValue.end.format("YYYY-MM-DD") &&
            this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
          )
        ) {
          params.date = {
            start: this.state.dateRangeValue.start,
            end: this.state.dateRangeValue.end,
          };
        }
        const sessions = await API.get(`Sessions/search`, { params: params });

        if (sessions.status === 200) {
          const pageCount = Math.ceil(sessions.data.count / this.state.limit);
          this.setState({ count: pageCount, totalCount: sessions.data.count });
          console.log("sessions searched content :", sessions.data.sessions);

          this.setState(
            {
              csvData: sessions.data.sessions,
              active: key,
              loading: false,
              sessions: sessions.data.sessions,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };
  openModalSearch = (prevPrice, prevPercent, prevDate) => {
    this.setState({
      modalSearch: !this.state.modalSearch,
      prevPrice: prevPrice,
      prevPercent: prevPercent,
      prevDate: prevDate,
    });
  };
  cancelAdvancedSearch = () => {
    if (this.state.prevPrice !== this.state.filter.price) {
      this.setState({
        filter: {
          ...this.state.filter,
          price: this.state.prevPrice,
        },
      });
    }
    if (this.state.prevPercent !== this.state.filter.percent) {
      this.setState({
        filter: {
          ...this.state.filter,
          percent: this.state.prevPercent,
        },
      });
    }
    if (
      this.state.prevDate.start !== this.state.dateRangeValue.start ||
      this.state.prevDate.end !== this.state.dateRangeValue.end
    ) {
      this.setState({
        dateRangeValue: moment.range(moment().clone(), moment().clone()),
      });
    }
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };
  resetPrice = () => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          price: 0,
        },
      },
      () => this.getSearchedSessions(1)
    );
  };
  resetPercent = () => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          percent: 0,
        },
      },
      () => this.getSearchedSessions(1)
    );
  };
  resetDate = () => {
    this.setState(
      {
        dateRangeValue: moment.range(moment().clone(), moment().clone()),
      },
      () => this.getSearchedSessions(1)
    );
  };
  confirmSearch = () => {
    this.setState(
      {
        modalSearch: !this.state.modalSearch,
      },
      () => this.getSearchedSessions(1)
    );
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedSessions(selectedPage + 1);
    } else {
      this.getSessions(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/mySessions/${selectedPage + 1}`
    );
  };

  handleLevelColor = (level) => {
    if (level && level.toUpperCase() === "BEGINNER") {
      return "#00BFA5";
    } else if (level && level.toUpperCase() === "INTERMEDIATE") {
      return "#FF8A65";
    } else if (level && level.toUpperCase() === "ADVANCED") {
      return "#E6413D";
    } else {
      return "#000";
    }
  };

  handleSearchInput = async (e) => {
    const target = e.target;

    this.setState({
      filter: {
        ...this.state.filter,
        keyword: target.value,
      },
    });
  };

  openModalConfirmAction = (id, value, status) => {
    if (status === "New") {
      this.setState({
        modalConfirmAction: !this.state.modalConfirmAction,
        actionValue: value,
        selectedSessionId: id,
      });
    } else return null;
  };
  toggleModalConfirmAction = () => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
    });
  };

  handleStatusChange = async () => {
    console.log(this.state.id);
    const selectedSessionId = this.state.selectedSessionId;
    const value = this.state.actionValue;
    try {
      let result = await API.post(`Sessions/acceptRefuse`, null, {
        params: {
          sessionId: selectedSessionId,
          value: value,
          access_token: localStorage.getItem("WeCodeLand_jwt"),
        },
      });
      if (result.status === 200) {
        console.log("Action done SUCCESSFULLY");
        notify(
          {
            type: "success",
            message: value ? "Session Accepted" : "Session Refused",
          },
          this
        );
        this.getSessions(this.state.active);
        this.setState({
          loading: false,
          modalConfirmAction: !this.state.modalConfirmAction,
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      await this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };
  openModalDelete = (id) => {
    this.setState({
      sessionToDelete: id,
      modalDelete: !this.state.modalDelete,
    });
  };
  toggleModalDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
    });
  };
  handleDeleteSession = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const { sessions, sessionToDelete } = this.state;

    this.setState({
      loading: true,
    });

    try {
      // delete session
      const deletedSession = await API.patch(
        `Sessions/deleteSession?access_token=${accessToken}&sessionId=${sessionToDelete}`
      );

      if (deletedSession.status === 200) {
        notify({ type: "success", message: "Session deleted" }, this);
        this.setState({ modalDelete: false });
        this.getSessions(this.state.active);
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
      console.log("error: ", error);
    }
  };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const { sessions, actionValue } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">My Sessions</h1>
                </div>
                <div className="float-right seach-filter-wrapper">
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.getSearchedSessions(1);
                    }}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.getSearchedSessions(1)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        className="search-input"
                        placeholder={"Search..."}
                        value={this.state.filter.keyword}
                        onChange={this.handleSearchInput}
                      />
                      <span
                        onClick={this.cancelSearch}
                        style={{
                          display:
                            this.state.searchQuery !== "" ? "block" : "none",
                        }}
                        className="fa fa-trash cancel-search"
                      ></span>
                    </InputGroup>
                  </form>
                  <div className="advanced-search-text">
                    <span
                      onClick={() =>
                        this.openModalSearch(
                          this.state.filter.price,
                          this.state.filter.percent,
                          this.state.dateRangeValue
                        )
                      }
                    >
                      Advanced Search
                    </span>
                    <div className="filter-results">
                      {this.state.filter.percent > 0 ? (
                        <div>
                          <span>Attendance : {this.state.filter.percent}%</span>
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetPercent}
                          ></span>
                        </div>
                      ) : null}
                      {this.state.filter.price > 0 ? (
                        <div>
                          <span>&nbsp;Price : {this.state.filter.price}</span>
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetPrice}
                          ></span>
                        </div>
                      ) : null}
                      {!(
                        this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
                          this.state.dateRangeValue.end.format("YYYY-MM-DD") &&
                        this.state.dateRangeValue.start.format("YYYY-MM-DD") ===
                          moment().format("YYYY-MM-DD")
                      ) ? (
                        <div className="date-filter">
                          {"Date : "}{" "}
                          {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
                          {" - "}
                          {this.state.dateRangeValue.end.format("YYYY-MM-DD")}
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetDate}
                          ></span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {this.state.isSearchResult ? (
                          <span className="results-count">
                            {this.state.totalCount} results were found
                          </span>
                        ) : null}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th className="fullName">Session Name</th>
                              <th className="centered-column-text">
                                Classroom
                              </th>
                              <th className="centered-column-text">
                                Participants
                              </th>
                              {/* <th className="centered-column-text">Status</th> */}
                              <th>
                                <select
                                  className="dropdown-column-text filter-col"
                                  id="inputStatus"
                                  onChange={this.handleStatusInputChange}
                                  value={this.state.filter.status}
                                  required
                                >
                                  {statusList.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th>
                              <th className="centered-column-text">From-To</th>
                              {/* <th className="centered-column-text">Level</th> */}
                              <th>
                                <select
                                  className="dropdown-column-text filter-col"
                                  id="inputGender"
                                  onChange={this.handleDifficultiesInputChange}
                                  value={this.state.filter.difficulty}
                                  required
                                >
                                  {difficultiesList.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th>
                              <th className="centered-column-text">Edit</th>
                              <th className="centered-column-text">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(sessions)}

                            {sessions.map((session, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    className="text-cell"
                                    id={`Tooltip${key}`}
                                  >
                                    <span
                                      className="hoverable-text"
                                      onClick={() =>
                                        window.open(
                                          `${BASEDIR}/session/${
                                            session && session.id
                                          }`
                                        )
                                      }
                                    >
                                      {session && session.title}
                                    </span>
                                  </td>
                                  <td className="centered-column-text clickable">
                                    <span
                                      className="hoverable-text"
                                      onClick={
                                        session &&
                                        session.sessionBooking &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ) &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ).bookingRoom &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ).bookingRoom.name &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ).bookingRoom.name.length > 0
                                          ? () =>
                                              window.open(
                                                `${BASEDIR}/classroom/${
                                                  session &&
                                                  session.sessionBooking &&
                                                  session.sessionBooking.find(
                                                    (o) => o.status === 1
                                                  ) &&
                                                  session.sessionBooking.find(
                                                    (o) => o.status === 1
                                                  ).bookingRoom &&
                                                  session.sessionBooking.find(
                                                    (o) => o.status === 1
                                                  ).bookingRoom.id
                                                }`
                                              )
                                          : null
                                      }
                                    >
                                      {session &&
                                        session.sessionBooking &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ) &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ).bookingRoom &&
                                        session.sessionBooking.find(
                                          (o) => o.status === 1
                                        ).bookingRoom.name}
                                    </span>
                                  </td>
                                  <td className="centered-column-text">
                                    <span className="purple clickable">
                                      {session && session.subscribedNumber} on{" "}
                                      {(session &&
                                        session.status === "Hosted") ||
                                      (session && session.status === "Full")
                                        ? session && session.maxParticipants
                                        : session && session.maxPlace}
                                    </span>
                                  </td>
                                  <td className="centered-column-text">
                                    {session && session.deleted === true
                                      ? "Deleted"
                                      : session && session.status}
                                  </td>
                                  <td className="centered-column-text">
                                    {session && session.start ? (
                                      <span>
                                        {moment(
                                          session && session.start
                                        ).format("D/MM/YYYY")}
                                        -
                                        {moment(session && session.end).format(
                                          "D/MM/YYYY"
                                        )}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td
                                    className="centered-column-text bold"
                                    style={{
                                      color: this.handleLevelColor(
                                        session && session.difficulty
                                      ),
                                    }}
                                  >
                                    {session && session.difficulty}
                                  </td>
                                  <td className="centered-column-text">
                                    {session &&
                                      session.deleted !== true &&
                                      session &&
                                      session.status !== "Completed" &&
                                      session &&
                                      session.status !== "Full" && (
                                        <span
                                          className="purple clickable"
                                          onClick={
                                            session &&
                                            session.deleted !== true &&
                                            session &&
                                            session.status !== "Completed" &&
                                            session &&
                                            session.status !== "Full"
                                              ? () => {
                                                  this.props.history.push(
                                                    `${BASEDIR}/editSession/${
                                                      session && session.id
                                                    }`
                                                  );
                                                }
                                              : null
                                          }
                                        >
                                          Edit
                                        </span>
                                      )}
                                  </td>
                                  <td className="centered-column-text">
                                    <i
                                      className="clickable fa fa-trash-o text-danger"
                                      style={{ fontSize: "17px" }}
                                      onClick={
                                        session && session.deleted !== true
                                          ? () => {
                                              this.openModalDelete(
                                                session && session.id
                                              );
                                            }
                                          : null
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                            {sessions.length === 0 && (
                              <tr className="text-center">
                                <td colspan="10">
                                  <b>Oops! no data found</b>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>

        <div>
          <ModalConfirmation
            isOpen={this.state.modalConfirmAction}
            toggle={this.toggleModalConfirmAction}
            disabledButton={false}
            confirm={this.handleStatusChange}
            cancel={this.toggleModalConfirmAction}
            title={actionValue ? "Accept Session" : "Refuse Session"}
            body={
              actionValue
                ? "Are you sure you want to accept this session?"
                : "Are you sure you want to refuse this session?"
            }
            confirmText={actionValue ? "Accept" : "Refuse"}
          />
        </div>

        <div>
          <Modal
            isOpen={this.state.modalSearch}
            toggle={this.toggleModalSearch}
            className={this.props.className}
            backdrop={"static"}
            keyboard={false}
          >
            <ModalHeader toggle={this.toggleModalSearch}>
              <span className="bold">Advanced Search</span>
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="form-group">
                  <label className="form-label bold" htmlFor="field-1">
                    Price per person
                  </label>
                  <br />
                  <label>Please Slide to select a price</label>
                  <div style={wrapperStyle}>
                    <Slider
                      min={0}
                      max={100}
                      defaultValue={this.state.filter.price}
                      onChange={this.handlePriceInputChange}
                      handle={handle}
                      marks={priceMarks}
                    />
                  </div>
                </div>
                <br />
                <div className="form-group">
                  <label className="form-label bold" htmlFor="field-1">
                    Attendance Rate
                  </label>
                  <br />
                  <label>Please Slide to select a percentage</label>
                  <div style={wrapperStyle}>
                    <Slider
                      min={0}
                      max={100}
                      defaultValue={this.state.filter.percent}
                      onChange={this.handleAttendanceInputChange}
                      handle={handle}
                      marks={attendanceMarks}
                    />
                  </div>
                </div>
                <br />
                <div className="form-group">
                  <label className="form-label bold" htmlFor="field-1">
                    Date
                  </label>
                  <br />
                  <label>Please Select a date range</label>
                  <div>{this.renderSelectionValue()}</div>
                  <div>
                    <input
                      className="btn"
                      type="button"
                      value="Toggle date picker"
                      onClick={this.onToggleDateRangePicker}
                    />
                  </div>

                  {this.state.isOpen && (
                    <DateRangePicker
                      value={this.state.dateRangeValue}
                      onSelect={this.onSelectDateRange}
                      singleDateRange={true}
                      firstOfWeek={1}
                      numberOfCalendars={2}
                      selectionType="range"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <span
                className="cancel-search-modal"
                onClick={this.cancelAdvancedSearch}
              >
                Cancel
              </span>
              <Button
                className="confirm-modal"
                color="primary"
                onClick={this.confirmSearch}
              >
                Search
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <ModalConfirmation
          isOpen={this.state.modalDelete}
          toggle={this.toggleModalDelete}
          disabledButton={false}
          confirm={this.handleDeleteSession}
          cancel={this.toggleModalDelete}
          title={"Delete session"}
          body={
            "Are you sure you want to delete this session? This process cannot be undone"
          }
          confirmText={"Confirm"}
        />
      </div>
    );
  }
}

export default MySessions;
