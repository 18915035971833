import React from "react";
import { Button, Row, Col, Table } from "reactstrap";
import API from "./../../../helpers/api";
/* import Loader from "react-loader-spinner"; */
// import { members } from 'variables/general/members.jsx';
import moment from "moment";
import { Link } from "react-router-dom";
import { notify } from "./../../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
/* import { ExportToCsv } from "export-to-csv"; */
import ModalConfirmation from "./../../../components/modals/ModalConfirmation";
import ModalTags from "./../../../components/modals/ModalTags";
import ReactPaginate from "react-paginate";
var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;
/* const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};
const csvExporter = new ExportToCsv(options); */

class HostingRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      limit: 20,
      count: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      modalTags: false,
      modalBlock: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      indexMute: "",
      indexRevoke: "",
      activated: false,
      tooltipOpen: false,
      modalConfirmAction: false,
      roomId: "",
      sessionId: "",
      actionValue: false,
      indexAction: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      isSearchResult: false,
      isFilterResult: false,
      filter: {
        gender: "",
        country: "",
        role: "",
      },
    };
  }

  async componentWillMount() {
    await this.getBookings(this.state.active);
    console.log("hello", this.state.bookings);
    /* this.getCount();
    
    console.log("my role", this.state.userProfile.role); */
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGendersInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          gender: target.value,
        },
      },
      () => this.getFilteredBookings(1)
    );
  };
  handleGovernorateInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          country: target.value,
        },
      },
      () => this.getFilteredBookings(1)
    );
  };
  handleRolesInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          role: target.value,
        },
      },
      () => this.getFilteredBookings(1)
    );
  };

  /* toggleTooltip=(target)=> {

    this.setState({
      [target]: !this.state[target]
    });
  } */

  //Export DATA

  /* exportData = async () => {
    if (this.state.text === "") {
      const bookings = await axios.get(
        `${SERVERDIR}Bookings/export?type=members&all=true&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (bookings.status === 200) {
        csvExporter.generateCsv(bookings.data);
      } else {
        return;
      }
    } else {
      const filters = encodeURIComponent(
        JSON.stringify({ text: this.state.text })
      );
      const bookings = await axios.get(
        `${SERVERDIR}Bookings/export?type=members&all=true&filters=${filters}&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (bookings.status === 200) {
        csvExporter.generateCsv(bookings.data);
      } else {
        return;
      }
    }
  }; */

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getBookings = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const hostId = localStorage.getItem("WeCodeLand_user");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        isFilterResult: false,
      });
      const bookings = await API.get(`Bookings/list`, {
        params: {
          hostId: hostId,
          limit: limit,
          skip: skip,
          access_token: accessToken,
        },
      });

      if (bookings.status === 200) {
        console.log("bookings content :", bookings.data);
        const pageCount = Math.ceil(bookings.data.count / this.state.limit);
        /* this.setState({ count: pageCount }); */

        this.setState({
          count: pageCount,
          csvData: bookings.data.bookingList,
          active: key,
          loading: false,
          bookings: bookings.data.bookingList,
        });
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };

  cancelSearch = () => {
    this.setState({ text: "", active: 1 }, () =>
      window.history.pushState(null, null, BASEDIR + `/hostingResquests/${1}`)
    );
    this.getBookings(1);
  };

  ///Search
  getSearchedBookings = async (key) => {
    const limit = this.state.limit;
    const text = this.state.text;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (text === "") {
      this.getBookings(1);
      return;
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
          filter: {
            gender: "",
            country: "",
            role: "",
          },
        });
        const bookings = await API.get(
          `Bookings/search?text=${text}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (bookings.status === 200) {
          const pageCount = Math.ceil(bookings.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("bookings searched content :", bookings.data.bookingList);

          this.setState(
            {
              csvData: bookings.data.bookingList,
              active: key,
              loading: false,
              bookings: bookings.data.bookingList,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };
  ///Filter
  getFilteredBookings = async (key) => {
    const limit = this.state.limit;
    const { filter } = this.state;
    var search = encodeURIComponent(JSON.stringify(filter));
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (
      this.checkProperties(filter) ||
      filter.country === "Governorate" ||
      filter.gender === "All Users" ||
      filter.role === "Role"
    ) {
      if (filter.country === "Governorate") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              country: "",
            },
          },
          () => {
            this.getFilteredBookings(1);
            return;
          }
        );
      }
      if (filter.gender === "All Users") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              gender: "",
            },
          },
          () => {
            this.getFilteredBookings(1);
            return;
          }
        );
      }
      if (filter.role === "Role") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              role: "",
            },
          },
          () => {
            this.getFilteredBookings(1);
            return;
          }
        );
      } else {
        this.getBookings(1);
        return;
      }
    } else {
      try {
        this.setState({
          loading: true,
          isFilterResult: true,
          text: "",
        });
        const bookings = await API.get(
          `Bookings/searchBy?text=${search}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (bookings.status === 200) {
          const pageCount = Math.ceil(bookings.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("bookings searched content :", bookings.data.bookings);

          this.setState(
            {
              csvData: bookings.data.bookingList,
              active: key,
              loading: false,
              bookings: bookings.data.bookingList,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };

  openModalConfirmAction = (
    roomId,
    sessionId,
    actionValue,
    indexAction,
    status
  ) => {
    if (status === 0) {
      this.setState({
        indexAction: indexAction,
        roomId: roomId,
        sessionId: sessionId,
        actionValue: actionValue,
        modalConfirmAction: true,
      });
    } else return null;
  };
  toggleModalConfirmAction = () => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
    });
  };

  handleRequests = async () => {
    const { actionValue, roomId, sessionId, indexAction } = this.state;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      let result = await API.post(`Bookings/acceptRefuse`, null, {
        params: {
          access_token: accessToken,
          value: actionValue,
          sessionId: sessionId,
          roomId: roomId,
        },
      });
      if (result.status === 200) {
        console.log("changed SUCCESSFULLY");
        notify(
          {
            type: "success",
            message: actionValue ? "Request Accepted" : "Request Refused",
          },
          this
        );
        const params = result.data;
        console.log("params :", params);

        const { bookings, indexAction } = this.state;
        /* bookings[indexAction].status = actionValue? 1 : 2; */
        this.setState(
          {
            loading: false,
            modalConfirmAction: !this.state.modalConfirmAction,
            /* bookings: bookings, */
            indexAction: "",
            roomId: "",
            sessionId: "",
          },
          () => this.getBookings(this.state.active)
        );
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      await this.setState({
        loading: false,
        modalConfirmAction: !this.state.modalConfirmAction,
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.text) {
      this.getSearchedBookings(selectedPage + 1);
    } else if (this.state.isFilterResult) {
      this.getFilteredBookings(selectedPage + 1);
    } else {
      this.getBookings(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/hostingResquests/${selectedPage + 1}`
    );
  };

  handleStatusColor = (code) => {
    if (code === 1) {
      return "#00BFA5";
    } else if (code === 0) {
      return "#FF8A65";
    } else if (code === 2) {
      return "#E6413D";
    } else {
      return "#000";
    }
  };
  handleStatusName = (code) => {
    if (code === 0) {
      return "Pending";
    } else if (code === 1) {
      return "Accepted";
    } else if (code === 2) {
      return "Refused";
    } else {
      return "Not available";
    }
  };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const { bookings, actionValue } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Hosting Requests</h1>
                </div>
                {/* <div className="float-right" style={{ marginTop: "2%" }}>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {e.preventDefault(); this.getSearchedBookings(1)}}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.getSearchedBookings(1)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        style={{ position: "relative" }}
                        placeholder={"Search..."}
                        value={this.state.text}
                        name="text"
                        onChange={this.handleInputChange}
                      />
                      <span
                        onClick={this.cancelSearch}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "8px",
                          top: "12px",
                          zIndex: "100",
                          display: this.state.text !== "" ? "block" : "none"
                        }}
                        class="fa fa-trash"
                      ></span>
                    </InputGroup>
                  </form>
                </div> */}
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {this.state.isSearchResult ||
                        this.state.isFilterResult ? (
                          <span className="results-count">
                            {this.state.count} results were found
                          </span>
                        ) : null}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th className="fullName">Tutors</th>
                              {/* <th>
                                <select
                                  className="fullName filter-col"
                                  id="inputGender"
                                  name="gender"
                                  onChange={this.handleGendersInputChange}
                                  value={this.state.filter.gender}
                                  required
                                >
                                  {genders.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th> */}
                              <th className="centered-column-text">
                                Requested On
                              </th>
                              <th className="centered-column-text">Status</th>
                              <th className="centered-column-text">
                                Classroom
                              </th>
                              <th className="centered-column-text">
                                Start-Finish
                              </th>
                              <th className="centered-column-text">Duration</th>
                              <th className="centered-column-text">Places</th>
                              <th className="centered-column-text">
                                Accept/Refuse
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookings.map((booking, key) => {
                              return (
                                <tr key={key}>
                                  <td className="user-inline-img text-cell">
                                    <img
                                      src={
                                        booking &&
                                        booking.bookingSession &&
                                        booking.bookingSession.tutors &&
                                        booking.bookingSession.tutors.image &&
                                        booking.bookingSession.tutors.image.name
                                          ? `${SERVERDIR}Containers/img/download/${booking.bookingSession.tutors.image.name}`
                                          : `${IMGDIR}/images/profile/profile-general.jpg`
                                      }
                                      alt="user avatar"
                                      className="avatar-image img-inline"
                                    />
                                    {booking &&
                                      booking.bookingSession &&
                                      booking.bookingSession.tutors &&
                                      booking.bookingSession.tutors.fullName}
                                  </td>
                                  <td className="centered-column-text">
                                    {moment(booking.createdAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td
                                    className="centered-column-text bold text-capitalize"
                                    style={{
                                      color: this.handleStatusColor(
                                        booking && booking.status
                                      ),
                                    }}
                                  >
                                    {this.handleStatusName(
                                      booking && booking.status
                                    )}
                                  </td>
                                  <td className="centered-column-text">
                                    {booking &&
                                      booking.bookingRoom &&
                                      booking.bookingRoom.name}
                                  </td>
                                  <td className="centered-column-text">
                                    {booking &&
                                    booking.bookingSession &&
                                    booking.bookingSession.start ? (
                                      <span>
                                        {moment(
                                          booking &&
                                            booking.bookingSession &&
                                            booking.bookingSession.start
                                        ).format("D/MM/YYYY")}
                                        -
                                        {moment(
                                          booking &&
                                            booking.bookingSession &&
                                            booking.bookingSession.end
                                        ).format("D/MM/YYYY")}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td className="centered-column-text">
                                    {booking &&
                                    booking.bookingSession &&
                                    booking.bookingSession.startTime ? (
                                      <span>
                                        {moment
                                          .utc(
                                            booking &&
                                              booking.bookingSession &&
                                              booking.bookingSession.startTime
                                          )
                                          .format("HH:mm")}
                                        -
                                        {moment
                                          .utc(
                                            booking &&
                                              booking.bookingSession &&
                                              booking.bookingSession.endTime
                                          )
                                          .format("HH:mm")}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td className="centered-column-text">
                                    {booking &&
                                      booking.bookingSession &&
                                      booking.bookingSession.maxPlace}
                                  </td>
                                  <td className="centered-column-text">
                                    <span
                                      className="accept-refuse-buttons"
                                      style={{
                                        color:
                                          booking && booking.status === 0
                                            ? "green"
                                            : "#CFCFCF",
                                      }}
                                      onClick={() =>
                                        this.openModalConfirmAction(
                                          booking && booking.roomId,
                                          booking &&
                                            booking.bookingSession &&
                                            booking.bookingSession.id,
                                          true,
                                          key,
                                          booking && booking.status
                                        )
                                      }
                                    >
                                      <i className="fa fa-check"></i>&nbsp;
                                    </span>
                                    &nbsp;
                                    <span
                                      className="accept-refuse-buttons"
                                      style={{
                                        color:
                                          booking && booking.status === 0
                                            ? "red"
                                            : "#CFCFCF",
                                      }}
                                      onClick={() =>
                                        this.openModalConfirmAction(
                                          booking && booking.roomId,
                                          booking &&
                                            booking.bookingSession &&
                                            booking.bookingSession.id,
                                          false,
                                          key,
                                          booking && booking.status
                                        )
                                      }
                                    >
                                      <i className="fa fa-times"></i>&nbsp;
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {bookings.length === 0 && (
                              <tr className="text-center">
                                <td colSpan="10">
                                  <b>Oops! no data found</b>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        {/* <div>
          <ModalConfirmation
            isOpen={this.state.modalConfirmRevoke}
            toggle={this.toggleModalConfirmRevoke}
            disabledButton={false}
            confirm={this.handleRevoke}
            cancel={this.toggleModalConfirmRevoke}
            title={isRevoked ? "Grant User" : "Revoke User"}
            body={
              isRevoked
                ? "Are you sure you want to grant this user?"
                : "Are you sure you want to revoke this user?"
            }
            confirmText={isRevoked ? "Grant" : "Revoke"}
          />
        </div> */}

        <div>
          <ModalConfirmation
            isOpen={this.state.modalConfirmAction}
            toggle={this.toggleModalConfirmAction}
            disabledButton={false}
            confirm={this.handleRequests}
            cancel={this.toggleModalConfirmAction}
            title={
              actionValue ? "Accept Hosting Request" : "Refuse Hosting Request"
            }
            body={
              actionValue
                ? "Are you sure you want to accept this hosting request?"
                : "Are you sure you want to refuse this hosting request?"
            }
            confirmText={actionValue ? "Accept" : "Refuse"}
          />
        </div>
        {/* <div>
          <ModalConfirmation
            isOpen={this.state.modalBlock}
            toggle={this.openModalblock}
            disabledButton={false}
            confirm={this.toggleBlock}
            cancel={this.closeModalBlock}
            title={revoked ? "Unblock User" : "Block User"}
            body={
              revoked
                ? "Are you sure you want to unblock this User?"
                : "Are you sure you want to block this User?"
            }
            confirmText={revoked ? "Unblock" : "Block"}
          />
        </div> */}
        {/* <div>
        <ModalTags
          isOpen={this.state.modalTags}
          toggle={this.toggleModalTags}
          close={this.closeModalTags}
          title="Domain"
          tags={tags}
          />
        </div> */}
      </div>
    );
  }
}

export default HostingRequests;
