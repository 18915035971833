import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import API from '../../helpers/api.jsx'
/* import Loader from "react-loader-spinner";
import { members } from 'variables/general/members.jsx'; */
import { notify } from "../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import empty_pic_room from "assets/img/default-logo.png";
/* import { ExportToCsv } from "export-to-csv"; */
import ModalConfirmation from "../../components/modals/ModalConfirmation.jsx";
import ReactPaginate from 'react-paginate';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-daterange-picker/dist/css/react-calendar.css'

import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;

class ClassroomManagement extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sessions: [],
      limit: 5,
      count: 0,
      totalCount: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      classroomToPreview: {},
      actionValue: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      idClassroomToAccept: "",
      indexRevoke: "",
      activated: false,
      modalProfile: false,
      modalConfirmAction: false,
      idSession: "",
      actionValue: false,
      selectedSessionId: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      modalTagsTitle: "",
      isSearchResult: false,
      searchQuery: ""
    };
  }

  async componentWillMount() {
    await this.getSessions(this.state.active);
    console.log("hello", this.state.sessions);
    /* this.getCount();
    
    console.log("my role", this.state.userProfile.role); */
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
 

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getSessions = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        searchQuery: ""
      });
      const sessions = await API.get(
        `Rooms/getListRooms?limit=${limit}&skip=${skip}&access_token=${accessToken}`
      );

      if (sessions.status === 200) {
        console.log("sessions content :", sessions.data);
        const pageCount = Math.ceil(sessions.data.count / this.state.limit);
        this.setState({ count: pageCount, totalCount: sessions.data.count });

        this.setState(
          {
            csvData: sessions.data.rooms,
            active: key,
            loading: false,
            sessions: sessions.data.rooms,
          },
          () => {}
        );
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };
 

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedSessions(selectedPage + 1);
    } else {
      this.getSessions(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/admin/classroomManagement/${selectedPage + 1}`
    );
  };

  toggleModalConfirmAction= (classroomId,value,status) => {
      if (status === "Draft" || status === "Draft_Edit") {
        this.setState({
          modalConfirmAction: !this.state.modalConfirmAction,
          actionValue: value,
          idClassroomToAccept: classroomId,
        });
      }
  }
  closeModalConfirmAction= () => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction
    })
  }
  toggleModalProfile= (classroom) => {
    this.setState({
      modalProfile: !this.state.modalProfile,
      classroomToPreview : classroom
    })
  }
  closeModalProfile= () => {
    this.setState({
      modalProfile: !this.state.modalProfile
    })
  }


  handleStatusChange = async()=>{
    console.log(this.state.id);
    const selectedSessionId = this.state.idClassroomToAccept;
    const value = this.state.actionValue;
    try {
      let result = await API.post(`Rooms/acceptRefuse`, null, { params: {
        roomId: selectedSessionId, value: value, access_token: localStorage.getItem("WeCodeLand_jwt")
      }})
      if (result.status === 204) {
        console.log("Action done SUCCESSFULLY");
        notify(
          { type: "success", message: value ? "Classroom Accepted" : "Classroom Refused" },
          this
        );
        this.getSessions(this.state.active);
        this.setState({
          loading: false,
          modalConfirmAction: !this.state.modalConfirmAction
        })
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      await this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  }

  renderStringTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  renderFileNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span className="tag-title underlined-clickable" onClick={() => window.open(`${SERVERDIR}Container2s/file/download/${file && file.name}`, "_blank")}>{file && file.originalFilename}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  renderImageNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span className="tag-title underlined-clickable" onClick={() => window.open(`${SERVERDIR}Containers/img/download/${file && file.name}`, "_blank")}>{file && file.originalFilename}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
    };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const {
      sessions,
      actionValue,
      classroomToPreview
    } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Classroom Management</h1>
                </div>
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {/* {this.state.isSearchResult ? (
                          <span className="results-count">
                            {this.state.totalCount} results were found
                          </span>
                        ) : null} */}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th className="fullName">Classroom Name</th>
                              <th className="centered-column-text">Size</th>
                              <th className="centered-column-text">Status</th>
                              <th className="centered-column-text">Governorate</th>
                              <th className="centered-column-text">
                                Accept/Refuse
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(sessions)}

                            {sessions.map((session, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    className="user-inline-img text-cell"
                                    id={`Tooltip${key}`}
                                  >
                                    <img
                                      src={
                                        session.logo && session.logo.name
                                          ? `${SERVERDIR}Containers/img/download/${session.logo.name}`
                                          : empty_pic_room
                                      }
                                      alt="user avatar"
                                      className="avatar-image img-inline"
                                    />
                                    <span
                                      className="clickable"
                                      onClick={() =>
                                        this.toggleModalProfile(session)
                                      }
                                      style={{
                                        color:
                                          session &&
                                          session.status &&
                                          (session.status === "Draft" ||
                                            session.status === "Draft_Edit")
                                            ? "#d43273"
                                            : "inherit",
                                      }}
                                    >
                                      {session && session.name}
                                    </span>
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {session && session.size}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {session.deleted
                                      ? "Deleted"
                                      : session && session.status}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {session &&
                                      session.address &&
                                      session.address.state}
                                  </td>
                                  <td className="centered-column-text">
                                    <span
                                      className="mr-3"
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          (session && (session.status === "Draft"||session.status === "Draft_Edit"))
                                            ? "green"
                                            : "#CFCFCF",
                                        padding: "5px",
                                      }}
                                      onClick={
                                        !session.deleted
                                          ? () =>
                                              this.toggleModalConfirmAction(
                                                session.id,
                                                true,
                                                session.status
                                              )
                                          : null
                                      }
                                    >
                                      <i className="fa fa-check"></i>&nbsp;
                                    </span>
                                    &nbsp;
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          session && (session.status === "Draft" ||
                                            session.status === "Draft_Edit")
                                            ? "red"
                                            : "#CFCFCF",
                                        padding: "5px",
                                      }}
                                      onClick={
                                        !session.deleted
                                          ? () =>
                                              this.toggleModalConfirmAction(
                                                session.id,
                                                false,
                                                session.status
                                              )
                                          : null
                                      }
                                    >
                                      <i className="fa fa-times"></i>&nbsp;
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>

        <div>
          <ModalConfirmation
            isOpen={this.state.modalConfirmAction}
            toggle={this.closeModalConfirmAction}
            disabledButton={false}
            confirm={this.handleStatusChange}
            cancel={this.closeModalConfirmAction}
            title={actionValue ? "Accept Classroom" : "Refuse Classroom"}
            body={
              actionValue
                ? "Are you sure you want to accept this classroom?"
                : "Are you sure you want to refuse this classroom?"
            }
            confirmText={actionValue ? "Accept" : "Refuse"}
          />
        </div>

        <div>
          <Modal
            isOpen={this.state.modalProfile}
            toggle={this.closeModalProfile}
          >
            <ModalHeader
              style={{ padding: "0.5rem 0.5rem" }}
              toggle={this.closeModalProfile}
            >
              <span style={{ fontWeight: "bold" }}>
                {classroomToPreview && classroomToPreview.name}
              </span>
            </ModalHeader>
            <ModalBody className="p-3">
              <div className="col-12">
                <div className="row uprofile">
                  <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                    <img
                      alt=""
                      src={
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.image &&
                        classroomToPreview.host.image.name
                          ? `${SERVERDIR}Containers/img/download/${classroomToPreview.host.image.name}`
                          : `${IMGDIR}/images/profile/profile-general.jpg`
                      }
                      className="img-fluid"
                    />
                  </div>
                  <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                    <h3 className="uprofile-owner">
                      <a href="#!">
                        {classroomToPreview && classroomToPreview.host && classroomToPreview.host.fullName}
                      </a>
                    </h3>
                    <div className="clearfix"></div>
                      <p className="uprofile-title">
                        {classroomToPreview && classroomToPreview.host && classroomToPreview.host.profession}
                      </p>
                    <div className="clearfix"></div>

                    <p className="uprofile-title">
                      {classroomToPreview && classroomToPreview.host && classroomToPreview.host.phone}
                    </p>
                  </div>
                </div>
                <div className="row profile-details">
                  <p>
                    {classroomToPreview && classroomToPreview.host && classroomToPreview.host.description}
                  </p>

                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.expertises && classroomToPreview.host.expertises.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Expertises</h3>
                      {this.renderStringTags(
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.expertises
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.education && classroomToPreview.host.education.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Education</h3>
                      {this.renderStringTags(
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.education
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.competencies  && classroomToPreview.host.competencies.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Competencies</h3>
                      {this.renderStringTags(classroomToPreview && classroomToPreview.host && classroomToPreview.host.competencies
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.experience &&
                  classroomToPreview.host.experience.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Experience</h3>
                      {this.renderStringTags(classroomToPreview && classroomToPreview.host && classroomToPreview.host.experience
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.subjects &&
                  classroomToPreview.host.subjects.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Subjects</h3>
                      {this.renderStringTags(
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.subjects
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.certifications &&
                  classroomToPreview.host.certifications.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">Certifications</h3>
                      {this.renderStringTags(
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.certifications
                      )}
                    </div>
                  ) : null}
                  {classroomToPreview && classroomToPreview.host && classroomToPreview.host.attachments &&
                  classroomToPreview.host.attachments.length > 0 ? (
                    <div>
                      <hr />
                      <h3 className="font-weight-bold">
                        Certifications attached files
                      </h3>
                      {this.renderFileNames(
                        classroomToPreview && classroomToPreview.host && classroomToPreview.host.attachments
                      )}
                    </div>
                  ) : null}
                </div>
                {
                  <div>
                    <hr />
                    <div className="row uprofile">
                      <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                        <img
                          alt=""
                          src={
                            classroomToPreview && classroomToPreview.logo &&
                            classroomToPreview.logo.name
                              ? `${SERVERDIR}Containers/img/download/${classroomToPreview.logo.name}`
                              : `${IMGDIR}/images/profile/profile-general.jpg`
                          }
                          className="img-fluid"
                        />
                      </div>
                      <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                        <h3 className="uprofile-owner">
                          <a href="#!">
                            {
                              classroomToPreview &&
                              classroomToPreview.name}
                          </a>
                        </h3>
                        <div className="clearfix"></div>
                        <p className="uprofile-list">
                          <span>
                            <i className="fa fa-users"></i>{" "}
                            {
                              classroomToPreview &&
                              classroomToPreview.size}{" "}
                            Places maximum
                          </span>
                          <span>
                            <i className="fa fa-calendar-o"></i>{" "}
                            {moment(
                              
                                classroomToPreview &&
                                classroomToPreview.from
                            ).format("D/MM/YYYY")}
                            {" - "}
                            {moment(
                              
                                classroomToPreview &&
                                classroomToPreview.to
                            ).format("D/MM/YYYY")}
                          </span>
                          <span>
                            <i className="fa fa-map-marker"></i>{" "}
                            {
                              classroomToPreview &&
                              classroomToPreview.address &&
                              classroomToPreview.address.name}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row profile-details">
                      <p>
                        {
                          classroomToPreview &&
                          classroomToPreview.description}
                      </p>

                      {
                      classroomToPreview &&
                      classroomToPreview.equipments &&
                      classroomToPreview.equipments.length > 0 ? (
                        <div>
                          <hr />
                          <h3 className="font-weight-bold">Equipement</h3>
                          {this.renderStringTags(
                            
                              classroomToPreview &&
                              classroomToPreview.equipments
                          )}
                        </div>
                      ) : null}
                      {
                      classroomToPreview &&
                      classroomToPreview.album &&
                      classroomToPreview.album.length > 0 ? (
                        <div>
                          <hr />
                          <h3 className="font-weight-bold">
                            Classroom's pictures
                          </h3>
                          {this.renderImageNames(
                            
                              classroomToPreview &&
                              classroomToPreview.album
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ height: "35px", width: "98px", padding: "0px" }}
                color="primary"
                onClick={this.closeModalProfile}
              >
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ClassroomManagement;
