import React from 'react';
import {

    Row, Col,
} from 'reactstrap';

import SessionCard from './../../../components/cards/SessionCard.jsx'
import { Link } from 'react-router-dom';
import API from './../../../helpers/api.jsx'
import {notify} from './../../../helpers/common.jsx'
import NotificationAlert from "react-notification-alert";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import ModalPayment from './../../../components/modals/ModalPayment';
import ModalConfirmation from './../../../components/modals/ModalConfirmation';

var BASEDIR = process.env.REACT_APP_BASEDIR;

class OngoingSessions extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ongoingSessions: [],
            limit: 8,
            count: 0,
            active: (this.props.match.params && Number(this.props.match.params.page)) || 1,
            loading: true,
            profileId: localStorage.getItem('WeCodeLand_user'),
            modalPayment: false,
            sessionToSubscribeTo: {},
            modalUnsubscribe: false,
            sesionToUnsubscribeTo: {},
            studentUnsubscribingId: "",
            connectedIsSubscribed: false
        };


    }

    componentDidMount() {
        const access_token = localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        this.getOngoingSessions(this.state.active);

    }

    getOngoingSessions = async (key) => {
        this.setState({
          loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const studentId = localStorage.getItem("WeCodeLand_user");
        const limit = this.state.limit;
        const skip = (key - 1) * this.state.limit;
        try {
          const ongoingSessions = await API.get(`Profiles/ongoingSessions`, {
            params: { studentId: studentId, limit: limit, skip: skip, access_token: accessToken },
          });

          if (ongoingSessions.status === 200) {
            this.setState({
              ongoingSessions: ongoingSessions.data.result,
              active: key,
            });
            const pageCount = Math.ceil(ongoingSessions.data.count / this.state.limit);
            this.setState({ count: pageCount });
          }
          this.setState({
            loading: false,
          });
        } catch (error) {
          this.setState({
            loading: false,
          });
        }
      };

    handlePageClick = (data) => {
        const selectedPage = data.selected;
        this.getOngoingSessions(selectedPage + 1);
        window.history.pushState(null, null, BASEDIR + `/ongoingSessions/${selectedPage + 1}`);
        window.scrollTo(0, 0)
      }

      unsubscribe=async(session,connectedIsSubscribed,studentId)=>{
        try {
          console.log("session: ", session);
          let result = await API.post(`Subscriptions/addRemove`, null, { params: {
            value: !connectedIsSubscribed,
            classId: session && session.id,
            studentId: studentId
          }});
          if (result.status === 200) {
            console.log("subscribed SUCCESSFULLY");
            notify(
              { type: "success", message: !connectedIsSubscribed? "Subscribed SUCCESSFULLY":"UnSubscribed SUCCESSFULLY" },
              this
            );
            this.getOngoingSessions(this.state.active);
            this.toggleModalUnsubscribe();
            this.setState({
              loading: false,
              idLoading: ''
            });
          } else {
            throw result.status;
          }
        } catch (error) {
          const {
            response: {
              data: {
                error: { message }
              }
            } = {}
          } = error;
           this.setState({
            loading: false
          });
          notify({ type: "danger", message: message }, this);
        } finally {

        }
      }

      buttonCLickHandler = async (session, index) => {
        const studentId = localStorage.getItem("WeCodeLand_user");
        this.setState({
          loading: true,
          idLoading: session.id
        });
        const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
        if(connectedIsSubscribed)
        {this.openModalUnsubscribe(session,connectedIsSubscribed,studentId)
        }
        else{
          this.setState({
            sessionToSubscribeTo: session
          },()=>this.toggleModalPayment())
        }
      };
      toggleModalUnsubscribe = ()=>{
        this.setState({
          modalUnsubscribe: !this.state.modalUnsubscribe
        })
    }
    openModalUnsubscribe = (session,connectedIsSubscribed,studentId)=>{
        this.setState({
          modalUnsubscribe: true,
          sesionToUnsubscribeTo : session,
          studentUnsubscribingId : studentId,
          connectedIsSubscribed : connectedIsSubscribed
        })
    }

      toggleModalPayment=()=>{
        this.setState({
          modalPayment : !this.state.modalPayment
        })
      }
    
      onFinish=async()=>{
        await this.getOngoingSessions(this.state.active);
      }
    
    render(){
        const {ongoingSessions} = this.state;
        return (
            <div>
                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                    <div className="page-title">
                        <div className="float-left">
                            <h1 className="title">Ongoing Sessions</h1>
                        </div>
                    </div>





                    <div className="col-xl-12">
                            <div className="content-body">


                            <div className="row">
                                    <div className="col-12 cards-list-container">
                                {
                                    ongoingSessions.map((session, key) => {
                                        return (
                                            <SessionCard key={key} session={session} subscriptions={session.subscriptionsSession} index={key} buttonCLickHandler={this.buttonCLickHandler} isDisabled={this.state.loading  && this.state.idLoading === session.id}/>
                                        )
                                    })
                                }

                                    </div>
                                </div>
                            </div></div>



                            {this.state.count>1?
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.count}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.active-1}
                                    forcePage={this.state.active-1}
                                />
                                :null}

                        </Col>

                    </Row>
                </div>
                <ModalPayment
                key={this.state.sessionToSubscribeTo.id}
                isOpen={this.state.modalPayment}
                toggle={this.toggleModalPayment}
                onFinish={this.onFinish}
                sessionToSubscribeTo={this.state.sessionToSubscribeTo}/>

                <ModalConfirmation
                    isOpen={this.state.modalUnsubscribe}
                    toggle={this.toggleModalUnsubscribe}
                    disabledButton={false}
                    confirm={()=>this.unsubscribe(this.state.sesionToUnsubscribeTo,this.state.connectedIsSubscribed,this.state.studentUnsubscribingId)}
                    cancel={this.toggleModalUnsubscribe}
                    title={"Cancel participation"}
                    body={ "Are you sure you want to cancel your participation? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
      
            </div>
        );
    }
}

export default OngoingSessions;
