import Home from 'views/general/Home/Home.jsx'
import Login from 'views/general/Pages/Login.jsx';
import Reset from 'views/general/Pages/Reset.jsx';
import Cgu from 'views/general/Pages/Cgu.jsx';
import Activation from 'views/general/Pages/Activation.jsx';
import Register from 'views/general/Pages/Register.jsx';
import MyClassrooms from 'views/general/ClassroomManagement/MyClassrooms.jsx';
import ownedClassrooms from 'views/general/ClassroomManagement/ownedClassrooms.jsx';
import NewClassroom from 'views/general/ClassroomManagement/Add.jsx';
import HostingRequests from 'views/general/ClassroomManagement/HostingRequests.jsx';
import UpcomingSessions from 'views/general/Sessions/UpcomingSessions.jsx';
import OngoingSessions from 'views/general/Sessions/OngoingSessions.jsx';
import AllSessions from 'views/general/Sessions/AllSessions.jsx';
import MySessions from 'views/general/Sessions/MySessions.jsx';
import NewSessionEdition from 'views/general/Sessions/NewSessionEdition.jsx';
import NewSessionCreation from 'views/general/Sessions/NewSessionCreation.jsx';
import AttendedSessions from 'views/general/Sessions/AttendedSessions.jsx';
import TaughtSessions from 'views/general/Sessions/TaughtSessions.jsx';
import HostedSessions from 'views/general/Sessions/HostedSessions.jsx';
import UserProfile from 'views/general/Profile/UserProfile.jsx'
import SessionDetails from 'views/general/Sessions/SessionDetails';
import ClassroomDetails from 'views/general/ClassroomManagement/ClassroomDetails';
import EditClassroom from 'views/general/ClassroomManagement/EditClassroom';
import EditProfile from 'views/general/Profile/EditProfile.jsx';
import UpgradeToTutor from 'views/general/Profile/UpgradeToTutor.jsx';
import UpgradeToHost from 'views/general/Profile/UpgradeToHost.jsx';
import NewCourseByTeacher from 'views/general/Sessions/NewCourseByTeacher.jsx'
import EditSessionByUser from 'views/general/Sessions/EditSessionByUser.jsx'


//import {menuStyle} from 'variables/settings/blog.jsx';

var BASEDIR = process.env.REACT_APP_BASEDIR;

var dashRoutes = [

        { path: BASEDIR+"/dashboard", component: Home, type: "child",authorizedRoles: ["student","teacher","host"]},
        { path: BASEDIR+"/login", component: Login, type: "child",authorizedRoles: ["student","teacher","host"]},
        { path: BASEDIR + "/reset", component: Reset, type: "child",authorizedRoles: ["student","teacher","host"] },
        { path: BASEDIR+"/register", component: Register, type: "child",authorizedRoles: ["student","teacher","host"]},
        { path: BASEDIR + "/activation", component: Activation, type: "child" ,authorizedRoles: ["student","teacher","host"]},
        { path: BASEDIR+"/cgu", component: Cgu, type: "child",authorizedRoles: ["student","teacher","host"]},
        { path: BASEDIR + "/myClassrooms/:page?", component: MyClassrooms, type: "child",authorizedRoles: ["host"]},
        { path: BASEDIR + "/hostingResquests/:page?", component: HostingRequests, type: "child",authorizedRoles: ["host"]},


        {
            path: BASEDIR+"/dashboard",
            name: "Home",
            icon: "home",
            component: Home,
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/profile/:id",
            name: "Profile",
            icon: "home",
            component: UserProfile,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
    {
        path: BASEDIR+"/session/:id",
        name: "Session",
        icon: "home",
        component: SessionDetails,
        type: "child",
        authorizedRoles: ["student","teacher","host"]
    },
    {
        path: BASEDIR+"/classroom/:id",
        name: "Classroom",
        icon: "home",
        component: ClassroomDetails,
        type: "child",
        authorizedRoles: ["student","teacher","host"]
    },
    {
        path: BASEDIR+"/editClassroom/:id",
        name: "Classroom",
        icon: "home",
        component: EditClassroom,
        type: "child",
        authorizedRoles: ["host"]
    },
        {
            path: BASEDIR+"/attendedSessions/:id/:page?",
            name: "Attended Sessions",
            icon: "home",
            component: AttendedSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/taughtSessions/:id/:page?",
            name: "Taught Sessions",
            icon: "home",
            component: TaughtSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/hostedSessions/:id/:page?",
            name: "Hosted Sessions",
            icon: "home",
            component: HostedSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/ownedClassrooms/:id/:page?",
            name: "Owned Classrooms",
            icon: "home",
            component: ownedClassrooms,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/editProfile/:id",
            name: "Edit Profile",
            icon: "home",
            component: EditProfile,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/upgradeToTutor/:id",
            name: "Upgrade Profile",
            icon: "home",
            component: UpgradeToTutor,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/upgradeToHost/:id",
            name: "Upgrade Profile",
            icon: "home",
            component: UpgradeToHost,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/sessions/:page?",
            name: "All Sessions",
            icon: "ui-faicon i-book-open",
            component: AllSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/mySessions/:page?",
            name: "My Sessions",
            icon: "ui-faicon i-book-open",
            component: MySessions,
            type: "child",
            authorizedRoles: ["teacher"]
        },
        {
            path: BASEDIR+"/editSession/:id",
            name: "Edit Sessions",
            icon: "ui-faicon i-book-open",
            component: EditSessionByUser,
            type: "child",
            authorizedRoles: ["teacher"]
        },
        {
            path: BASEDIR+"/upcomingSessions/:page?",
            name: "Upcoming Sessions",
            icon: "ui-faicon i-book-open",
            component: UpcomingSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/ongoingSessions/:page?",
            name: "Ongoing Sessions",
            icon: "ui-faicon i-book-open",
            component: OngoingSessions,
            type: "child",
            authorizedRoles: ["student","teacher","host"]
        },
        {
            path: BASEDIR+"/newSession/edit",
            name: "Add New Session",
            icon: "ui-faicon i-book-open",
            component: NewSessionEdition,
            type: "child",
            authorizedRoles: ["teacher"]
        },
        {
            path: BASEDIR+"/newSession/create",
            name: "Add New Session",
            icon: "ui-faicon i-book-open",
            component: NewSessionCreation,
            type: "child",
            authorizedRoles: ["teacher"]
        },
        {
            path: BASEDIR+"/newCourse",
            name: "Add New Course",
            icon: "ui-faicon i-book-open",
            component: NewCourseByTeacher,
            type: "child",
            authorizedRoles: ["teacher"]
        },
        {
            path: BASEDIR+"/classroomManagement/add",
            name: "Classroom Management",
            icon: "ui-faicon i-location-pin",
            component: NewClassroom,
            type: "child",
            authorizedRoles: ["host"]
        },
        {
            name: "Sessions",
            icon: "ui-faicon i-book-open",
            authorizedRoles: ["student","teacher","host"],
            type: "dropdown",
            child: [
              { path: BASEDIR + "/sessions", name: "All Sessions" },

              { path: BASEDIR + "/mysessions/1", name: "My Sessions" },

              { path: BASEDIR + "/ongoingSessions", name: "Ongoing Sessions" },

              { path: BASEDIR + "/upcomingSessions", name: "Upcoming Sessions" }
            ]
          },
        {
            path: BASEDIR+"/classroomManagement",
            name: "Classroom Management",
            icon: "ui-faicon i-location-pin",
            authorizedRoles: ["host"],
            type: "dropdown",
            child: [
              { path: BASEDIR + "/myClassrooms", name: "My Classrooms" },

              { path: BASEDIR + "/hostingResquests", name: "Hosting Requests" }
            ]
          },


    { redirect: true, path: BASEDIR+"/", pathTo: BASEDIR+"/dashboard", name: "Dashboard" },
    { redirect: true, path: "/", pathTo: BASEDIR + "/login", name: "Login" }

];
export default dashRoutes;
