import React from "react";
import {Container, Col, Row, UncontrolledCarousel} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMapMarkerAlt, faUsers, faCheck, faStar } from '@fortawesome/fontawesome-free-solid'
import { faCalendar } from '@fortawesome/fontawesome-free-regular'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../../helpers/api";
import {convertFromRaw, EditorState} from "draft-js";
import empty_pic_course from "../../../assets/img/course-default.png";
import empty_pic_classroom from "assets/img/empty_pic_classroom.png";
import moment from 'moment';
import SessionCard from '../../../components/cards/SessionCard.jsx';
import ModalPayment from '../../../components/modals/ModalPayment.jsx';
import ModalConfirmation from '../../../components/modals/ModalConfirmation.jsx';
import {notify} from './../../../helpers/common.jsx'
import NotificationAlert from "react-notification-alert";

const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;

export default class ClassroomDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            classroomObject: {},
            hostedSessions: [],
            hostedSessionsCount: 0,
            editorState: EditorState.createEmpty(),
            coursePhoto: {
                preview: empty_pic_course,
                data: {},
                container: {}
            },
            loading: false,
            modalPayment: false,
            idLoading: '',
            sessionToSubscribeTo: {},
            modalDelete: false,
            modalUnsubscribe: false,
            sesionToUnsubscribeTo: {},
            studentUnsubscribingId: "",
            connectedIsSubscribed: false
        };
    }


    async componentDidMount() {
        const access_token = localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        await this.getClassroom();
        await this.getHostedSessions();
    }


    getClassroom = async () => {

        this.setState({
            loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const sessionId = this.props.match.params.id;

        try {
            const classroom = await API.get(`Rooms/${sessionId}`, {
                params: {
                    access_token: accessToken,
                },
            });

            if (classroom.status === 200) {
                this.setState({classroomObject: classroom.data})

                //this.setState({ editorState: EditorState.createWithContent(convertFromRaw(session.data && session.data.sessionCourse && session.data.sessionCourse.syllabus)) });
                //this.setState({ SessionImage: session.SessionImage });


            }
            this.setState({
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });
        }

    };

    getHostedSessions = async () => {
        this.setState({
          loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const limit = this.state.limit;
        const skip = 0;
        try {
          const hostedSessions = await API.get(`Rooms/hostedSessions`, {
            params: { roomId: this.props.match.params.id, limit: limit, skip: skip, access_token: accessToken },
          });

          if (hostedSessions.status === 200) {
            this.setState({
              hostedSessions: hostedSessions.data.hostedSessions,
              hostedSessionsCount : hostedSessions.data.count
            });
            /* const pageCount = Math.ceil(hostedSessions.data.count / this.state.limit);
            this.setState({ count: pageCount }); */
          }
          this.setState({
            loading: false,
          });
        } catch (error) {
          this.setState({
            loading: false,
          });
        }
      };

      unsubscribe=async(session,connectedIsSubscribed,studentId)=>{
        try {
          console.log("session: ", session);
          let result = await API.post(`Subscriptions/addRemove`, null, {
            params: {
              value: !connectedIsSubscribed,
              classId: session && session.id,
              studentId: studentId
            }
          });
          if (result.status === 200) {
            console.log("subscribed SUCCESSFULLY");
            notify(
              { type: "success", message: !connectedIsSubscribed ? "Subscribed SUCCESSFULLY" : "UnSubscribed SUCCESSFULLY" },
              this
            );
            this.getClassroom();
            this.getHostedSessions();
            this.toggleModalUnsubscribe();
            this.setState({
              loading: false,
              idLoading: ''
            });
          } else {
            throw result.status;
          }
        } catch (error) {
          const {
            response: {
              data: {
                error: { message }
              }
            } = {}
          } = error;
          this.setState({
            loading: false
          });
          notify({ type: "danger", message: message }, this);
        } finally {
    
        }
      }

      participateButtonCLickHandler = async (session, index) => {
        const studentId = localStorage.getItem("WeCodeLand_user");
        this.setState({
          loading: true,
          idLoading: session.id
        });
        const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
        if(connectedIsSubscribed)
        {/* this.unsubscribe(session,connectedIsSubscribed,studentId) */
          this.openModalUnsubscribe(session,connectedIsSubscribed,studentId)
        }
        else{
          this.setState({
            sessionToSubscribeTo: session
          },()=>this.toggleModalPayment())
        }
      };

      toggleModalPayment=()=>{
        this.setState({
          modalPayment : !this.state.modalPayment
        })
      }
    
      onFinish=async()=>{
        await this.getClassroom();
        await this.getHostedSessions();
      }

      openModalDelete = () =>{
        this.setState({
          modalDelete: !this.state.modalDelete
        })
      }
      toggleModalDelete = ()=>{
        this.setState({
          modalDelete: !this.state.modalDelete
        })
    }
    toggleModalUnsubscribe = ()=>{
        this.setState({
          modalUnsubscribe: !this.state.modalUnsubscribe
        })
    }
    openModalUnsubscribe = (session,connectedIsSubscribed,studentId)=>{
        this.setState({
          modalUnsubscribe: true,
          sesionToUnsubscribeTo : session,
          studentUnsubscribingId : studentId,
          connectedIsSubscribed : connectedIsSubscribed
        })
    }
    deleteRoomHandler = async () => {
      const accessToken = localStorage.getItem("WeCodeLand_jwt");
    
      this.setState({
        loading: true
      });
    
      try {
        // delete room
        const deletedRoom = await API.patch(
          `Rooms/deleteRoom?access_token=${accessToken}&roomId=${this.props.match.params && this.props.match.params.id}`,
        );
    
        if (deletedRoom.status === 200) {
          notify({ type: "success", message: "Room deleted" }, this);
          this.setState({modalDelete : false})
          this.props.history.push(`${BASEDIR}/myClassrooms/1`)
        } else {
          this.setState({
            loading: false
          });
        }
      } catch (error) {
        const {
          response: {
            data: {
              error: { message }
            }
          } = {}
        } = error;
        this.setState({
          loading: false
        });
        notify({ type: "danger", message: message }, this);
        console.log("error: ", error);
      }
    }

    render() {

        const room = this.state.classroomObject;
        const {hostedSessions , hostedSessionsCount} = this.state;
        const address = room.address || {};
        const location = room.location;


        return (

            <div>
                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="details_content">
                    <Row>
                        <Col xs={12} md={12}>
                            <div className="page_title_section">
                                <h1 className="page_header">Detail Classroom</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="details_card">


                                <Row>
                                    <Col md={4}>
                                        <img src={(room && room.logo && Object.keys(room.logo).length > 0) ? `${ SERVERDIR }containers/${ room.logo.container }/download/${ room.logo.name }` : this.state.coursePhoto.preview} className="roomBanner_img"/>

                                    </Col>
                                    <Col md={8} className="custom_p-l_40">
                                        <div className="titles_section">
                                            <div className="d-flex">
                                            <h4 className="card_main_title text-truncate">{room.name}</h4>
                                            
                                            <h3 className="text-right" style={{ fontSize: "14px", margin: "0px", marginLeft: "10px" }}>
                                              <i
                                                style={{ cursor: "pointer",opacity: "0.6",marginRight: "3px" }}
                                                onClick={()=>window.open(`${BASEDIR}/editClassroom/${room && room.id}`, "_blank")}
                                                className="fa fa-pencil"
                                              ></i>{" "}
                                              <i
                                                style={{ cursor: "pointer",opacity: "0.6" }}
                                                onClick={()=>this.openModalDelete(room && room.id)}
                                                className="fa fa-trash"
                                              ></i>
                                            </h3>
                                            </div>
                                            <h4 className="card_secondary_title text-truncate">{ hostedSessionsCount + " "}hosted sessions</h4>

                                            <div className="rating-container">
                                                <i className="fa fa-star"></i> <span>{room.rating}</span>
                                            </div>
                                        </div>



                                        <div className="links_section">
                                            <Container>
                                                <Row>
                                                    <Col className="text-truncate" md={4}><FontAwesomeIcon icon={faUser} /> <a target="_blank" href={`${BASEDIR}/user/userId`}>{room.ownerName}</a></Col>
                                                    <Col className="text-truncate" md={4}><FontAwesomeIcon icon={faUsers} /> <span>{room.size} Places Maximum</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-truncate" md={12}><FontAwesomeIcon icon={faCalendar} /> <span>{moment(room.from).format("DD/MM/YYYY")} - {moment(room.to).format("DD/MM/YYYY")}</span></Col>
                                                </Row>
                                                {Object.keys(address).length > 0 && address && address.name && address.name.length?
                                                    <Row>
                                                    <Col className="text-truncate" md={12}><FontAwesomeIcon icon={faMapMarkerAlt} /> <span>{`${address.name}, ${address.area}, ${address.city}, ${address.state}`}</span></Col>
                                                </Row>
                                                :null}
                                            </Container>
                                        </div>

                                        <div className="actions_section" /* style={{display: "none"}} */>
                                          <Row>
  
                                              <Col>
                                                <button className="btn btn-primary" onClick={location && location.lat? ()=>window.open("https://maps.google.com?q=" + location.lat + "," + location.lng) : null}> See directions</button>
                                                
                                              </Col>
                                              <Col>
                                                <button className="btn btn-secondary" style={{display: "none"}}><FontAwesomeIcon className="btn_icon" icon={faStar} /> Rate this classroom</button>
                                            
                                              </Col>
                                              <Col></Col>
                                          </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="p_section">
                                            <h3>description</h3>
                                            <p>{room.description}</p>
                                        </div>
                                        <hr/>

                                        <div className="p_section">
                                            <h3>equipement</h3>
                                            <ul>
                                                {
                                                    room.equipments && room.equipments.map((eq, index) => (
                                                        <li key={index}>{eq}</li>
                                                    ))
                                                }

                                            </ul>
                                        </div>
                                        <hr/>
                                        <div className="p_section">
                                            <h3>location preview</h3>
                                            <div className="mapContainer">
                                                <MyMapComponent
                                                    isMarkerShown
                                                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `100%` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    location={location}
                                                />
                                            </div>
                                        </div>
                                        <hr/>
                                        {room && room.album && room.album.length > 0 ?
                                          <div className="p_section">
                                          <h3>Classroom pictures</h3>
                                          <div className="pictures-container">
                                            {room && room.album && room.album.length > 0 ?
                                            <UncontrolledCarousel controls={false} items={room &&
                                              room.album &&
                                              room.album.length > 0 &&
                                              room.album.map(v => ({src : v.name ? `${SERVERDIR}Containers/img/download/${v.name}`  : empty_pic_classroom, altText: 'Classroom Picture', caption: 'Classroom Picture Caption'}))} />
                                              :
                                                <img
                                              className="img-fluid"
                                              src={
                                                room &&
                                                room.album &&
                                                room.album.length > 0 &&
                                                room.album[0] &&
                                                room.album[0].name
                                                  ? `${SERVERDIR}Containers/img/download/${
                                                      room && room.album[0].name
                                                    }`
                                                  : empty_pic_classroom
                                              }
                                              alt=""
                                                />
                                            }
                                          </div>
                                        </div>
                                        :null}
                                    </Col>
                                </Row>


                            </div>
                        </Col>
                    </Row>
                    {hostedSessions && hostedSessions.length > 0 ?
                    <Row>
                    <Col xs={12} md={12}>
                        <div className="page-title">
                        <div className="float-left">
                            <h1 className="title">Hosted Sessions</h1>
                        </div>
                        <div className="float-right see-all-button">
                            <h3 className="font-weight-bold">
                            <Link to={`${BASEDIR}/hostedSessions/${this.props.match.params.id}/1`}>See All</Link>
                            </h3>
                        </div>
                        </div>

                        <div className="col-xl-12">
                        <div className="content-body">
                            <div className="row">
                            <div className="col-12 cards-list-container">
                                {hostedSessions.slice(0, 4).map((session, key) => {
                                return (
                                    <SessionCard
                                    key={key}
                                    session={session}
                                    index={key}
                                    buttonCLickHandler={this.participateButtonCLickHandler}
                                    subscriptions={session.subscriptionsSession}
                                    isDisabled={this.state.loading && this.state.idLoading === session.id}
                                    />
                                );
                                })}
                            </div>
                            </div>
                        </div>
                        </div>
                    </Col>
                    </Row>
                    :
                    null}


                </div>
                <ModalPayment
                isOpen={this.state.modalPayment}
                toggle={this.toggleModalPayment}
                onFinish={this.onFinish}
                sessionToSubscribeTo={this.state.sessionToSubscribeTo}/>
                <ModalConfirmation
                    isOpen={this.state.modalDelete}
                    toggle={this.toggleModalDelete}
                    disabledButton={false}
                    confirm={this.deleteRoomHandler}
                    cancel={this.toggleModalDelete}
                    title={"Delete classroom"}
                    body={ "Are you sure you want to delete this classroom? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
                <ModalConfirmation
                    isOpen={this.state.modalUnsubscribe}
                    toggle={this.toggleModalUnsubscribe}
                    disabledButton={false}
                    confirm={()=>this.unsubscribe(this.state.sesionToUnsubscribeTo,this.state.connectedIsSubscribed,this.state.studentUnsubscribingId)}
                    cancel={this.toggleModalUnsubscribe}
                    title={"Cancel participation"}
                    body={ "Are you sure you want to cancel your participation? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
            </div>

        );
    }
}


const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: props.location && props.location.lat || 0, lng: props.location && props.location.lng || 0 }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.location && props.location.lat || 0, lng: props.location && props.location.lng || 0}} />}
    </GoogleMap>
));
