import React from 'react';
import {

    Row, Col,Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import SessionCard from './../../../components/cards/SessionCard.jsx'
import { Link } from 'react-router-dom';
import API from './../../../helpers/api.jsx'
import {notify} from './../../../helpers/common.jsx'
import NotificationAlert from "react-notification-alert";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
import ModalPayment from './../../../components/modals/ModalPayment';
import ModalConfirmation from './../../../components/modals/ModalConfirmation';

var BASEDIR = process.env.REACT_APP_BASEDIR;

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.title;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.title}
  </div>
);
const shouldRenderSuggestions=() =>{
  return true;
}

class AllSessions extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            limit: 8,
            count: 0,
            active: (this.props.match.params && Number(this.props.match.params.page)) || 1,
            loading: true,
            modalSessions: false,
            selectionValue: "",
            profileId: localStorage.getItem('WeCodeLand_user'),
            isTeacher:  JSON.parse(localStorage.getItem("WeCodeLand_profile")).roles.some(item => item.name === "teacher"),
            value: '',
            suggestions: [],
            allSuggestions: {},
            selectedSessionId: "",
            modalPayment: false,
            sessionToSubscribeTo: {},
            modalUnsubscribe: false,
            sesionToUnsubscribeTo: {},
            studentUnsubscribingId: "",
            connectedIsSubscribed: false
        };


    }

    async componentDidMount() {
        const access_token = localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        await this.getSessions(this.state.active);
        await this.getAllSuggestions();
        this.setState({
          suggestions: this.getSuggestions(this.state.value)
        });

    }

    onChange = (event, { newValue }) => {
      this.setState({
        value: newValue
      });
    };
    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const {allSuggestions} = this.state;
        if(this.state.selectionValue === "2"){
      return allSuggestions.sessions && allSuggestions.sessions.filter(item =>
        item.title.toLowerCase().slice(0, inputLength) === inputValue
      );}
        else{
      return  allSuggestions.courses && allSuggestions.courses.filter(item =>
        item.title.toLowerCase().slice(0, inputLength) === inputValue
      );}
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
      this.setState({
        suggestions: this.getSuggestions(value)
      });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };

    onSuggestionSelected = (event, { suggestion}) =>{
      console.log("suggestion",suggestion);
      this.setState({
        selectedSessionId : suggestion.id
      })
    };

    toggleModalSessions = () => {
        this.setState({
          modalSessions: !this.state.modalSessions,
        });
      };
    selectionChange = (event) => {
        this.setState({selectionValue: event.target.value, value:''});
    }
    cancelModal = () =>{
      this.setState({
        modalSessions: !this.state.modalSessions,
        value:'',
        selectionValue: ""
      });
    }
    confirmSelection =()=>{
      if((this.state.value!== ""  &&  this.state.selectionValue!=="") || (this.state.selectionValue=== "1")){
      if(this.state.selectionValue=== "1"){
        this.props.history.push({
          pathname: BASEDIR+"/newCourse"
        })
      }
      if(this.state.selectionValue=== "2"){
        this.props.history.push({
        pathname: BASEDIR+"/newSession/edit",
        state: { id: this.state.selectedSessionId }
      })
    }
    else if (this.state.selectionValue=== "3"){
      this.props.history.push({
      pathname: BASEDIR+"/newSession/create",
      state: { id: this.state.selectedSessionId }
    })
  }
    }
    }

    getSessions = async (key) => {
        this.setState({
          loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const studentId = localStorage.getItem("WeCodeLand_user");
        const limit = this.state.limit;
        const skip = (key - 1) * this.state.limit;
        try {
          const sessions = await API.get(`Sessions/getList`, {
            params: { studentId: studentId, limit: limit, skip: skip, access_token: accessToken },
          });

          if (sessions.status === 200) {
            this.setState({
              sessions: sessions.data.result,
              active: key,
            });
            const pageCount = Math.ceil(sessions.data.count / this.state.limit);
            this.setState({ count: pageCount });
          }
          this.setState({
            loading: false,
          });
        } catch (error) {
          this.setState({
            loading: false,
          });
        }
      };
    getAllSuggestions = async () => {
        this.setState({
          loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const teacherId = localStorage.getItem("WeCodeLand_user");
        try {
          const allSuggestions = await API.get(`Sessions/list`, {
            params: { teacherId: teacherId,access_token: accessToken }
          });

          if (allSuggestions.status === 200) {
            this.setState({
              allSuggestions: allSuggestions.data
            });
            /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
            this.setState({ count: pageCount }); */
          }
          this.setState({
            loading: false,
          });
        } catch (error) {
          this.setState({
            loading: false,
          });
        }
      };

    handlePageClick = (data) => {
        const selectedPage = data.selected;
        this.getSessions(selectedPage + 1);
        window.history.pushState(null, null, BASEDIR + `/sessions/${selectedPage + 1}`);
        window.scrollTo(0, 0)
      }

      unsubscribe=async(session,connectedIsSubscribed,studentId)=>{
        try {
          console.log("session: ", session);
          let result = await API.post(`Subscriptions/addRemove`, null, { params: {
            value: !connectedIsSubscribed,
            classId: session && session.id,
            studentId: studentId
          }});
          if (result.status === 200) {
            console.log("subscribed SUCCESSFULLY");
            notify(
              { type: "success", message: !connectedIsSubscribed? "Subscribed SUCCESSFULLY":"UnSubscribed SUCCESSFULLY" },
              this
            );
            this.getSessions(this.state.active);
            this.toggleModalUnsubscribe();
            this.setState({
              loading: false,
              idLoading: ''
            });
          } else {
            throw result.status;
          }
        } catch (error) {
          const {
            response: {
              data: {
                error: { message }
              }
            } = {}
          } = error;
           this.setState({
            loading: false
          });
          notify({ type: "danger", message: message }, this);
        } finally {

        }
      }

      buttonCLickHandler = async (session, index) => {
        const studentId = localStorage.getItem("WeCodeLand_user");
        this.setState({
          loading: true,
          idLoading: session.id
        });
        const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
        if(connectedIsSubscribed)
        {this.openModalUnsubscribe(session,connectedIsSubscribed,studentId)}
        else{
          this.setState({
            sessionToSubscribeTo: session
          },()=>this.toggleModalPayment())
        }
      };

      toggleModalUnsubscribe = ()=>{
        this.setState({
          modalUnsubscribe: !this.state.modalUnsubscribe
        })
    }
    openModalUnsubscribe = (session,connectedIsSubscribed,studentId)=>{
        this.setState({
          modalUnsubscribe: true,
          sesionToUnsubscribeTo : session,
          studentUnsubscribingId : studentId,
          connectedIsSubscribed : connectedIsSubscribed
        })
    }

      toggleModalPayment=()=>{
        this.setState({
          modalPayment : !this.state.modalPayment
        })
      }
    
      onFinish=async()=>{
        await this.getSessions(this.state.active);
      }
    

    render(){
        const {sessions,value, suggestions} = this.state;
        const SessionsInputProps = {
          placeholder: 'No session selected',
          value,
          onChange: this.onChange
        };
        const CrousesInputProps = {
          placeholder: 'No course selected',
          value,
          onChange: this.onChange
        };
        return (
            <div>
                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                    <div className="page-title">
                        <div className="float-left">
                            <h1 className="title">All Sessions</h1>
                        </div>
                        { this.state.isTeacher?
                          <div className="float-right">
                                    <h1>
                                        <button className="btn btn-primary btn-sm profile-btn button-add-new" onClick={this.toggleModalSessions}>
                                                <i className="fa fa-plus"></i>&nbsp;&nbsp; New Session
                                        </button>
                                    </h1>
                        </div>
                        :null}
                    </div>





                    <div className="col-xl-12">
                            <div className="content-body">


                            <div className="row">
                                    <div className="col-12 cards-list-container">
                                {
                                    sessions.map((session, key) => {
                                        return (
                                            <SessionCard key={key} session={session} subscriptions={session.subscriptionsSession} index={key} buttonCLickHandler={this.buttonCLickHandler} isDisabled={this.state.loading  && this.state.idLoading === session.id}/>
                                        )
                                    })
                                }

                                    </div>
                                </div>
                            </div></div>



                            {this.state.count>1?
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.count}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.active-1}
                                    forcePage={this.state.active-1}
                                />
                                :null}

                        </Col>

                    </Row>
                </div>
                <Modal
                    isOpen={this.state.modalSessions}
                    toggle={this.toggleModalSessions}
                    className={this.props.className}
                    backdrop={ 'static' }
                    keyboard={false}
                >
                    <ModalHeader
                    toggle={this.toggleModalSessions}
                    >
                    <span className="bold">New Session</span>
                    </ModalHeader>
                    <ModalBody>
                    <div>
                    <div className="form-group">
                    <label className="form-label bold" htmlFor="field-1">
                        Adding a new Session/course
                    </label>
                    <br />
                    <label>Please Select one option</label>
                    <br/>
                    <select onChange={this.selectionChange} value={this.state.selectionValue}>
                        <option value="" disabled hidden>No Option Selected</option>
                        <option value="1">Create your own course </option>
                        <option value="2">Create session from a previous session</option>
                        <option value="3">Create session from an existing course</option>
                    </select>
                    </div>
                    {(this.state.selectionValue=== "2")?
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                             Previous session
                          </label>
                          <br />
                          <label>Please select a previously taught session</label>
                          <br/>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      shouldRenderSuggestions={shouldRenderSuggestions}
                      onSuggestionSelected={this.onSuggestionSelected}
                      inputProps={SessionsInputProps}
                    />
                    </div>
                    :null}
                    {(this.state.selectionValue=== "3")?
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                             Previous course
                          </label>
                          <br />
                          <label>Please select an existing course</label>
                          <br/>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      shouldRenderSuggestions={shouldRenderSuggestions}
                      onSuggestionSelected={this.onSuggestionSelected}
                      inputProps={CrousesInputProps}
                    />
                    </div>
                    :null}
                    </div>
                    </ModalBody>
                    <ModalFooter>
                    <span className="cancel-search-modal" onClick={this.cancelModal}>Cancel</span>
                    <Button
                        className="confirm-modal"
                        color="primary"
                        onClick={this.confirmSelection}
                        disabled={!((this.state.value!== ""  &&  this.state.selectionValue!=="") || (this.state.selectionValue=== "1"))}
                    >
                        Next
                    </Button>
                    </ModalFooter>
                </Modal>
                <ModalPayment
                  key={this.state.sessionToSubscribeTo.id}
                  isOpen={this.state.modalPayment}
                  toggle={this.toggleModalPayment}
                  onFinish={this.onFinish}
                  sessionToSubscribeTo={this.state.sessionToSubscribeTo}/>

                  <ModalConfirmation
                    isOpen={this.state.modalUnsubscribe}
                    toggle={this.toggleModalUnsubscribe}
                    disabledButton={false}
                    confirm={()=>this.unsubscribe(this.state.sesionToUnsubscribeTo,this.state.connectedIsSubscribed,this.state.studentUnsubscribingId)}
                    cancel={this.toggleModalUnsubscribe}
                    title={"Cancel participation"}
                    body={ "Are you sure you want to cancel your participation? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
      
            </div>
        );
    }
}

export default AllSessions;
