import React from "react";
import { Row, Col } from "reactstrap";
import { Line ,Doughnut, HorizontalBar } from "react-chartjs-2";
import axios from "axios";
import API from "../../../helpers/api.jsx";

var SERVERDIR = process.env.REACT_APP_API;
const backgroundColor = [
  "#F98BCD",
  "#F5B3DB",
  "#EEB8D5",
  "#DE71A5",
  "#E88DBE",
  "#D890B2",
  "#EB4CBE",
  "#DC42A8",
  "#DD0486",
  "#D0007D",
  "#B7026E",
  "#BF0073",
  "#BE669B",
  "#9E3B76",
  "#CF5590",
  "#CB5A97",
  "#954C7C",
  "#A35182",
  "#A61285",
  "#A30062",
  "#883E6B",
  "#890052",
  "#941167",
  "#830066",
  "#730053",
  "#660946",
  "#410027"
];
const hoverBackgroundColor = [
  "#FCC5E6",
  "#FAD9ED",
  "#F6DCEA",
  "#EFB8D2",
  "#F3C6DE",
  "#EBC7D9",
  "#F5A6DF",
  "#EDA0D3",
  "#EE81C2",
  "#E880BE",
  "#DB80B7",
  "#DF80B9",
  "#DFB3CD",
  "#CF9DBB",
  "#E7AAC7",
  "#E5ACCB",
  "#CAA5BD",
  "#D1A8C0",
  "#D288C2",
  "#D180B0",
  "#C39FB5",
  "#C480A9",
  "#C988B3",
  "#C180B3",
  "#B980A9",
  "#B284A3",
  "#A08093"
];
var studentsByGenderData = {
    labels: [
      'Male',
      'Female',
      'Other'
    ],
    datasets: [{
      data: [0, 0, 0],
      backgroundColor: [
        '#A30062','#D890B2','#CF5590'
      ],
      hoverBackgroundColor: [
        '#410027','#DFB3CD',"#EE81C2",
      ]
    }]
  };
  const primaryColor = "#D43272";
  const accentColor = "#ff8a65";
  const purpleColor = "#7f8ff4";
  const newStudentsStats = {
    data: (canvas) => {
        var ctx = canvas.getContext("2d");
        var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, '#18ce0f');
        gradientStroke.addColorStop(1, primaryColor);
        var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
        gradientFill.addColorStop(0, "rgba(38, 166, 154, 0)");
        gradientFill.addColorStop(1, "rgba(38, 166, 154, 0)");
        return {
            labels: ["12pm,", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
            datasets: [{
                label: "Stats",
                borderColor: primaryColor,
                pointBorderColor: "#FFF",
                pointBackgroundColor: primaryColor,
                pointBorderWidth: 2,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                fill: true,
                backgroundColor: gradientFill,
                borderWidth: 2,
                data: [640, 660, 700, 720, 790, 832, 860, 920]
            }]
        }
    },
    options: {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
      bodySpacing: 4,
      mode:"nearest",
      intersect: 0,
      position:"nearest",
      xPadding:10,
      yPadding:10,
      caretPadding:10
    },
    responsive: 1,
    scales: {
             xAxes: [{
              display:1,
              gridLines: {
                 color: "rgba(0, 0, 0, 0.01)",
              },
              ticks: {
                  display: false
              },

            }],
            yAxes: [{
                categoryPercentage: 0.8,
                barPercentage: 0.6,
                maxBarThickness: 12,
                display:1,
                gridLines: {
                  color: "rgba(0, 0, 0, 0.01)",
                },
                ticks: {
                  display: true
                }
            }]
      },
    layout:{
      padding:{left:0,right:0,top:0,bottom:0}
    }
},
}

class StudentsStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      industriesData: {},
      regionsData: {},
      ageRangeData: {},
      attendedSessionsData: {},
      industriesCount: 0,
      regionsCount: 0,
      blockedCount: 0,
      registredStudentsCount: 0,
      studentsByGenderData: studentsByGenderData,
      newStudentsStats: newStudentsStats,
    };
  }
  async componentWillMount() {
    await this.getStudentsByGender();
    await this.getRegions();
    await this.getAgeRange();
    await this.getNewStudentsStats();
    await this.getAttendedSessionsData();
    await this.getCount();
    await this.getRegistredStudentsCount();
  }

   getStudentsByGender=async()=> {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    let {studentsByGenderData}= this.state;
    try {
      const res = await API.get(`Profiles/NumberStudentsByGender`, {
        params: { access_token: accessToken },
      });
      if (res.status === 200) {
        studentsByGenderData.datasets[0].data[0] = res .data && res.data.male;
        studentsByGenderData.datasets[0].data[1] = res .data && res.data.female;
        studentsByGenderData.datasets[0].data[2] = res .data && res.data.Other + res.data.countNoGender;
        this.setState({
            studentsByGenderData: studentsByGenderData
        })
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
   getNewStudentsStats=async()=> {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    
    try {
      const res = await API.get(`Profiles/NewStudentsStat`, {
        params: { access_token: accessToken },
      });
      if (res.status === 200) {
        let newStudentsStats = {
          data: (canvas) => {
              var ctx = canvas.getContext("2d");
              var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, '#18ce0f');
              gradientStroke.addColorStop(1, primaryColor);
              var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, "rgba(38, 166, 154, 0)");
              gradientFill.addColorStop(1, "rgba(38, 166, 154, 0)");
              return {
                  labels: Object.keys(res.data),
                  datasets: [{
                      label: "New students",
                      borderColor: primaryColor,
                      pointBorderColor: "#FFF",
                      pointBackgroundColor: primaryColor,
                      pointBorderWidth: 2,
                      pointHoverRadius: 2,
                      pointHoverBorderWidth: 2,
                      pointRadius: 4,
                      fill: true,
                      backgroundColor: gradientFill,
                      borderWidth: 2,
                      data: Object.values(res.data)
                  }]
              }
          },
          options: {
          maintainAspectRatio: false,
          legend: {
              display: false
          },
          tooltips: {
            bodySpacing: 4,
            mode:"nearest",
            intersect: 0,
            position:"nearest",
            xPadding:10,
            yPadding:10,
            caretPadding:10
          },
          responsive: 1,
          scales: {
                   xAxes: [{
                    display:1,
                    gridLines: {
                       color: "rgba(0, 0, 0, 0.01)",
                    },
                    ticks: {
                        display: false
                    },
      
                  }],
                  yAxes: [{
                      categoryPercentage: 0.8,
                      barPercentage: 0.6,
                      maxBarThickness: 12,
                      display:1,
                      gridLines: {
                        color: "rgba(0, 0, 0, 0.01)",
                      },
                      ticks: {
                        display: true
                      }
                  }]
            },
          layout:{
            padding:{left:0,right:0,top:0,bottom:0}
          }
      },
      }
        this.setState({
          newStudentsStats: newStudentsStats
        })
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Profiles/count`, {
        params: { access_token: accessToken, where: {revoked:true} },
      });
      if (res.status === 200) {
        this.setState({ blockedCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  async getRegistredStudentsCount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const res = await API.get(`Profiles/count`, {
        params: { access_token: accessToken },
      });
      if (res.status === 200) {
        this.setState({ registredStudentsCount: res.data.count });
      } else {
        throw res.status;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  getAgeRange = async key => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const ageRangeResult = await API.get(`Profiles/NumberStudentsByAge`, {
        params: { access_token: accessToken },
      });

      if (ageRangeResult.status === 200) {
        const ageRange = ageRangeResult.data;
        const ageRangeData = {
          labels: ["40+","30-40","20-30","-20"],
          datasets: [
            {
              data: [ageRange.plusForty,ageRange.betweenThirtyForty,ageRange.betweenTwentyThirty,ageRange.underTwenty],
              label: "users",
              backgroundColor: "rgba(212, 50, 114,1)",
              borderColor: "rgba(212, 50, 114,0.8)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(212, 50, 114,0.8)",
              hoverBorderColor: "rgba(212, 50, 114,1)"
            }
          ]
        };
        this.setState({ ageRangeData: ageRangeData });
      } else {
        console.log("ageRange status :", ageRangeResult.status);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  getAttendedSessionsData = async key => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const attendedSessionsResult = await API.get(`Profiles/sessionsAttended`, {
        params: { access_token: accessToken },
      });

      if (attendedSessionsResult.status === 200) {
        const attendedSessions = attendedSessionsResult.data;
        const attendedSessionsData = {
          labels: ["+10 sessions","+5 sessions","1 session"],
          datasets: [
            {
              data: [attendedSessions.moreThanTencount,attendedSessions.moreThanFivecount,attendedSessions.oneCount],
              label: "users",
              backgroundColor: "rgba(212, 50, 114,1)",
              borderColor: "rgba(212, 50, 114,0.8)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(212, 50, 114,0.8)",
              hoverBorderColor: "rgba(212, 50, 114,1)"
            }
          ]
        };
        this.setState({ attendedSessionsData: attendedSessionsData });
      } else {
        console.log("ageRange status :", attendedSessionsResult.status);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  getRegions = async key => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const regionsResult = await API.get(`Profiles/NumberStudentsByGovernorate`, {
        params: { access_token: accessToken },
      });

      if (regionsResult.status === 200) {
        const regions = regionsResult.data;
        var indexes = this.getAllIndexes(regions.numbers, 0);
        const regionsData = {
          labels: indexes.map(i => regions.gov[i]),
          datasets: [
            {
              data: indexes.map(i => regions.numbers[i]),
              backgroundColor: indexes.map(i => backgroundColor[i]),
              hoverBackgroundColor: indexes.map(i => hoverBackgroundColor[i])
            }
          ]
        };

        this.setState({
          regionsData: regionsData,
          regionsCount: regionsResult.data.numbers.length
        });
      } else {
        console.log("regions status :", regionsResult.status);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  getAllIndexes = (arr, val) => {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] !== val) indexes.push(i);
    return indexes;
  };

  render() {
    const {
      industriesData,
      regionsData,
      blockedCount,
      registredStudentsCount,
      ageRangeData,
      attendedSessionsData,
      studentsByGenderData,
      newStudentsStats
    } = this.state;
    const options12 = {
      legend: {
        display: false
      }
    };

    const options32 = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)"
            },
            ticks: {
              display: false,
              beginAtZero: true
            }
          }
        ],
        yAxes: [
          {
            categoryPercentage: 0.6,
            barPercentage: 0.5,
            maxBarThickness: 15,
            gridLines: {
              color: "rgba(0, 0, 0, 0.01)"
            },
            ticks: {
              display: true,
              beginAtZero: true
            }
          }
        ]
      },
      maintainAspectRatio: false
    };

    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Students statistics</h1>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "30px" }}
              >
                <div
                  className="col-md-4 chart-card"
                >
                  <h2 style={{ fontWeight: "400", color: "#505458",marginBottom: "20px" }}>
                    Students Gender
                  </h2>
                  <div
                    className="chart-area"
                  >
                    <Doughnut
                      height={240}
                      data={studentsByGenderData}
                      options={options12}
                    />
                  </div>
                  <br></br>
                  <div className="widdata">
                    <h2 className="widtitle">
                      {studentsByGenderData.datasets &&
                        studentsByGenderData.datasets[0].data[0] + studentsByGenderData.datasets[0].data[1] + studentsByGenderData.datasets[0].data[2]}{" "}
                      students
                    </h2>
                      <p className="widtag" style={{fontSize: "16px"}}>{`${studentsByGenderData.datasets[0].data[0]} male & ${studentsByGenderData.datasets[0].data[1]} female`}</p>
                  </div>
                </div>
                <div
                  className="col-md-7 chart-card"
                >
                  <section /* className="box " */>
                    <header className="panel_header">
                      <h2 className="title float-left chart-title">Age Range</h2>
                    </header>
                    <div className="content-body">
                      {" "}
                      <div className="row">
                        <div className="col-12">
                          <div className="chart-container">
                            <div className="chart-area">
                              <HorizontalBar
                                data={ageRangeData}
                                options={options32}
                                height={325}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ display: "flex", marginBottom: "30px" }}
              >
                <div
                  className="col-md-4 chart-card"
                >
                  <h2 style={{ fontWeight: "400", color: "#505458",marginBottom: "20px" }}>
                    Regions
                  </h2>
                  <div
                    className="chart-area"
                  >
                    <Doughnut
                      height={240}
                      data={regionsData}
                      options={options12}
                    />
                  </div>
                  <br></br>
                  <div className="widdata">
                    <h2 className="widtitle">
                      {regionsData.datasets &&
                        regionsData.datasets[0] &&
                        regionsData.datasets[0].data.length}{" "}
                      Gouvernorates
                    </h2>
                    <p className="widtag" style={{fontSize: "16px"}}>
                      on {this.state.regionsCount} Gouvernorates
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-7 chart-card"
                >
                  <section /* className="box " */>
                    <header className="panel_header">
                      <h2 className="title float-left chart-title">Sessions attended</h2>
                    </header>
                    <div className="content-body">
                      {" "}
                      <div className="row">
                        <div className="col-12">
                          <div className="chart-container">
                            <div className="chart-area">
                              <HorizontalBar
                                data={attendedSessionsData}
                                options={options32}
                                height={325}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div
                className="col-md-12 pl-0"
                style={{ display: "flex", marginBottom: "30px" }}
              >
                <div className="col-md-5" style={{maxWidth:"481px"}}>
                  <div className="db_box iconbox" style={{boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",height: "230px", borderRadius: "15px"}}>
                    <div className="widdata">
                      <i className="widicon i-people icon-lg icon-primary" style={{fontSize: "45px"}}></i>
                      <h2 className="widtitle" style={{fontSize: "30px"}}>
                        <b>{studentsByGenderData.datasets &&
                        studentsByGenderData.datasets[0].data[0] + studentsByGenderData.datasets[0].data[1] + studentsByGenderData.datasets[0].data[2]}</b>
                      </h2>
                      <h4 className="widtag">Students registered</h4>
                    </div>
                  </div>
                  <div className="db_box iconbox" style={{boxShadow: "0 1px 3px rgba(33, 33, 33, 0.2)",height: "230px",marginBottom: "0px", borderRadius: "15px"}}>
                    <div className="widdata">
                      <i className="widicon i-user-unfollow icon-lg icon-primary" style={{fontSize: "45px"}}></i>
                      <h2 className="widtitle" style={{fontSize: "30px"}}>
                        <b>{blockedCount}</b>
                      </h2>
                      <h4 className="widtag">Students blocked</h4>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-7 chart-card"
                >
                  <section>
                                <header className="panel_header">
                                    <h2 className="title float-left chart-title">New students</h2>
                                    
                                </header>
                                <div className="content-body">        <div className="row">
                                        <div className="col-12">
                                            <div className="chart-container">

                                              <Line data={newStudentsStats.data} options={newStudentsStats.options} height={380}/>


                                            </div>
                                        </div>  

                                    </div> 
                                </div>
                            </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default StudentsStatistics;
