import React from "react";
import empty_pic_classroom from "assets/img/empty_pic_classroom.png";
import ReadMoreAndLess from "react-read-more-less";
import { UncontrolledTooltip } from 'reactstrap';
import moment from "moment";
import tnd from './../../assets/img/tnd.svg';

const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;

class SessionCard extends React.Component {
  sessionName=(name)=>{
  if(name === "Completed"){
    return "Complete"
  }
  else return name
  }
  render() {
    let { session, index, isPublic, isDisabled, subscriptions } = this.props;
    const connectedId = localStorage.getItem("WeCodeLand_user");
    let connectedIsSubscribed = !!(subscriptions && subscriptions.filter(subscription => subscription.studentId === connectedId).map(subscription => subscription.going)[0]);
    console.log("connectedIsSubscribed: ", connectedIsSubscribed);
    return (
      <div id="sessionCard" className={isPublic? "" :"col-md-4 col-lg-3 pl-2 pr-2"} key={index}>
        {session && session.title && session.title.length>0 ?
          <UncontrolledTooltip key={`${index}-${session && session.id}`} placement="top" target={`session-name${index}-${session && session.id}`}>
            {session && session.title}
          </UncontrolledTooltip>
        :null}
        <div className="team-member ">
          <div className="d-flex">
            {/* <img
              alt=""
              src={
                session && session.tutors && session.tutors.image && session.tutors.image.name
                  ? `${SERVERDIR}Containers/img/download/${
                      session && session.tutors.image.name
                    }`
                  : empty_pic_classroom
              }
            /> */}

            <div className="card-title-container" style={{paddingLeft: '18px', maxWidth: "273px"}}>
              <h3 id={`session-name${index}-${session && session.id}`} className="hoverable-text" onClick={() => window.open(`${BASEDIR}/session/${session && session.id}`, "_blank")}>
                {session && session.title}
              </h3>
              <h5 className="mb-0">By <span className="hoverable-text bold" onClick={() => window.open(`${BASEDIR}/profile/${session && session.tutors && session.tutors.id}`, "_blank")}>{session && session.tutors && session.tutors.fullName}</span>
              </h5>
            </div>
            {/* <div className="bookmark-icon-container">
                <i className="fa fa-bookmark"></i>
            </div> */}
          </div>
          <div className="team-img mt-3">
            <img
              className="img-fluid"
              src={
                session && session.SessionImage && session.SessionImage.name
                  ? `${SERVERDIR}Containers/img/download/${
                      session && session.SessionImage.name
                    }`
                  : empty_pic_classroom
              }
              alt=""
            />
          </div>
          <div className="team-info">
            {/* <h3 className="hoverable-text" onClick={() => window.open(`${BASEDIR}/session/${session && session.id}`, "_blank")}>
              {session && session.title}
            </h3> */}
            <div className="rating-container">
                <i className="fa fa-star"></i>
            <span>&nbsp;{session && session.rating}</span>
            </div>
            {session && session.domains && session.domains.length>0 ?
            <div className="d-flex">
                <i className="fa fa-tag"></i>
                <ReadMoreAndLess
                    ref={this.ReadMore}
                    className="read-more-content"
                    charLimit={session && session.domains && session.domains.join(', ').substring(0,18).lastIndexOf(',') >0 ? session && session.domains.join(', ').substring(0,18).lastIndexOf(',') : 25}
                    readMoreText="view more"
                    readLessText="view less"
                >
                    {session && session.domains && session.domains.join(', ')+" " || " "}
                </ReadMoreAndLess>
            </div>
            :<div style={{height:"23px"}}></div>}
            {session && session.start ? (
              <div className={isPublic ? "mt-3" : "mt-1"}>
                <i className="fa fa-calendar-o"></i>
                &nbsp;&nbsp;{moment(session && session.start).format("D/MM/YYYY")}
                {" To "}
                {moment(session && session.end).format("D/MM/YYYY")}
              </div>
            ) : null}
            {session && session.price >0 ? (
              <div className={isPublic ? "mt-3" : "mt-1"}>
                {/* <i className="fa fa-dollar"></i> */}
                <img style={{height: "17px",marginBottom: "3px"}} src={tnd} alt="TND" />
                <span style={{fontWeight: "400"}}>&nbsp;&nbsp;{session && session.price}</span>
              </div>
            ) : null}
            <div className="card-description">
              <ReadMoreAndLess
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={100}
                readMoreText="view more"
                readLessText="view less"
              >
                {session && session.description + " "|| " "}
              </ReadMoreAndLess>
            </div>
          </div>
          <div className="card-button-container">
            <button
              className={(connectedIsSubscribed && connectedIsSubscribed) || ((session && (session.status === "Full" || session.status === "Completed")))  ? "btn btn-primary" : "custom-button outline"}
              /* disabled={isDisabled} */
              onClick={()=>this.props.buttonCLickHandler(session,index)}
              disabled={session && (session.status === "Full" || session.status === "Completed")}
            >
              <i className={(session && (session.status === "Full" || session.status === "Completed"))?  "fa fa-check-circle" : connectedIsSubscribed ? "fa fa-times" : "fa fa-check"}></i>
              &nbsp;&nbsp;{(session && (session.status === "Full" || session.status === "Completed"))?  this.sessionName(session.status) : connectedIsSubscribed ? "Not Going" : "Participate"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default SessionCard;
