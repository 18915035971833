import React from "react";
import empty_pic_classroom from "assets/img/empty_pic_classroom.png";
import ReadMoreAndLess from "react-read-more-less";
import moment from "moment";
import {
  UncontrolledCarousel , Input
} from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;

class ClassroomCard extends React.Component {

  showInMap = (location) => {
    window.open(
      "https://maps.google.com?q=" + location.lat + "," + location.lng
    );
  };
  render() {
    let { room, index, isPublic } = this.props;
    return (
      <div id="classroomCard" className={isPublic? "" :"col-md-4 col-lg-3 pl-2 pr-2"} key={index}>
        {room && room.address && room.address.name && room.address.name.length>0 ?
          <UncontrolledTooltip key={index} placement="top" target={`address-name${index}`}>
            {room && room.address && room.address.name}
        </UncontrolledTooltip>
        :null}
        <div className="team-member ">
          <div className="d-flex">
            <img
              alt=""
              src={
                room && room.logo && room.logo.name
                  ? `${SERVERDIR}Containers/img/download/${
                      room && room.logo.name
                    }`
                  : empty_pic_classroom
              }
            />

            <div className="card-title-container">
              <h3 className="hoverable-text" onClick={() => window.open(`${BASEDIR}/classroom/${room && room.id}`, "_blank")}>
                {room && room.name}
              </h3>
              <h5>{room && room.size} persons max</h5>
            </div>
            {this.props.selection?
              <div className="checkbox-container">
                <Input type="checkbox" defaultChecked={room.checked!==""} value={room.id} onChange={(event)=>this.props.handleClassroomCheck(room.id,event)}></Input>
                <span className="checkmark"></span>
            </div>
            :null}
            {(!this.props.selection) && (room && room.profileId === localStorage.getItem('WeCodeLand_user')) ?
              <div className="edit-container">
              <h3 className="text-right" style={{ fontSize: "14px" }}>
                <i
                  style={{ cursor: "pointer",opacity: "0.6",marginRight: "3px" }}
                  onClick={()=>window.open(`${BASEDIR}/editClassroom/${room && room.id}`, "_blank")}
                  className="fa fa-pencil"
                ></i>{" "}
                <i
                  style={{ cursor: "pointer",opacity: "0.6" }}
                  onClick={()=>this.props.deleteRoomHandler(room && room.id)}
                  className="fa fa-trash"
                ></i>
              </h3>
              </div>
            :null}
          </div>
          <div className="team-img">
            {room && room.album && room.album.length > 1 ?
            <UncontrolledCarousel controls={false} items={room &&
              room.album &&
              room.album.length > 0 &&
              room.album.map(v => ({src : v.name ? `${SERVERDIR}Containers/img/download/${v.name}`  : empty_pic_classroom, altText: 'Classroom Picture', caption: 'Classroom Picture Caption'}))} />
              :
                <img
              className="img-fluid"
              src={
                room &&
                room.album &&
                room.album.length > 0 &&
                room.album[0] &&
                room.album[0].name
                  ? `${SERVERDIR}Containers/img/download/${
                      room && room.album[0].name
                    }`
                  : empty_pic_classroom
              }
              alt=""
                />
            }
          </div>
          <div className="team-info">
            {isPublic?
            <div className="rating-container">
                <i className="fa fa-star"></i>
                <span>&nbsp;{"3.5"}</span>
            </div>
            :null}
            <div className={isPublic ? "card-description mt-3" : "card-description mt-1"}>
              <ReadMoreAndLess
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={100}
                readMoreText="view more"
                readLessText="view less"
              >
                {(room && room.description + " ") || " "}
              </ReadMoreAndLess>
            </div>
            {isPublic ? (
              <div className={isPublic ? "mt-3" : "mt-1"}>
                <i className="fa fa-user"></i>
                &nbsp;&nbsp;{(room && room.ownerName + " ") || " "}
                <span
                className="underlined-clickable"
                onClick={() => window.open(`${BASEDIR}/profile/${room && room.profileId}`, "_blank")}
              >
                see Profile
              </span>
              </div>
            ) : null}
            {room && room.from ? (
              <div className={isPublic ? "mt-3" : "mt-1"}>
                <i className="fa fa-calendar-o"></i>
                &nbsp;&nbsp;{moment(room && room.from).format("D/MM/YYYY")}
                {" To "}
                {moment(room && room.to).format("D/MM/YYYY")}
              </div>
            ) : null}
            {room && room.address && room.address.name && room.address.name.length >0 ?
            <div className={isPublic ? "mt-3 classroomCard-footer" : "mt-1"}>
              {room && room.address && room.address.name && room.address.name.length >0 ?
              <div style={{
                height: "39px",
                overflow: "hidden"}}>
                <i className="fa fa-map-marker"></i>
                &nbsp;&nbsp;
                <span id={`address-name${index}`}>{room && room.address && room.address.name} </span>
              </div>
              :<div style={{width:"39px"}}></div>
              }
              {room && room.address && room.address.name && room.address.name.length >0 ?
              <span
                className="underlined-clickable"
                onClick={() => this.showInMap(room && room.location)}
              >
                see on map
              </span>
              :<div style={{width:"16px"}}></div>}
            </div>
            :<div className="mt-1" style={{height:"62px"}}></div>}
          </div>
          {(!this.props.selection) && (!isPublic) && (room && room.profileId === localStorage.getItem('WeCodeLand_user')) ?
            <div className="card-button-container">
            <button
              className={
                room && room.status && room.status.toUpperCase() === "NEW"
                  ? "btn btn-primary"
                  : "custom-button outline"
              }
              onClick={() => this.props.buttonCLickHandler(room)}
            >
              <i
                className={
                  room && room.status && room.status.toUpperCase() === "NEW"
                    ? "fa fa-eye-slash"
                    : "fa fa-eye"
                }
              ></i>
              &nbsp;&nbsp;
              {room && room.status && room.status.toUpperCase() === "NEW"
                ? "Make Public"
                : "Place in draft"}
            </button>
          </div>
          :null}
        </div>
      </div>
    );
  }
}
export default ClassroomCard;
