import React from 'react';
import {
  Row, Col, Input,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import API from 'helpers/api.jsx'
import { notify } from 'helpers/common.jsx';
import empty_pic_room from "assets/img/course-default.png";
import NotificationAlert from 'react-notification-alert';
import TagsInput from 'components/Tags/TagsInput.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import Script from 'react-load-script';
// import Autocomplete from 'react-google-autocomplete';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

class NewClassroom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      description: '',
      size: 0,
      startDate: moment(),
      endDate: moment(),
      isFileUploading: false,
      classroomLogo: {
        preview: empty_pic_room,
        data: {},
        container: {}
      },
      equipments: [],
      album: [],
      address: {
        name: '',
        area: '',
      	city: '',
      	state: ''
      },
      location: {lat: 0, lng: 0},
      modalConfirmAction: false,
      inputKey : Date.now(),
      multiInputKey : 0,
    };
    this.notificationAlert = React.createRef();
  }

  getPosition = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }),
      err => console.log(err)
    );
  }

  componentWillMount=async()=>{

    // set initial position
    this.getPosition();
    // set Authorization header
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;

  }

  selectedEquipments=(equipments)=>{
    this.setState({equipments:equipments})
  }

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value
    })
  }

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
      endDate : date.isBefore(this.state.endDate)? this.state.endDate : date
    });
  }

  handleEndDateChange = (date) => {
    this.setState({
      startDate : date.isAfter(this.state.startDate)? this.state.startDate : date,
      endDate: date
    });
  }


  addClassroom = (e) => {
    e.preventDefault();

    const request = {
      name: this.state.name,
      description: this.state.description,
      size: this.state.size,
      from: this.state.startDate.format('YYYY-MM-DD'),
      to: this.state.endDate.format('YYYY-MM-DD'),
      location: this.state.location,
      address: this.state.address,
      logo: this.state.classroomLogo.container,
      album: this.state.album,
      equipments: this.state.equipments,
      profileId: localStorage.getItem('WeCodeLand_user')
    };

    API
      .post(`${SERVERDIR}Rooms`, request)
      .then(res => {
        if (res.status === 200) {
          notify({ type: 'success', message: "success" }, this);
          this.props.history.push(BASEDIR + '/myClassrooms');
        } else {
          this.setState({modalConfirmAction:false})
          throw res.status;
        }
      })
      .catch(error => {
        console.log('error:', error);
        const {
          response: {
            data: {
              error: { message }
            }
          } = {}
        } = error;
        notify({ type: 'danger', message: message==="Authorization Required"? "You are not able to add a classroom" : message }, this);
        this.setState({modalConfirmAction:false});
      });
  }

  //** UPLOAD COURSE IMAGE */
  photoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { classroomLogo } = this.state;
    let uploadedPhoto;
    if (e.target.name === "classroomLogo") {
      classroomLogo.preview = URL.createObjectURL(file);
      classroomLogo.data = file;
      uploadedPhoto = {
        file: classroomLogo,
        name: "classroomLogo"
      };
    }

    const fileData = new FormData();
    fileData.append('file', uploadedPhoto.file.data);

    try {let imageProfile = await API.post(`${SERVERDIR}Containers/img/upload`, fileData);
    if (imageProfile.status === 200) {
      uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
      await this.setState({
        [uploadedPhoto.name]: uploadedPhoto.file
      });
    } else {
      throw imageProfile.status;
    }} catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      if (message.includes("contentType"))
      notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey : Date.now()
      })
    } finally {
    }
  }

  resetClassroom = () => {
    this.setState({
      name: '',
      description: '',
      size: 0,
      startDate: moment(),
      endDate: moment(),
      isFileUploading: false,
      classroomLogo: {
        preview: empty_pic_room,
        data: {},
        container: {}
      },
      equipments: [],
      album: [],
      address: {
        name: '',
        area: '',
      	city: '',
      	state: ''
      },
      location: {lat: 0, lng: 0}
    })
  }

  handelOnUploadFile = async(e) => {
    e.preventDefault();
    const files = e.currentTarget.files
    this.setState({
      isFileUploading: true
    });

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i])
    }


    try {let filesUploaded= await API.post(`${SERVERDIR}Containers/img/upload`, formData)
    if (filesUploaded.status === 200) {
      // flatten array
      const albumfiles = filesUploaded.data.result.files;
      const album = Object.keys(albumfiles).reduce(function (r, k) {
        return r.concat(albumfiles[k].flat());
      }, []);

      this.setState({
        album: album,
        isFileUploading: false
      })
      notify({ type: 'success', message: "files Uploaded" }, this);
     
    } else {
      throw filesUploaded.status;
    }} catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      if (message.includes("contentType")){
      notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        multiInputKey : this.state.multiInputKey +1,
        isFileUploading: false
      })}
    } finally {
    }
  }

  /**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getCity = ( addressArray ) => {
		let city = '';
		for( let i = 0; i < addressArray.length; i++ ) {
			if ( addressArray[ i ].types[0] && 'administrative_area_level_2' === addressArray[ i ].types[0] ) {
				city = addressArray[ i ].long_name;
				return city;
			}
		}
	};
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getArea = ( addressArray ) => {
		let area = '';
		for( let i = 0; i < addressArray.length; i++ ) {
			if ( addressArray[ i ].types[0]  ) {
				for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
					if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
						area = addressArray[ i ].long_name;
						return area;
					}
				}
			}
		}
	};
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
	getState = ( addressArray ) => {
		let state = '';
		for( let i = 0; i < addressArray.length; i++ ) {
			for( let i = 0; i < addressArray.length; i++ ) {
				if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
					state = addressArray[ i ].long_name;
					return state;
				}
			}
		}
	};

  // onPlaceSelected = ( place ) => {
	// 	const address = place.formatted_address,
	// 	      addressArray =  place.address_components,
	// 	      city = this.getCity( addressArray ),
	// 	      area = this.getArea( addressArray ),
	// 	      state = this.getState( addressArray ),
	// 	      latValue = place.geometry.location.lat(),
	// 	      lngValue = place.geometry.location.lng();
  //   const Place = {
  //     name: ( address ) ? address : '',
  //     area: ( area ) ? area : '',
  //     city: ( city ) ? city : '',
  //     state: ( state ) ? state : ''
  //   };
  //   console.log("test: ", Place);
  //   this.setState({
  //     address: Place,
  //     location: {lng: lngValue, lat: latValue}
  //   })
	// };

  onPlacesSelected = async ( result ) => {
    const place = (await geocodeByPlaceId(result.place_id))[0];
    // console.log("places: ", place);
    const address = place.formatted_address,
		      addressArray =  place.address_components,
		      city = this.getCity( addressArray ),
		      area = this.getArea( addressArray ),
		      state = this.getState( addressArray ),
		      latValue = place.geometry.location.lat(),
		      lngValue = place.geometry.location.lng();
    console.log({
      address: {
        name: ( address ) ? address : '',
        area: ( area ) ? area : '',
    		city: ( city ) ? city : '',
    		state: ( state ) ? state : ''
      },
      location: {lng: lngValue, lat: latValue}
    });
    this.setState({
      address: {
        name: ( address ) ? address : '',
        area: ( area ) ? area : '',
    		city: ( city ) ? city : '',
    		state: ( state ) ? state : ''
      },
      location: {lng: lngValue, lat: latValue}
    })
  };
  toggleModalConfirmAction = (e) => {
    e.preventDefault();
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction
    });
  }


  render() {
    const { isFileUploading } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>

              <div className="page-title">
                <div className="float-left">
                  <h1 className="title" style={{ textTransform: "none" }}>New Classroom</h1>
                </div>
              </div>


              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                    </header>
                    <div className="content-body">
                    <form id="myForm" onSubmit={this.addClassroom} method="post">
                      <div className="row">

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                            <div className="form-group">
                              <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Name</label>
                              <input type="text" name="name" maxLength="40" onChange={this.updateInputValue} value={this.state.name} className="form-control" id="imputTitle" placeholder="Title" style={{ borderRadius: "4px" }} required />
                            </div>
                            <div className="form-group">
                              <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Description</label>
                              <Input type="textarea" rows="5" maxLength="350" cols="50" className="form-control custom-fixed-textarea" name="description" value={this.state.description} onChange={this.updateInputValue} id="inputDescription" placeholder="Tell us about the course" required />
                            </div>

                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" >
                          <div className="form-group">
                            <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Logo (optional)</label>
                            <div className="mintit-upload-image-container">
                              <img className="mintit-upload-image" alt="" src={this.state.classroomLogo.preview}/>
                              <div className="mintit-upload-image-overlay">
                                <p><input type="file" name="classroomLogo" key={this.state.inputKey} id="classroomLogo" onChange={this.photoChangeHandler} style={{ marginTop: '10px' }} /></p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                        <div className="row">
                          <div className="form-group col-md-3">
                            <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Number of places (maximum)</label>
                            <input type="number" min="0" name="size" onChange={this.updateInputValue} value={this.state.size} className="form-control" id="imputTitle" placeholder="Insert number of places here" style={{ borderRadius: "4px" }} required />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Start Date</label>
                            <DatePicker
                            name="startDate"
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange}
                            minDate={moment()}
                            fixedHeight
                            required />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>End Date</label>
                            <DatePicker
                            name="endDate"
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            minDate={this.state.startDate}
                            fixedHeight
                            required />
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Location</label>
                            {/*<Autocomplete
                							className="form-control"
                							onPlaceSelected={ this.onPlaceSelected }
                						/>*/}
                            <GooglePlacesAutocomplete
                              onSelect={this.onPlacesSelected}
                              autocompletionRequest={{
                                componentRestrictions: {
                                  country: ['tn','LY'],
                                }
                              }}
                            />
                            {/*<input id="location-autocomplete" type="text" name="address" value={this.state.address} className="form-control" placeholder="Title" style={{ borderRadius: "4px" }} required />*/}
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                                <label htmlFor="imputSkills" style={{ fontWeight: "bold" }}>Equipment</label>
                                <TagsInput key={this.state.equipments.length} placeholder="Enter an equipment and press the + button to add it" selectedTags={this.selectedEquipments}  tags={this.state.equipments}/>
                          </div>
                          <div className="form-group col-md-6">
                                <label htmlFor="imputDomains" style={{ fontWeight: "bold" }}>Classroom’s pictures (you can add one or muliple ones)</label>
                                <Input key={this.state.multiInputKey} type="file" multiple name="album" id="inputFile" onChange={this.handelOnUploadFile} style={{ marginTop: '10px' }} />
                          </div>
                        </div>
                        <div className="row" style={{justifyContent: "center"}}>
                              <button type="button" onClick={this.resetClassroom} className="btn btn-primary" style={{ backgroundColor: "#444444", BoxShadow: "0px 0px 12px" , width:"40%"}}>Cancel</button>
                              <button disabled={isFileUploading} onClick={this.toggleModalConfirmAction} className="btn btn-primary" style={{ width:"40%"}}>Save</button>
                        </div>
                      </form>
                    </div>
                  </section></div>
              </div>
            </Col>

          </Row>
        </div>
        <Modal isOpen={this.state.modalConfirmAction} toggle={this.toggleModalConfirmAction}>
              <ModalHeader>Adding a new classroom</ModalHeader>
              <ModalBody>
                <h3>Are you sure you want to save these changes</h3>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="save-button"
                  color="primary"
                  form="myForm" key="submit" htmltype="submit"
                >
                  {"Confirm"}
                </Button>{" "}
                <Button className="save-button" color="secondary" onClick={this.toggleModalConfirmAction}>
                  {"Cancel"}
                </Button>
              </ModalFooter>
            </Modal>
      </div>
    );
  }
}

export default NewClassroom;
