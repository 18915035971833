import React from "react";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { notify } from '../../../helpers/common';
import NotificationAlert from 'react-notification-alert';

var SERVERDIR = process.env.REACT_APP_API;
var BASEDIR = process.env.REACT_APP_BASEDIR;


class Activation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,    
      verified: false
    };
  }
  async componentWillMount() {
    await this.confirmProfile();
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }
  confirmProfile = async () => {
    this.setState({
      loading: true
    });

    try {
      const profile = await axios.get(
        `${SERVERDIR}Profiles/confirm${this.props.location &&
          this.props.location.search}`
      );

      if (profile.status === 204) {
        this.setState({
          verified: true,
          loading: false
        });

        notify(
          { type: "success", message: "Your Account has been Activated" },
          this
        );
        this.props.history.push(BASEDIR + "/login");
      }
    } catch (error) {
      this.setState({
        loading: false
      });
    }
}


  render() {

    if (this.state.loading)
     return (
    <div className="content-fluid h-100 d-flex justify-content-center align-items-center">
      <Loader
        type="ThreeDots"
        color="#D43272"
        height={80}
        width={80}
        timeout={900000}
      />
    </div>);
  
    return (
      <div>
        <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </div>
    );
  }
}

export default Activation;
