import React from 'react';
import {
    Row, Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';


import NotificationAlert from "react-notification-alert";


import loginPic from "assets/img/login_pic.png";
import {notify} from "../../../helpers/common";
import API from "../../../helpers/api";
import ReactGA from 'react-ga';

var BASEDIR = process.env.REACT_APP_BASEDIR;


class Register extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            email: "",
            password: "",
            agreed: false,
            modalThanks: false,
        };

        this.notificationAlert = React.createRef();
    }
    componentDidMount(){
    ReactGA.initialize('UA-173264282-1');
    }
    toggleModalThanks = () => {
        this.setState({ modalThanks: !this.state.modalThanks });
      };
      goToLogin = () => {
        this.setState({ modalThanks: !this.state.modalThanks });
        this.props.history.push(BASEDIR + "/login");
      };

    handleInputChange = e => {
        const target = e.target;
        let value = target.value;
        if(target.type === "checkbox") {
            value = target.checked;
        }
        const name = target.name;

        this.setState({
            [name]: value
        })

    };


    handleSubmit = async (e) => {
        e.preventDefault();

        if(!this.state.agreed) {
            notify({ type: 'danger', message: "Please agree to the terms and conditions" }, this);
            return;
        }

        this.setState({loading: true});
        

        //notify({ type: 'success', message: "success" }, this);
        //this.props.history.push(BASEDIR + 'register');

        const {fullName, password} = this.state;
        const email = this.state.email.toLowerCase();

        try {
            const result = await API.post(`Profiles`, {
                    fullName, email, password
            });

            if (result.status === 200) {
                ReactGA.event({
                    category: 'Registration',
                    action: 'Created an account'
                  })

                this.setState({ loading: false });
                this.setState({
                    loading: false,
                    modalThanks: true
                  });
            }

        } catch (error) {
            this.setState({ loading: false });
            const {
                response: {
                    data: {
                        error: { code, message }
                    }
                } = {}
            } = error;

            notify({ type: 'danger', message:  message.includes("already exists") ? "Email already exists" : message }, this);

        }
    };

    render(){

        return (
            <div>

                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>

                <div className="sign_container">


                    <Row>
                        <Col md={5} sm={0} className="d-none d-md-block">
                            <img src={loginPic} alt="banner" className="login_picture"/>
                        </Col>
                        <Col md={7} sm={12}>

                            <Row>
                                <Col md={8} sm={{ size: 10, offset: 1 }}>

                                    <div className="sign_content">
                                        <h4 className="sign_title">Create an account</h4>

                                        <div className="sign_form">
                                            <form name="loginform" id="loginform" onSubmit={this.handleSubmit}>


                                                <div className="form-group">
                                                    <label htmlFor="user_login">Full Name</label>
                                                    <input className="form-control" id="user_login" name="fullName" value={this.state.fullName} onChange={this.handleInputChange} required />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="user_email">Email</label>
                                                    <input type="email" className="form-control" id="user_email" name="email" value={this.state.email} onChange={this.handleInputChange} required />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="user_pass">Password</label>
                                                    <input type="password" className="form-control" id="user_pass" placeholder="Password" name="password" value={this.state.password} onChange={this.handleInputChange} minLength="8" maxLength="20"  required />
                                                </div>

                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" name="agreed" checked={this.state.agreed} onChange={this.handleInputChange} />
                                                    <label className="form-check-label register-check-label" htmlFor="exampleCheck1"> I agree to the <span className="clickable purple bold" onClick={() => window.open(`${BASEDIR}/cgu`, "_blank")}>terms and conditions</span></label>
                                                </div>

                                                <input type="submit" name="wp-submit" id="wp-submit" className="btn btn-primary btn-block btn_login" value="Register" />
                                            </form>
                                            <p id="nav" className="sign_notice"style={{textDecorationLine: "none"}}>
                                            already have an account ? <a href={`${BASEDIR}/login`} title="Sign In" className="bold"  style={{textDecorationLine: "underline", fontWeight:" bold"}}>Sign In</a>
                                            </p>


                                        </div>

                                    </div>

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
                <div>
          <Modal
            isOpen={this.state.modalThanks}
            toggle={this.toggleModalThanks}
            className={this.props.className}
          >
            <ModalHeader style={{ padding: "0.5rem 0.5rem" }}>
              <span style={{ fontWeight: "bold", paddingLeft: "11px" }}>
                Congratulations , you have just created your account
              </span>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "35px",
                  paddingRight: "35px"
                }}
              >
                <span>
                  {" "}
                  Thank you for completing the registration process, we've just
                  sent you an email with a link.
                  <br></br>
                  Please check your mail box and click on the link to activate
                  your account. You'll be directed to the login Page
                </span>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ height: "35px", width: "98px", padding: "0px", borderRadius :"6px"}}
                color="primary"
                onClick={this.goToLogin}
              >
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
            </div>
        );
    }
}

export default Register;
