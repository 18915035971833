import React from 'react';
import {

    Row, Col,
} from 'reactstrap';

import ClassroomCard from './../../../components/cards/ClassroomCard.jsx'
import { Link } from 'react-router-dom';
import API from './../../../helpers/api.jsx'
import {notify} from './../../../helpers/common.jsx'
import NotificationAlert from "react-notification-alert";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
var BASEDIR = process.env.REACT_APP_BASEDIR;

class MyClassrooms extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ownedRooms: [],
            limit: 8,
            count: 0,
            active: (this.props.match.params && Number(this.props.match.params.page)) || 1,
            userId: this.props.match.params && this.props.match.params.id,
            loading: true,
            profileId: localStorage.getItem('WeCodeLand_user')
        };


    }

    componentDidMount() {
        const access_token = localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        this.getRooms(this.state.active);

    }

    getRooms = async (key) => {
        this.setState({
            loading: true
        });
        const accessToken = localStorage.getItem('WeCodeLand_jwt');
        const {userId}= this.state;
        const limit =this.state.limit;
        const skip =((key - 1) * this.state.limit);
        try {

            const ownedRooms = await API.get(`Profiles/hostRooms`, {
                params: { hostId: userId, limit: limit, skip: skip, access_token: accessToken },
              });
        
              if (ownedRooms.status === 200) {

                this.setState({
                    ownedRooms: ownedRooms.data.ownedClassrooms,
                    active: key
                });
                const pageCount = Math.ceil(ownedRooms.data.count / this.state.limit);
                this.setState({ count: pageCount });
                
            }
            this.setState({
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    handlePageClick = (data) => {
        const selectedPage = data.selected;
        this.getRooms(selectedPage + 1);
        window.history.pushState(null, null, BASEDIR + `/ownedClassrooms/${this.state.userId}/${selectedPage + 1}`);
        window.scrollTo(0, 0)
      }

      buttonCLickHandler = async (room) => {
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        try {
    
          let result = await API.post(`Rooms/displayMask`, null, { params: {
            access_token: accessToken,
            value: room && room.status && room.status.toUpperCase() === "NEW"? true : false,
            roomId: room && room.id
          }});
          if (result.status === 200) {
            console.log("changed SUCCESSFULLY");
            notify(
              { type: "success", message: room && room.status && room.status.toUpperCase() === "NEW"? "Room published" : "Room placed in drafts" },
              this
            );
            this.getRooms(this.state.active);
          } else {
            throw result.status;
          }
        } catch (error) {
          const {
            response: {
              data: {
                error: { message }
              }
            } = {}
          } = error;
           this.setState({
            loading: false
          });
          notify({ type: "danger", message: "something went wrong" }, this);
        } finally {
            
        }
      };
   
    
    render(){
        const {ownedRooms} = this.state;
        return (
            <div>
                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                    <div className="page-title">
                        <div className="float-left">
                            <h1 className="title">Owned Classrooms</h1>
                        </div>
                    </div>


                            
           

                    <div className="col-xl-12">
                            <div className="content-body">    


                            <div className="row">
                                    <div className="col-12 cards-list-container">
                                {
                                    ownedRooms.map((room, key) => {
                                        return (
                                            <ClassroomCard key={room.id} room={room} index={key} buttonCLickHandler={this.buttonCLickHandler}/>
                                        )
                                    })
                                }

                                    </div>
                                </div>
                            </div></div>



                            {this.state.count>1?
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.count}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.active-1}
                                    forcePage={this.state.active-1}
                                />
                                :null}
                                
                        </Col>

                    </Row>
                </div>
            </div>
        );
    }
}

export default MyClassrooms;
