import React from "react";
import empty_pic_classroom from "assets/img/empty_pic_classroom.png";
import ReadMoreAndLess from "react-read-more-less";

const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;

class TeacherCard extends React.Component {

  render() {
    let { teacher, index, isPublic } = this.props;
    return (
      <div id="teacherCard" className={isPublic? "" :"col-md-4 col-lg-3 pl-2 pr-2"} key={index}>
        <div className="team-member ">
          <div className="team-img">
            <img
              className="img-fluid"
              src={
                teacher &&
                teacher.image &&
                teacher.image.name
                  ? `${SERVERDIR}Containers/img/download/${
                    teacher.image && teacher.image.name
                    }`
                  : empty_pic_classroom
              }
              alt=""
            />
          </div>
          <div className="team-info">
            <div className="d-flex">
                <h3 className="hoverable-text" onClick={() => window.open(`${BASEDIR}/profile/${teacher && teacher.id}`, "_blank")}>
                  {teacher && teacher.fullName}
                </h3>
                <div className="rating-container">
                    <i className="fa fa-star"></i>
                    <span>&nbsp;{teacher && teacher.rating}</span>
                </div>
            </div>
            <h5>{teacher && teacher.profession}</h5>
            <div className="card-description">
              <ReadMoreAndLess
                ref={this.ReadMore}
                className="read-more-content"
                charLimit={100}
                readMoreText="view more"
                readLessText="view less"
              >
                {teacher && teacher.description + " "}
              </ReadMoreAndLess>
            </div>
            <div className="float-right social-media-icons">
              <ul className="list-inline list-unstyled">
                <li className="list-inline-item">
                  <a
                    href={(teacher && teacher.email && teacher.email.length>0 && "mailto:" + teacher.email) || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fa fa-envelope-square"
                      style={{
                        color: 
                          teacher && teacher.email && teacher.email.length
                            ? "#D43272"
                            : "#606060",
                      }}
                    ></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={/* `//${
                      profile.linkedinUrl != null &&
                      profile.linkedinUrl.replace(/(^\w+:|^)\/\//, "")
                    }` */ '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fa fa-linkedin-square "
                      style={{
                        color: "D43272"
                          /* profile.linkedinUrl && profile.linkedinUrl.length
                            ? "#009688"
                            : "#606060", */
                      }}
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TeacherCard;
