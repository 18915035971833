import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./../../helpers/common.jsx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter , Input} from "reactstrap";

var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;

class ModalProfilesList extends React.Component {
    render() {
      return (
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={this.props.className}
          id="modal-profiles-list"
        >
          <ModalHeader
            toggle={this.props.close}
          >
            <span>{this.props.title}</span>
          </ModalHeader>
  
          <ModalBody>
            <div className="blog-content">
              <div className="col-xl-12">
                <section
                  className="box profile-page"
                >
                  <div className="content-body">
                    {this.props.profiles.map((profile, key) => {
                      return (
                        <div key={key}>
                          <div className="user-inline-img">
                            <img
                              src={
                                profile.image &&
                                profile.image.name
                                  ? `${SERVERDIR}Containers/img/download/${profile.image.name}`
                                  : `${IMGDIR}/images/profile/profile-general.jpg`
                              }
                              alt="user avatar"
                              className="avatar-image img-inline"
                            />
                            <div style={{alignSelf: "center"}}>
                              <span
                                className="uprofile-list span a">
                                {profile && profile.fullName}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
          color="primary save-button" onClick={this.props.close}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
  ModalProfilesList.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    close: PropTypes.func,
    title: PropTypes.string,
    profileAttribute: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object)
  };
  export default props => (
    <AppContext.Consumer>
      {Helper => <ModalProfilesList {...props} helper={Helper} />}
    </AppContext.Consumer>
  );