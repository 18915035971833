import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./../../helpers/common.jsx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


class ModalConfirmation extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          <h3>{this.props.body}</h3>
        </ModalBody>
        <ModalFooter>
          <Button
            className="save-button"
            color="primary"
            onClick={this.props.confirm}
            disabled={this.props.disabledButton}
          >
            {this.props.confirmText? this.props.confirmText:"Confirm"}
          </Button>{" "}
          <Button className="save-button" color="secondary" onClick={this.props.cancel}>
            {this.props.cancelText? this.props.cancelText:"Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
ModalConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  disabledButton: PropTypes.bool,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
};
export default props => (
  <AppContext.Consumer>
    {Helper => <ModalConfirmation {...props} helper={Helper} />}
  </AppContext.Consumer>
);
