import React from "react";

export const defaultValues = {
  appName: "WeCodeLand",
  baseUrl: "http://41.231.122.14/server/api/",
  token: localStorage.getItem("WeCodeLand_jwt") || "",
  id: localStorage.getItem("WeCodeLand_user") || "",
  user: localStorage.getItem("WeCodeLand_profile") || ""
};

export const AppContext = React.createContext(defaultValues);
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export const isAuthorized = authorizedRoles => {
  const jwt = localStorage.getItem("WeCodeLand_jwt");
  const profile = localStorage.getItem("WeCodeLand_profile");
  let prof = JSON.parse(profile);

  return jwt && (authorizedRoles.find(role => role.toLowerCase() === "all") ||  prof.roles.some(item =>  authorizedRoles.includes(item.name)));
};

export const handleLogout = param => {
  localStorage.removeItem("WeCodeLand_jwt");
  localStorage.removeItem("WeCodeLand_user");
  localStorage.removeItem("WeCodeLand_profile");
};

export const notify = (config, component) => {
  console.log("config: ", config);
  // var color = config.color;
  var type = config.type;
  var options = {};
  options = {
    place: config.place || "tc",
    message: (
      <div>
        <div>{config.message}</div>
      </div>
    ),
    type: type,
    icon: config.icon || "",
    autoDismiss: 2
  };
  component.refs.notificationAlert.notificationAlert(options);
};

export const tunGovList = [
  "Ariana",
  "Kairouan",
  "Medenine",
  "Gafsa",
  "Mahdia",
  "Gabes",
  "Nabeul",
  "Ben Arous",
  "Monastir",
  "Kef",
  "Bizerte",
  "Tunis",
  "Beja",
  "Sfax",
  "Sousse",
  "Jendouba",
  "Zaghouan",
  "Kasserine",
  "Kebili",
  "Sidi Bouzid",
  "Siliana",
  "Tataouine",
  "Tozeur"
];
export const libyaGovBlackList =[
  "BU",
  "JA",
  "JG",
  "JA",
  "JU",
  "FK",
  "MJ",
  "MB",
  "WA",
  "NQ",
  "ZA",
  "BA",
  "DR",
  "GH",
  "MI",
  "MQ",
  "NL",
  "SB",
  "SR",
  "TB",
  "WD",
  "WS",
]
export const libyaGovList =[
  "Ghat",
  "Jabal al Gharbi",
  "Butnan",
  "Derna",
  "Kufra",
  "Jufra",
  "Murqub", 
  "Tripoli",
  "Sabha",
  "Al Wahat",
  "Benghazi",
  "Marj",
  "Jabal al Akhdar",
  "Wadi al Hayaa",
  "Jafara",
  "Wadi al Shatii",
  "Nuqat al Khams",
  "Misrata",
  "Nalut",
  "Murzuq",
  "Zawiya",
  "Sirte",
]
