import React from 'react';
import {
  Row, Col, Input,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import API from './../../../helpers/api.jsx'
import { notify , tunGovList, libyaGovBlackList, libyaGovList } from './../../../helpers/common.jsx';
import profilePhoto from './../../../assets/img/profile-general.jpg';
import NotificationAlert from 'react-notification-alert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TagsInput from './../../../components/Tags/TagsInput.jsx';
import axios from 'axios'
import moment from 'moment'
import InputMask from 'react-input-mask';
import Loader from 'react-loader-spinner';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const countryList = ["Tunisia"];
const genderList = ["Male", "Female"];
const govList = tunGovList;

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params && this.props.match.params.id,
            userRole: [],
            fullName: '',
            lastName: '',
            birthDate: '',
            email: '',
            password: '',
            phoneNumber: '',
            gov: 'Ariana',
            gender: 'Male',
            isTeacher: false,
            educationChanged: false,
            interestsChanged: false,
            certificationsChanged: false,
            subjectsChanged: false,
            competenciesChanged: false,
            experienceChanged: false,
            expertisesChanged: false,
            modalConfirmAction: false,
            loading: true,
            profilePhoto: {
                preview: profilePhoto,
                data: {},
                container: {}
            },
            facebookUrl: '',
            linkedinUrl: '',
            description: '',
            profile: {},
            photoChanged: false,
            skills: [],
            education: [],
            interests: [],
            certifications: [],
            subjects: [],
            competencies: [],
            experience: [],
            expertises: [],
            inputKey: Date.now(),
            country: '',
        };

    }

    selectCountry =(val)=> {
      this.setState({ country: val });
    }
   
    selectRegion =(val)=> {
      this.setState({ gov: val });
    }

    async componentWillMount() {

        const access_token = await localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        const id = this.state.id;
        if (id !== localStorage.getItem('WeCodeLand_user')){
          this.props.history.push({ pathname: BASEDIR + "/dashboard" })
        }
        else {await this.getProfileDetails(id);}
    }

    getProfileDetails = async (userId) => {
        this.setState({
          loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        try {
          const profile = await API.get(`Profiles/getUser`, {
            params: { userId: userId, access_token: accessToken }
          });
    
          if (profile.status === 200) {
            this.setState({
              profile: profile.data.profileInfos,
              userRole: profile.data.role,
              isTeacher: profile.data.role.includes("teacher")
            },()=>this.populateForm(profile.data.profileInfos));
          }
          this.setState({
            loading: false,
          });
        } catch (error) {
          this.setState({
            loading: false,
          });
        }
      };


    componentWillUnmount() {
        // this.populateForm();
        // policy.removeEventListener('ps-y-reach-end');
        // policy.destroy();
    }


    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    populateForm = (prop) => {
        prop = prop ? prop : {};

        this.setState({ birthDate: moment(prop.birthday).format("YYYY-MM-DD") });
        this.setState({ fullName: prop.fullName || '' });
        this.setState({ phoneNumber: prop.phone || '' });
        if(prop.governorate && prop.governorate.length>0 && prop.governorate !== "Other") this.setState({ gov: prop.governorate, country : tunGovList.includes(prop.governorate) ? "Tunisia" : "Libya"})
        if(prop.gender && prop.gender.length>0) this.setState({ gender: prop.gender})
        this.setState({ email: prop.email || '' });
        this.setState({ description: prop.description || '' });
        if (Object.keys(prop.image).length > 0) {this.setState({ profilePhoto: prop.image })}
        this.setState({ facebookUrl: prop.facebookUrl || '' });
        this.setState({ linkedinUrl: prop.linkedinUrl || '' });
        if (prop.education && prop.education.length > 0) this.setState({ education: prop.education });
        if (prop.certifications && prop.certifications.length > 0 && this.state.isTeacher) this.setState({ certifications: prop.certifications });
        if (prop.subjects && prop.subjects.length > 0 && this.state.isTeacher) this.setState({ subjects: prop.subjects });
        if (prop.experience && prop.experience.length > 0 && this.state.isTeacher) this.setState({ experience: prop.experience });
        if (prop.competencies && prop.competencies.length > 0 && this.state.isTeacher) this.setState({ competencies: prop.competencies });
        if (prop.interests && prop.interests.length > 0) this.setState({ interests: prop.interests.map(a => a.name) });
        if (prop.expertises && prop.expertises.length > 0 && this.state.isTeacher) this.setState({ expertises: prop.expertises.map(a => a.name) });

        // this.setState({ isLoading: false });


    }
    selectedEducation=(education)=>{
      this.setState({education:education,educationChanged : true})
      
    }
    selectedInterests=(interests)=>{
      this.setState({interests:interests,interestsChanged : true})
      
    }

    selectedCertifications=(certifications)=>{
      this.setState({certifications:certifications,certificationsChanged : true})
      
    }
    selectedSubjects=(subjects)=>{
      this.setState({subjects:subjects,subjectsChanged : true})
      
    }
    selectedCompetencies=(competencies)=>{
      this.setState({competencies:competencies,competenciesChanged : true})
      
    }
    selectedExperiences=(experience)=>{
      this.setState({experience:experience,experienceChanged : true})
      
    }
    selectedExpertises=(expertises)=>{
      this.setState({expertises:expertises,expertisesChanged : true})
      
    }


    updateInputValue = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    updatePhoneValue = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //console.log("validity :",target.checkValidity());
        this.setState({
            [name]: String(value).replace(/\s/g, ''),
        });
    }

    updateGovValue = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ gov: value })
    }

    photoChangeHandler = async (e) => {
        const file = e.target.files[0];
        const { profilePhoto } = this.state;
        let uploadedPhoto;
        if (e.target.name === "profilePhoto") {
            profilePhoto.preview = URL.createObjectURL(file);
            profilePhoto.data = file;
            uploadedPhoto = {
                file: profilePhoto,
                name: "profilePhoto"
            };
        }



        const fileData = new FormData();
        fileData.append('file', uploadedPhoto.file.data);

        try {let imageProfile = await axios.post(`${ SERVERDIR }Containers/img/upload`, fileData);
        if (imageProfile.status === 200) {
            // notify({ type: 'success', message: "success" }, this);
            uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
            await this.setState({
                [uploadedPhoto.name]: uploadedPhoto.file,
                photoChanged: true
            });
        } else {
          console.log(imageProfile.status);
            throw imageProfile.status;
            
        }} catch (error) {
        const {
          response: {
            data: {
              error: { message }
            }
          } = {}
        } = error;
        if (message.includes("contentType"))
        notify({ type: "danger", message: "Please upload an image" }, this);
        this.setState({
          inputKey : Date.now()
        })
      } finally {
      }
    }


    cancelUpdate = (event) => {
        const id= this.state.id;
        this.props.history.push({ pathname: BASEDIR + "/profile/"+ id })
    }
    toggleModalConfirmAction = (e) => {
      e.preventDefault();
      this.setState({
        modalConfirmAction: !this.state.modalConfirmAction
      });
    }


    handleSubmit = async (event) => {
        const id= this.state.id;
        const accessToken= localStorage.getItem('WeCodeLand_jwt');
        event.preventDefault();
        this.setState({
            loading: true
        });
        const request = {
            fullName: this.state.fullName,
            birthday: this.state.birthDate,
            email: this.state.email,
            phone: this.state.phoneNumber,
            governorate: this.state.gov,
            country: this.state.country,
            gender: this.state.gender,
            facebookUrl: this.state.facebookUrl,
            linkedinUrl: this.state.linkedinUrl,
            description: this.state.description

        };

        if (this.state.photoChanged && this.state.profilePhoto && this.state.profilePhoto.container && Object.keys(this.state.profilePhoto.container).length>0) {
            request.image = this.state.profilePhoto.container
        }
        if (this.state.interestsChanged) {
            request.interests = this.state.interests
        }
        if (this.state.educationChanged) {
            request.education = this.state.education
        }
        if (this.state.certificationsChanged && this.state.isTeacher) {
            request.certifications = this.state.certifications
        }
        if (this.state.subjectsChanged && this.state.isTeacher) {
            request.subjects = this.state.subjects
        }
        if (this.state.competenciesChanged && this.state.isTeacher) {
            request.competencies = this.state.competencies
        }
        if (this.state.experienceChanged && this.state.isTeacher) {
            request.experience = this.state.experience
        }
        if (this.state.expertisesChanged && this.state.isTeacher) {
            request.expertises = this.state.expertises
        }


        try {

            let result = await API.post(`Profiles/updateProfile/${id}`,request, {
              params: { access_token: accessToken }
            });
            var state = this.state;

            if (result.status === 200) {
                const newProfile=  result.data;
                newProfile.roles = JSON.parse(localStorage.getItem('WeCodeLand_profile')).roles;
                console.log("result: ", result.data);
                this.setState({
                    loading: false
                });
                localStorage.setItem('WeCodeLand_profile', JSON.stringify(newProfile));
                this.props.history.push({ pathname: BASEDIR + "/profile/"+ id, state: {headerUpdated: true, sidebarUpdated: true } });
                
                
            } else {
                throw result.status;
            }

        } catch (error) {
            const {
                response: {
                    data: {
                        error: { message }
                    }
                } = {}
            } = error;
            this.setState({
                ...state
            });
            this.setState({
                loading: false
            });
            notify({ type: 'danger', message: message }, this);
            this.setState({modalConfirmAction: false});

        }


    }

    render() {
        const {country,gov, photoChanged ,profile,education,interests,certifications,subjects,experience,competencies,expertises,isTeacher} = this.state;

        /* if (this.state.loading) return <div className="content-fluid h-100 d-flex justify-content-center align-items-center" style={{ marginTop: '10px' }}>
            <Loader
                type="BallTriangle"
                color="#00BFA5"
                height={80}
                width={80}
                timeout={900000}
            />
        </div> */



        return (
            <div>
                <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
              <Row>
                <Col xs={12} md={12}>
    
                  <div className="page-title">
                    <div className="float-left">
                      <h1 className="title" style={{ textTransform: "none" }}>Edit Profile</h1>
                    </div>
                  </div>
    
    
                  <div className="row margin-0">
                    <div className="col-12">
                      <section className="box ">
                      <header className="panel_header">
                      </header>
                        <div className="content-body">
                        <form id="myForm" onSubmit={this.handleSubmit}>
                        <div className="row">
                          <div className="col-md-4" >
                          <img alt="" src={(profile && profile.image && Object.keys(profile.image).length > 0 &&  !photoChanged) ? `${ SERVERDIR }containers/${ profile.image.container }/download/${ profile.image.name }` : this.state.profilePhoto.preview} className="img-fluid rounded-image" style={{ "width": "120px" }} />
                            <div className="profileimg-input">
                              <Input type="file" name="profilePhoto" key={this.state.inputKey} id="exampleFile" onChange={this.photoChangeHandler} style={{ marginTop: '10px' }} />
                            </div>
                          </div>
                        
                          <div className="col-md-8">

                            
                              <div className="form-group">
                                <label htmlFor="inputfullName">Full Name</label>
                                <input type="text" name="fullName" value={this.state.fullName} onChange={this.updateInputValue} pattern="[a-zA-Z ]+" minLength="2" maxLength="20" className="form-control" id="inputfullName" placeholder="Full Name" required />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputDescription">Description</label>
                                <Input type="textarea" rows="5" maxLength="350" cols="50" className="form-control custom-fixed-textarea" name="description" value={this.state.description} onChange={this.updateInputValue} id="inputDescription" placeholder="Tell us more about you"/>
                              </div>
                          </div>
                        
                      </div>
                          
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputEmail4" >Email</label>
                                <input type="email" name="email" value={this.state.email} onChange={this.updateInputValue} className="form-control" id="inputEmail4" placeholder="Email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$" required />
                            </div>
                            <div className="form-group col-md-4">
                                    <label htmlFor="imputType">Gender</label>
                                          <select id="imputType" name="gender" className="form-control" onChange={this.updateInputValue} value={this.state.gender}>
                                            {
                                              genderList.map((data, key) => {
                                                return (
                                                  <option value={data} key={key}>
                                                    {data}
                                                  </option>
                                                )
                                              })
                                            }
                                          </select>
                                    </div>
                                    <div className="form-group col-md-4">
                                                            <label htmlFor="inputDate" >Birth Date</label>
                                                            <input type="date" name="birthDate" value={this.state.birthDate} onChange={this.updateInputValue} className="form-control" id="inputBirthDate" placeholder="Birth Date" />
                                                        </div>
                            </div>
                            <div className="row">
                            <div className="form-group col-md-4">
                                    <label htmlFor="imputType">Country</label>
                                          {/* <select disabled id="imputType" name="country" className="form-control" onChange={this.updateInputValue} value={this.state.country}>
                                            {
                                              countryList.map((data, key) => {
                                                return (
                                                  <option value={data} key={key}>
                                                    {data}
                                                  </option>
                                                )
                                              })
                                            }
                                          </select> */}
                                          <CountryDropdown
                                             className="form-control"
                                              value={country}
                                              whitelist={["TN","LY"]}
                                              onChange={(val) => this.selectCountry(val)} />
                                    </div>
                                    <div className="form-group col-md-4">
                                    <label htmlFor="imputType">Governorate</label>
                                          {/* <select id="imputType" name="gov" className="form-control" onChange={this.updateInputValue} value={this.state.gov}>
                                            {
                                              govList.map((data, key) => {
                                                return (
                                                  <option value={data} key={key}>
                                                    {data}
                                                  </option>
                                                )
                                              })
                                            }
                                          </select> */}
                                          {console.log(CountryRegionData)}
                                          <div>
                                            <RegionDropdown
                                             className="form-control"
                                              country={country}
                                              value={gov}
                                              onChange={(val) => this.selectRegion(val)}
                                              blacklist={{LY:libyaGovBlackList}}
                                              customOptions={country === "Libya" ? libyaGovList : country === "Tunisia" ? tunGovList : []} />
                                          </div>
                                    </div>
                                    <div className="form-group col-md-4">
                                                            <label >Phone Number</label>
                                                            <InputMask className="form-control" pattern='^[0-9]{2}[ ][0-9]{3}[ ][0-9]{3}$' mask="99 999 999" maskChar={null} name="phoneNumber" value={this.state.phoneNumber} onChange={this.updatePhoneValue} id="inputPhoneNumber" placeholder="Phone Number"/>
                                                        </div>
                            </div>
                            <div className="row">
                            <div className="form-group col-md-6">
                                                            <label htmlFor="inputTwitterUrl" >Facebook Url</label>
                                                            <input type="text" name="facebookUrl" value={this.state.facebookUrl}
                                                                onChange={this.updateInputValue}
                                                                minLength="2"
                                                                className="form-control"
                                                                id="inputFacebookUrl"
                                                                placeholder="www.facebook.com/userid"
                                                                pattern="(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)" />

                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputLinkedinUrl" >Linkedin Url</label>
                                                            <input type="text"
                                                                name="linkedinUrl"
                                                                value={this.state.linkedinUrl}
                                                                onChange={this.updateInputValue}
                                                                minLength="2"
                                                                // maxLength="30"
                                                                className="form-control"
                                                                id="inputLinkedinUrl"
                                                                placeholder="www.linkedin.com/userid"
                                                                pattern="((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$"
                                                            />
                                                        </div>
                            </div>
                            <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="imputEducation">Education</label>
                                <TagsInput key={education.length} placeholder="Enter an education and press the + button to add it" selectedTags={this.selectedEducation}  tags={education}/>
                          </div>
                          <div className="form-group col-md-6">
                                <label htmlFor="imputInterest">Interests</label>
                                <TagsInput key={interests.length} placeholder="Enter an interest and press the + button to add it" selectedTags={this.selectedInterests}  tags={interests}/>
                          </div>
                          {isTeacher?
                            <div className="d-contents">
                              <div className="form-group col-md-6">
                                  <label htmlFor="imputCertifications">Certifications</label>
                                  <TagsInput key={certifications.length} placeholder="Enter a certification and press the + button to add it" selectedTags={this.selectedCertifications}  tags={certifications}/>
                            </div>
                            <div className="form-group col-md-6">
                                  <label htmlFor="imputSubjects">Subjects</label>
                                  <TagsInput key={subjects.length} placeholder="Enter a subject and press the + button to add it" selectedTags={this.selectedSubjects}  tags={subjects}/>
                            </div>
                            <div className="form-group col-md-6">
                                  <label htmlFor="imputCompetencies">Competencies</label>
                                  <TagsInput key={competencies.length} placeholder="Enter a competence and press the + button to add it" selectedTags={this.selectedCompetencies}  tags={competencies}/>
                            </div>
                            <div className="form-group col-md-6">
                                  <label htmlFor="imputExperiences">Experiences</label>
                                  <TagsInput key={experience.length} placeholder="Enter an experience and press the + button to add it" selectedTags={this.selectedExperiences}  tags={experience}/>
                            </div>
                            <div className="form-group col-md-6">
                                  <label htmlFor="imputExpertise">Expertises</label>
                                  <TagsInput key={expertises.length} placeholder="Enter an expertise and press the + button to add it" selectedTags={this.selectedExpertises}  tags={expertises}/>
                            </div>
                            </div>
                          :null}
                            </div>
                            <div className="row" style={{justifyContent: "center"}}>
                                  <button type="button" onClick={this.cancelUpdate} className="btn btn-primary" style={{ borderRadius: "7px", backgroundColor: "#444444", BoxShadow: "0px 0px 12px" , width:"40%"}}>Cancel</button>
                                  <button onClick={this.toggleModalConfirmAction} className="btn btn-primary" style={{ borderRadius: "7px", width:"40%"}}>Save changes</button>
                            </div>
                          </div>
                          </form>
                        </div>
                      </section></div>
                  </div>
                </Col>
    
              </Row>
            </div>
            <Modal isOpen={this.state.modalConfirmAction} toggle={this.toggleModalConfirmAction}>
              <ModalHeader>Profile modification</ModalHeader>
              <ModalBody>
                <h3>Are you sure you want to save these changes</h3>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="save-button"
                  color="primary"
                  /* onClick={this.state.confirm} */
                  form="myForm" key="submit" htmltype="submit"
                >
                  {"Confirm"}
                </Button>{" "}
                <Button className="save-button" color="secondary" onClick={this.toggleModalConfirmAction}>
                  {"Cancel"}
                </Button>
              </ModalFooter>
            </Modal>
            </div>


        );
    }
}
export default EditProfile;
