import React, {Component} from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import API from '../../helpers/api.jsx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare } from "@fortawesome/free-brands-svg-icons"
import {withRouter} from 'react-router-dom';
import { notify } from '../../helpers/common';
import NotificationAlert from 'react-notification-alert';
var BASEDIR = process.env.REACT_APP_BASEDIR;

class Facebook extends Component {

    state = {
        auth: false,
        name: '',
        email: '',
        userToken: ''
    };

    responseFacebook = response => {
        console.log(response);
        if(response.status !== 'unknown'){
        this.setState({
            auth: true,
            name: response.name,
            email: response.email,
            userToken: response.accessToken
        },()=>this.handleLogin());}
        else{
            notify({ type: 'danger', message: "Login error"}, this);
        }
        
    }

    handleLogin = async () => {
    
        const { userToken } = this.state;
        const email = this.state.email.toLowerCase();
    
          const result = await API.post(`Profiles/loginFacebook`, null, { params: {
            token: userToken
          }});
    
          if (result.status === 200) {
            const params = result.data;
            localStorage.setItem('WeCodeLand_jwt', params.id);
            localStorage.setItem('WeCodeLand_user', params.userId);
            localStorage.setItem('WeCodeLand_profile', JSON.stringify(params.profile));
            params.profile && params.profile.roles && params.profile.roles.some(item => item.name === "admin") ?
            this.props.history.push({ pathname: BASEDIR + "/admin/dashboard" })
            :
            this.props.history.push({ pathname: BASEDIR + "/dashboard" })
          }
      }

    componentClicked = () => {
        console.log('Facebook btn clicked');
    }

    render(){
        let facebookData;
            facebookData = (<FacebookLogin
                appId="209256993845332"
                fields="name,email"
                callback={this.responseFacebook}
                render={renderProps => (
                    <button onClick={renderProps.onClick} className="btn btn-primary btn-block btn_login fb_btn mt-2"> <FontAwesomeIcon className="btn_brand_ico"  icon={faFacebookSquare} /> Sign in with Facebook</button>
                  )} />);

        return (
            <>
                <div>
                <div className="notification-popup">
                <NotificationAlert ref="notificationAlert" />
                </div>
                    {facebookData}
                </div>
            </>
        );
    }
}
export default withRouter(Facebook);