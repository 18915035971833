import React from "react";
import { AppContext } from "./../../helpers/common.jsx";

class TagsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags,
      value: "",
    };
  }
  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  removeTags = (indexToRemove) => {
    const { tags } = this.state;
    this.setState({
      tags: [...tags.filter((_, index) => index !== indexToRemove)],
    });
    this.props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  addTags = (event) => {
    event.preventDefault();
    const { tags, value } = this.state;
    if (value !== "") {
      this.setState({ tags: [...tags, value] });
      this.props.selectedTags([...tags, value]);
      this.setState({ value: "" });
    }
  };
  render() {
    const { tags } = this.state;
    return (
      <div className="tags-input">
        <div className="row tags-input-field">
          <input
            className="col-md-10"
            type="text"
            name="value"
            value={this.state.value}
            onChange={this.updateInputValue}
            placeholder={this.props.placeholder}
          />
          <i
            className="fa fa-plus purple clickable col-md-1 centered p-2"
            onClick={(e) => this.addTags(e)}
          ></i>
        </div>
        <ul id="tags-list">
          {tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <i
                className="fa fa-times"
                onClick={() => this.removeTags(index)}
              ></i>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
export default (props) => (
  <AppContext.Consumer>
    {(Helper) => <TagsInput {...props} helper={Helper} />}
  </AppContext.Consumer>
);
