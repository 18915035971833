import React from 'react';
import {
  Row, Col, Input, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import API from './../../../helpers/api.jsx'
import { notify } from './../../../helpers/common.jsx';
import empty_pic_course from "./../../../assets/img/empty_pic_classroom.png";
import NotificationAlert from 'react-notification-alert';
import TagsInput from './../../../components/Tags/TagsInput.jsx';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga';

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const jobList = ["University teacher", "IT engineer", "Web designer", "Ui Ux designer", "Project manager", "Business manager", "Freelance", "Other"];

class UpgradeToTutor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params && this.props.match.params.id,
      description: '',
      phoneNumber: '',
      job: "",
      otherJob: "",
      file: {
        originalFileName: "",
        name: ""
      },
      isFileUploading: false,
      isOtherSelected: false,
      coursePhoto: {
        preview: empty_pic_course,
        data: {},
        container: {}
      },
      modalConfirmAction: false,
      certifications: [],
      subjects: [],
      competencies: [],
      experience: [],
      expertises: [],
      attachments: [],
      photoChanged: false,
      inputKey : Date.now()
    };
    this.notificationAlert = React.createRef();
    this.multiselectRef = React.createRef();
  }

  componentWillMount = async () => {
    ReactGA.initialize('UA-173264282-1');
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    if (this.state.id !== localStorage.getItem('WeCodeLand_user')) {
      this.props.history.push({ pathname: BASEDIR + "/dashboard" })
    }
    else { await this.getProfileDetails(this.state.id); }
  }

  getProfileDetails = async (userId) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const profile = await API.get(`Profiles/getUser`, {
        params: { userId: userId, access_token: accessToken }
      });

      if (profile.status === 200) {
        if (profile.data.profileInfos && profile.data.profileInfos.upgradeRequest && profile.data.profileInfos.upgradeRequest.length>0 && profile.data.profileInfos.upgradeRequest.filter(obj => { return obj.status === "Pending" }).length > 0) {
          this.props.history.push({ pathname: BASEDIR + "/dashboard" })
        }
        this.setState({
          profile: profile.data.profileInfos
        });
        if (profile.data.profileInfos && profile.data.profileInfos.phone > 0 ){
          this.setState({
            phoneNumber: profile.data.profileInfos.phone
          });}
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };


  selectedCertifications = (certifications) => {
    this.setState({ certifications: certifications })

  }
  selectedSubjects = (subjects) => {
    this.setState({ subjects: subjects })

  }
  selectedCompetencies = (competencies) => {
    this.setState({ competencies: competencies })

  }
  selectedExperiences = (experience) => {
    this.setState({ experience: experience })

  }
  selectedExpertises = (expertises) => {
    this.setState({ expertises: expertises })

  }

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value
    })
  }


  addCourse = (e) => {

    e.preventDefault();
    const accessToken = localStorage.getItem('WeCodeLand_jwt');

    const request = {
      data: {
        description: this.state.description,
        certifications: this.state.certifications,
        subjects: this.state.subjects,
        competencies: this.state.competencies,
        experience: this.state.experience,
        profession: (this.state.isOtherSelected && this.state.otherJob !== "") ? this.state.otherJob : this.state.job,
        /* file: this.state.file */
        attachments: this.state.attachments
      },
      profileId: localStorage.getItem('WeCodeLand_user'),
      newRole: "teacher",
      expertises: this.state.expertises
    };
    if (this.state.photoChanged && this.state.coursePhoto && this.state.coursePhoto.container && Object.keys(this.state.coursePhoto.container).length>0) {
      request.data.image = this.state.coursePhoto.container
    }
    if (this.state.phoneNumber !== ''){
      request.data.phone = this.state.phoneNumber
    }

    API
      .post(`upgradeRequests`, request, {
        params: { access_token: accessToken }
      })
      .then(res => {
        this.setState({
          loading: false
        })
        if (res.status === 200) {
          ReactGA.event({
            category: 'Upgrade Request',
            action: 'Requested upgrade to Tutor'
          })
          notify({ type: 'success', message: "success" }, this);
          this.props.history.push(BASEDIR + `/profile/${this.state.id}`);
        } else {
          this.setState({ modalConfirmAction: false });
          throw res.status;
        }
      })
      .catch(error => {
        // this.setState({
        //   loading: false
        // });
        console.log('error:', error);
        const {
          response: {
            data: {
              error: { message }
            }
          } = {}
        } = error;
        notify({ type: 'danger', message: message === "Authorization Required" ? "You are not able to add an upgrade request" : message }, this);
        this.setState({ modalConfirmAction: false });
      });
  }

  //** UPLOAD COURSE IMAGE */
  photoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { coursePhoto } = this.state;
    let uploadedPhoto;
    if (e.target.name === "coursePhoto") {
      coursePhoto.preview = URL.createObjectURL(file);
      coursePhoto.data = file;
      uploadedPhoto = {
        file: coursePhoto,
        name: "coursePhoto"
      };
    }

    // const file = this.state.coursePhoto
    const fileData = new FormData();
    fileData.append('file', uploadedPhoto.file.data);

    try {let imageProfile = await API.post(`${SERVERDIR}Containers/img/upload`, fileData);
    if (imageProfile.status === 200) {
      console.log("uploadedPhoto: ", imageProfile.data);
      // notify({ type: 'success', message: "success" }, this);
      uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
      await this.setState({
        [uploadedPhoto.name]: uploadedPhoto.file,
        photoChanged: true
      });
    } else {
      throw imageProfile.status;
    }} catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      if (message.includes("contentType"))
      notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey : Date.now()
      })
    } finally {
    }
  }

  resetCourse = () => {
    this.setState({
      description: '',
      coursePhoto: {
        preview: empty_pic_course,
        data: {},
        container: {}
      },
      /* file: {
        originalFileName: "",
        name: ""
      }, */
      attachments: [],
      job: "",
      phoneNumber: '',
      certifications: [],
      subjects: [],
      competencies: [],
      experience: [],
      expertises: [],
    })
  }

  /* handelOnUploadFile = async (course) => {
    course.preventDefault();
    const { file } = this.state;
    const originalFileName = course.currentTarget.files[0];
    this.setState({
      isFileUploading: true
    });

    const formData = new FormData();
    formData.append('file', course.target.files[0]);


    let fileUploaded = await API.post(`${SERVERDIR}Container2s/file/upload`, formData)
    if (fileUploaded.status === 200) {
      this.setState({ isFileUploading: false });
      file.originalFileName = originalFileName.name;
      file.name = fileUploaded.data.result.files.file[0].name;
      this.setState({
        file: file,
        isFileUploading: false
      })
      notify({ type: 'success', message: "file Uploaded" }, this);
    } else {
      throw fileUploaded.status;
    }
  } */
  handelOnUploadFile = async (e) => {
    e.preventDefault();
    const files = e.currentTarget.files
    this.setState({
      isFileUploading: true
    });

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i])
    }


    let filesUploaded = await API.post(`${SERVERDIR}Container2s/file/upload`, formData)
    if (filesUploaded.status === 200) {
      // flatten array
      const albumfiles = filesUploaded.data.result.files;
      const album = Object.keys(albumfiles).reduce(function (r, k) {
        return r.concat(albumfiles[k].flat());
      }, []);

      this.setState({
        attachments: album,
        isFileUploading: false
      })
      notify({ type: 'success', message: "files Uploaded" }, this);
    } else {
      throw filesUploaded.status;
    }
  }

  toggleModalConfirmAction = (e) => {
    e.preventDefault();
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction
    });
  }
  updatePhoneValue = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: String(value).replace(/\s/g, ''),
    });
  }
  updateJobValue = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      job: value,
      otherJob: ""
    });
    if (value === "Other") {
      this.setState({
        isOtherSelected: true
      })
    }
    else {
      this.setState({
        isOtherSelected: false
      })
    }
  }

  handleSubmitButtonClick=(event) =>{
    if (this.myForm.checkValidity() === false) {
        return null;
    }
    else
        this.toggleModalConfirmAction(event);

    this.setState({ validated: true });
}


  render() {
    const { photoChanged, profile, isOtherSelected, isFileUploading, certifications, subjects, experience, competencies, expertises } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>

              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Upgrade to tutor</h1>
                </div>
              </div>


              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                    </header>
                    <div className="content-body">
                      <form id="myForm" onSubmit={this.addCourse} method="post" ref={form => this.myForm = form}>
                        <div className="row">
                          <div className="mintit-upload-image-container col-md-4">
                            <img className="mintit-upload-image" alt="" src={(profile && profile.image && Object.keys(profile.image).length > 0 && !photoChanged) ? `${SERVERDIR}containers/${profile.image.container}/download/${profile.image.name}` : this.state.coursePhoto.preview} />
                            <div className="mintit-upload-image-overlay">
                              <p><input type="file" name="coursePhoto" key={this.state.inputKey} id="coursePhoto" onChange={this.photoChangeHandler} style={{ marginTop: '10px' }} /></p>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="form-group">
                              <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Description</label>
                              <p>As a tutor we need to know more about you. Please provide us with a long description about yourself</p>
                              <Input type="textarea" rows="6" maxLength="350" cols="50" className="form-control custom-fixed-textarea" name="description" value={this.state.description} onChange={this.updateInputValue} id="inputDescription" placeholder="Tell us about the yourself" required />
                            </div>
                          </div>

                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="imputType" style={{ fontWeight: "bold" }}>Job Title</label>
                              <select id="imputType" name="job" className="form-control" style={{ borderRadius: "4px" }} onChange={this.updateJobValue} value={this.state.job} required>
                                <option value="" disabled hidden>No Option Selected</option>
                                {
                                  jobList.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    )
                                  })
                                }
                              </select>
                              {isOtherSelected ? <input type="text" name="otherJob" value={this.state.otherJob} onChange={this.updateInputValue} pattern="[a-zA-Z ]+" minLength="2" maxLength="20" className="form-control" id="inputOtherJob" placeholder="Other job" required /> : null}
                            </div>
                            <div className="form-group col-md-6">
                              <label >Phone Number</label>
                              <InputMask className="form-control" pattern='^[0-9]{2}[ ][0-9]{3}[ ][0-9]{3}$' mask="99 999 999" maskChar={null} name="phoneNumber" value={this.state.phoneNumber} onChange={this.updatePhoneValue} id="inputPhoneNumber" placeholder="Phone Number" required />
                            </div>

                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="imputCompetencies">Competencies</label>
                              <TagsInput key={competencies.length} placeholder="Enter a competence and press the + button to add it" selectedTags={this.selectedCompetencies} tags={competencies} />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="imputExperiences">Experiences</label>
                              <TagsInput key={experience.length} placeholder="Enter an experience and press the + button to add it" selectedTags={this.selectedExperiences} tags={experience} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="imputExpertise">Expertises</label>
                              <TagsInput key={expertises.length} placeholder="Enter an expertise and press the + button to add it" selectedTags={this.selectedExpertises} tags={expertises} />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="imputSubjects">Subjects</label>
                              <TagsInput key={subjects.length} placeholder="Enter a subject and press the + button to add it" selectedTags={this.selectedSubjects} tags={subjects} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="imputCertifications">Certifications</label>
                              <TagsInput key={certifications.length} placeholder="Enter a certification and press the + button to add it" selectedTags={this.selectedCertifications} tags={certifications} />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputFile" style={{ fontWeight: "bold" }}>Attachment (optional)</label>
                              <Input type="file" multiple name="file" id="inputFile" onChange={this.handelOnUploadFile} style={{ marginTop: '10px' }} />
                            </div>
                          </div>
                          <div className="row" style={{ justifyContent: "center" }}>
                            <button type="button" onClick={() => this.props.history.push(BASEDIR + `/profile/${this.state.id}`)} className="btn btn-primary" style={{ backgroundColor: "#444444", borderRadius: "7px", BoxShadow: "0px 0px 12px", width: "40%" }}>Cancel</button>
                            <button disabled={isFileUploading} onClick={this.handleSubmitButtonClick} className="btn btn-primary" style={{ width: "40%", borderRadius: "7px" }}>Save</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section></div>
              </div>
            </Col>

          </Row>
        </div>
        <Modal isOpen={this.state.modalConfirmAction} toggle={this.toggleModalConfirmAction}>
          <ModalHeader>Adding a new course</ModalHeader>
          <ModalBody>
            <h3>Are you sure you want to save these changes</h3>
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              form="myForm" key="submit" htmltype="submit"
            >
              {"Confirm"}
            </Button>{" "}
            <Button className="save-button" color="secondary" onClick={this.toggleModalConfirmAction}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UpgradeToTutor;
