import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./../../helpers/common.jsx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter , Input} from "reactstrap";
import API from '../../helpers/api.jsx';
import { notify } from '../../helpers/common.jsx';
import NotificationAlert from 'react-notification-alert';
import InputMask from 'react-input-mask';
import axios from "axios"
import { withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
const md5 = require('md5')
const sha1 = require('sha1')
const BASEDIR = process.env.REACT_APP_BASEDIR;
const SERVERDIR = process.env.REACT_APP_API;

class ModalPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionValue: "",
      coupon: "",
      errorMessage: "",
      isLoading: false,
      completePayment: false,
      sucessType: "",
      missingAmount: 0,
      EMAIL: "",
      CustFirstName: "",
      CustLastName: "",
      CustTel: "",
      CustAddress: "",
      CustFirstNameOffline: "",
      CustLastNameOffline: "",
      CustAddressOffline: "",
      CustTelOffline: "",
      signature: "",
      NumSite: "MAR680",
      password: "mv_naK09",
      orderId: "",
      amountToPay: "",
    };
  }
  componentDidMount = () => {
    const profile = JSON.parse(localStorage.getItem("WeCodeLand_profile"));
    this.setState({ EMAIL: profile.email });
    if (
      profile &&
      profile.phone &&
      profile.phone.toString() &&
      profile.phone.toString().length > 0
    ) {
      this.setState({ CustTel: profile.phone, CustTelOffline: profile.phone });
    }
    this.calculateOrderId();
  };
  calculateSignature = () => {
    let amount =
      this.state.missingAmount > 0
        ? this.state.missingAmount.toString()
        : this.props.sessionToSubscribeTo &&
          this.props.sessionToSubscribeTo.price &&
          this.props.sessionToSubscribeTo.price.toString();
    let devise = "TND";
    let signature = sha1(
      `${this.state.NumSite}${this.state.password}${this.state.orderId}${amount}${devise}`
    );
    this.setState({ signature: signature });
    this.setState({ amountToPay: amount });
  };
  calculateOrderId = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    let dateStr = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let arr = dateStr.split("/"); // [ '10', '05', '19' ]
    let d = arr[0];
    let m = arr[1];
    let y = arr[2];
    let timeStr = date.toLocaleTimeString("en-GB", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }); //
    let arr2 = timeStr.split(":"); // 15:30:20
    let H = arr2[0];
    let i = arr2[1];
    let s = arr2[2];
    let ymdHms = y + d + m + H + i + s;
    this.setState({ orderId: ymdHms }, () => {
      this.calculateSignature();
    });
  };
  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  updatePhoneValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("validity :",target.checkValidity());
    this.setState({
      [name]: String(value).replace(/\s/g, ""),
    });
  };
  updateCoupounInputValue = (e) => {
    const target = e.target;
    this.setState({
      coupon: target.value,
      errorMessage: "",
    });
  };

  submit = async (e) => {
    e.preventDefault();
    const studentId = localStorage.getItem("WeCodeLand_user");
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const sessionToSubscribeTo = this.props.sessionToSubscribeTo;
    const coupon = this.state.coupon;
    const profile = JSON.parse(localStorage.getItem("WeCodeLand_profile"));

    const tagManagerArgs = {
      gtmId: "GTM-NGJLSHX",
    };

    try {
      this.setState({
        isLoading: true,
      });
      let result = await API.post(`Coupons/subscribeWithCoupon`, null, {
        params: {
          studentId: studentId,
          sessionId: sessionToSubscribeTo.id,
          code: coupon,
          access_token: accessToken,
        },
      });
      if (result.status === 200) {
        this.setState({
          sucessType: result.data.remainingAmount > 0 ? "1" : "2",
        });
        if (result.data.missingAmount > 0) {
          this.setState({
            missingAmount: result.data.missingAmount,
          });
        }

        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer({
          dataLayer: {
            event: "weCoinPayment",
            studentId: studentId,
            transactionValue: sessionToSubscribeTo.price,
            sessionId: sessionToSubscribeTo.id,
            sessionName: sessionToSubscribeTo.title,
            sessionDomains: sessionToSubscribeTo.domains,
            studentFirstName: profile.fullName,
            studentEmail: profile.email,
            studentPhone: profile.phone,
            paymentMethod: "Wecoin",
          },
        });
      } else if (result.status === 204) {
        this.setState({
          sucessType: "1",
        });
        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer({
          dataLayer: {
            event: "weCoinPayment",
            studentId: studentId,
            transactionValue: sessionToSubscribeTo.price,
            sessionId: sessionToSubscribeTo.id,
            sessionName: sessionToSubscribeTo.title,
            sessionDomains: sessionToSubscribeTo.domains,
            studentFirstName: profile.fullName,
            studentEmail: profile.email,
            studentPhone: profile.phone,
            paymentMethod: "Wecoin",
          },
        });
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      console.log("error: ", message);
      if (message.includes("Expired")) {
        this.setState({
          errorMessage: message,
        });
      } else if (message.includes("Consumed")) {
        this.setState({
          errorMessage: message,
        });
      } else {
        this.setState({
          errorMessage: "Invalid Coupon",
        });
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  submitOfflinePayment = async (e) => {
    e.preventDefault();
    const studentId = localStorage.getItem("WeCodeLand_user");
    const sessionToSubscribeTo = this.props.sessionToSubscribeTo;
    const profile = JSON.parse(localStorage.getItem("WeCodeLand_profile"));
    this.setState({
      isLoading: true,
    });

    const tagManagerArgs = {
      gtmId: "GTM-NGJLSHX",
    };
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer({
      dataLayer: {
        event: "OfflinePayment",
        studentId: studentId,
        transactionValue: sessionToSubscribeTo.price,
        sessionId: sessionToSubscribeTo.id,
        sessionName: sessionToSubscribeTo.title,
        sessionDomains: sessionToSubscribeTo.domains,
        studentFirstName: this.state.CustFirstNameOffline,
        studentEmail: profile.email,
        studentPhone: this.state.CustTelOffline,
        studentAddress: this.state.CustAddressOffline,
        paymentMethod: "Offline",
      },
    });
    this.setState({
      isLoading: false,
      sucessType: "3",
    });
  };
  submitPayment = async (e) => {
    e.preventDefault();
    const studentId = localStorage.getItem("WeCodeLand_user");
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const sessionToSubscribeTo = this.props.sessionToSubscribeTo;
    const coupon = this.state.coupon;
    const request = {
      studentId: studentId,
      sessionId: sessionToSubscribeTo.id,
      custLastName: this.state.CustLastName,
      custFirstName: this.state.CustFirstName,
      custAddress: this.state.CustAddress,
      custTel: this.state.CustTel.toString(),
      email: this.state.EMAIL,
      orderId: this.state.orderId,
      amount:
        this.state.missingAmount > 0
          ? this.state.missingAmount.toString()
          : this.props.sessionToSubscribeTo &&
            this.props.sessionToSubscribeTo.price &&
            this.props.sessionToSubscribeTo.price.toString(),
      orderProducts:
        this.props.sessionToSubscribeTo &&
        this.props.sessionToSubscribeTo.title,
    };
    if (this.state.missingAmount > 0) {
      request.coupon = this.state.coupon;
    }
    /* const request = [
              `studentId=${studentId}`,
              `sessionId=${sessionToSubscribeTo.id}`,
              `custLastName=${this.state.CustLastName}`,
              `custFirstName=${this.state.CustFirstName}`,
              `custAddress=${this.state.CustAddress}`,
              `custTel=${this.state.CustTel.toString()}`,
              `email=${this.state.EMAIL}`,
              `orderId=${this.state.orderId}`,
              `amount=${this.state.missingAmount> 0? this.state.missingAmount.toString() : this.props.sessionToSubscribeTo && this.props.sessionToSubscribeTo.price && this.props.sessionToSubscribeTo.price.toString()}`,
              `orderProducts=${this.props.sessionToSubscribeTo && this.props.sessionToSubscribeTo.title}`
              
          ]
          window.open(`${SERVERDIR}Subscriptions/subscribeWithoutPayment?${request.join('&')}`, "_blank") */
    this.postAndRedirect(
      `${SERVERDIR}Subscriptions/subscribeWithPayment`,
      request
    );
    /* try{
          let result = await API.post(`Subscriptions/subscribeWithPayment`, request, {
            params: { access_token: accessToken }
          });
          if (result.status === 200 ) {
            if( Object.keys(result.data).length > 0){
              this.setState({
                dataToSend : result.data
              })
            }

            this.postAndRedirect(result.data && result.data.url,result.data && result.data.command)

          }}
          catch (error) {
            const {
              response: {
                data: {
                  error: { message }
                }
              } = {}
            } = error;
            console.log("error: ", message);
          }
          finally{
            
          } */
  };

  postAndRedirect = (url, postData) => {
    var f = document.createElement("form");
    f.action = url;
    f.method = "POST";
    for (var key in postData) {
      if (postData.hasOwnProperty(key)) {
        let i = document.createElement("input");
        i.type = "hidden";
        i.name = key;
        i.value = postData[key];
        f.appendChild(i);
      }
    }

    document.body.appendChild(f);
    f.submit();
  };

  getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  showPaymentForm = () => {
    this.setState({
      selectionValue: "2",
      completePayment: true,
      sucessType: "",
    });
  };

  render() {
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader>Payment</ModalHeader>
          <ModalBody>
            <div>
              {this.state.sucessType === "" ? (
                <form id="myForm" onSubmit={this.submit}>
                  <div className="form-group">
                    {!this.state.completePayment ? (
                      <div>
                        <label className="form-label bold" htmlFor="field-1">
                          Please choose a payment method
                        </label>
                        <br />
                        <label>Please select one option</label>
                        <br />
                        <select
                          onChange={this.updateInputValue}
                          value={this.state.selectionValue}
                          name="selectionValue"
                        >
                          <option value="" disabled hidden>
                            No Option Selected
                          </option>
                          <option value="1">WeCoin </option>
                          {this.props.sessionToSubscribeTo &&
                          this.props.sessionToSubscribeTo.price > 0 ? (
                            <option value="2">Online payment </option>
                          ) : null}
                          {this.props.sessionToSubscribeTo &&
                          this.props.sessionToSubscribeTo.price > 0 ? (
                            <option value="3">Offine payment </option>
                          ) : null}
                        </select>
                      </div>
                    ) : null}
                    <br />
                    {this.state.selectionValue === "1" ? (
                      <div>
                        <br />
                        <input
                          type="text"
                          name="coupon"
                          onChange={this.updateCoupounInputValue}
                          value={this.state.coupon}
                          className={
                            this.state.errorMessage.length > 0
                              ? "form-control input-error"
                              : "form-control"
                          }
                          id="imputTitle"
                          placeholder="insert WeCoin code"
                          style={{ borderRadius: "4px" }}
                          required={this.state.selectionValue === "1"}
                        />
                        {this.state.errorMessage.length > 0 ? (
                          <span style={{ color: "red" }}>
                            {this.state.errorMessage}
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </form>
              ) : null}

              {this.state.selectionValue === "2" ? (
                <div>
                  <form
                    id="paiment"
                    name="paiment"
                    onSubmit={
                      this.submitPayment
                    } /* method="POST" action="https://preprod.gpgcheckout.com/Paiement_test/Validation_paiement.php" */
                  >
                    <div className="form-group">
                      <label className="form-label bold" htmlFor="field-1">
                        Please fill out this form
                      </label>
                      <br />
                      <label>FirstName</label>
                      <input
                        type="text"
                        name="CustFirstName"
                        onChange={this.updateInputValue}
                        value={this.state.CustFirstName}
                        className={"form-control"}
                        id="inputfirsttName"
                        placeholder="insert your firstName"
                        style={{ borderRadius: "4px" }}
                        required={this.state.selectionValue === "2"}
                      />
                    </div>
                    <div className="form-group">
                      <label>LastName</label>
                      <input
                        type="text"
                        name="CustLastName"
                        onChange={this.updateInputValue}
                        value={this.state.CustLastName}
                        className={"form-control"}
                        id="inputlastName"
                        placeholder="insert your lastName"
                        style={{ borderRadius: "4px" }}
                        required={this.state.selectionValue === "2"}
                      />
                    </div>
                    {/* <div className="form-group">
                            <label htmlFor="user_login">Email</label>
                            <input type="email" className="form-control"  name="EMAIL" value={this.state.EMAIL} onChange={this.updateInputValue} placeholder="email@email.com" required={this.state.selectionValue === "2"} />
                        </div> */}
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="CustAddress"
                        onChange={this.updateInputValue}
                        value={this.state.CustAddress}
                        className={"form-control"}
                        id="inputAddress"
                        placeholder="insert your address"
                        style={{ borderRadius: "4px" }}
                        required={this.state.selectionValue === "2"}
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <InputMask
                        className="form-control"
                        pattern="^[0-9]{2}[0-9]{3}[0-9]{3}$"
                        mask="99999999"
                        maskChar={null}
                        name="CustTel"
                        value={this.state.CustTel.toString()}
                        onChange={this.updatePhoneValue}
                        id="inputPhoneNumber"
                        placeholder="Phone Number"
                        required={this.state.selectionValue === "2"}
                      />
                    </div>
                    {/* <div style={{display:"none"}}>
                          <input name="NumSite" value="MAR680" /><br /><br />
                          <input
                            name="Password"
                            value={md5('mv_naK09')}
                          /><br /><br />
                          <input name="orderID" value={this.state.orderId} /><br /><br />
                          <input name="Amount" value={this.state.amountToPay} /><br /><br />
                          <input name="Currency" value="TND" /><br /><br />
                          <input name="Language" value="fr" /><br /><br />
                          <input name="PayementType" value="1" /><br /><br />
                          <input name="MerchandSession" value="" /><br /><br />
                          <input
                            name="orderProducts"
                            value={this.props.sessionToSubscribeTo && this.props.sessionToSubscribeTo.title}
                          /><br /><br />
                          <input
                            name="signature"
                            value={this.state.signature}
                          /><br /><br />
                          <input name="AmountSecond" value="" /><br /><br />
                          <input name="vad" value="697700003" /><br /><br />
                          <input name="Terminal" value="001" /><br /><br />
                          <input name="TauxConversion" value=" " /><br /><br />
                          <input name="BatchNumber" value=" " /><br /><br />
                          <input name="MerchantReference" value=" " /><br /><br />
                          <input name="Reccu_Num" value="" /><br /><br />
                          <input name="Reccu_ExpiryDate " value="" /><br /><br />
                          <input name="Reccu_Frecuency " value=" " /><br /><br />
                          <input type="submit" name="Valider" value="Valider" />
                        </div> */}
                  </form>
                </div>
              ) : null}
              {this.state.selectionValue === "3" &&
                this.state.sucessType === "" && (
                  <div>
                    <form
                      id="offlinePaiment"
                      name="offlinePaiment"
                      onSubmit={this.submitOfflinePayment}
                    >
                      <div className="form-group">
                        <label className="form-label bold" htmlFor="field-1">
                          Please fill out this form
                        </label>
                        <br />
                        <label>FirstName</label>
                        <input
                          type="text"
                          name="CustFirstNameOffline"
                          onChange={this.updateInputValue}
                          value={this.state.CustFirstNameOffline}
                          className={"form-control"}
                          id="inputfirsttName"
                          placeholder="insert your firstName"
                          style={{ borderRadius: "4px" }}
                          required={this.state.selectionValue === "2"}
                        />
                      </div>
                      <div className="form-group">
                        <label>LastName</label>
                        <input
                          type="text"
                          name="CustLastNameOffline"
                          onChange={this.updateInputValue}
                          value={this.state.CustLastNameOffline}
                          className={"form-control"}
                          id="inputlastName"
                          placeholder="insert your lastName"
                          style={{ borderRadius: "4px" }}
                          required={this.state.selectionValue === "2"}
                        />
                      </div>
                      {/* <div className="form-group">
                            <label htmlFor="user_login">Email</label>
                            <input type="email" className="form-control"  name="EMAIL" value={this.state.EMAIL} onChange={this.updateInputValue} placeholder="email@email.com" required={this.state.selectionValue === "2"} />
                        </div> */}
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          name="CustAddressOffline"
                          onChange={this.updateInputValue}
                          value={this.state.CustAddressOffline}
                          className={"form-control"}
                          id="inputAddress"
                          placeholder="insert your address"
                          style={{ borderRadius: "4px" }}
                          required={this.state.selectionValue === "2"}
                        />
                      </div>
                      <div className="form-group">
                        <label>Phone Number</label>
                        <InputMask
                          className="form-control"
                          pattern="^[0-9]{2}[0-9]{3}[0-9]{3}$"
                          mask="99999999"
                          maskChar={null}
                          name="CustTelOffline"
                          value={this.state.CustTelOffline.toString()}
                          onChange={this.updatePhoneValue}
                          id="inputPhoneNumber"
                          placeholder="Phone Number"
                          required={this.state.selectionValue === "2"}
                        />
                      </div>
                    </form>
                  </div>
                )}
              {this.state.sucessType === "1" ? (
                <div>
                  <span className="bold">Thank you!</span>
                  <br />
                  <span>
                    Your payment to attend the{" "}
                    <span className="clickable purple">
                      {this.props.sessionToSubscribeTo &&
                        this.props.sessionToSubscribeTo.title}
                    </span>{" "}
                    has succeeded.
                  </span>
                  <br />
                  <span>
                    We will be waiting for you, for now please print the payment
                    receipt.
                  </span>
                  <br />
                  <span className="clickable purple">receipt</span>
                </div>
              ) : this.state.sucessType === "2" ? (
                <div>
                  <span className="bold">
                    You need to add the missing amount
                  </span>
                  <br />
                  <span>
                    The WeCoin code you inserted is valid but you need to add{" "}
                    <span className="clickable purple">
                      {this.state.missingAmount} TND
                    </span>{" "}
                    with the GPG checkout
                  </span>
                </div>
              ) : this.state.sucessType === "3" ? (
                <div>
                  <span>To complete your payment, please follow one of the following methods :</span>
                  <br />
                  <span className="bold">In one of our spaces</span>
                  <br />
                  <span>
                    Make an appointment with one of our advisers to come and
                    make the payment in one of our spaces.
                  </span>
                  <br />
                  <span className="bold">Wire Transfer</span>
                  <br />
                  <span>
                    Call us and receive all the information you need to make the
                    payment from your bank.
                  </span>
                  <br />
                  <span className="bold">By minute mandate</span>
                  <br />
                  <span>
                    Call us and receive all the information to pay from the post
                    office nearest to you.
                  </span>
                  <br />
                  <span>
                    <span className="bold">Contact us : </span>71 136 136
                  </span>
                </div>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.sucessType === "2" &&
            this.state.selectionValue === "1" ? (
              <span className="cancel-search-modal" onClick={this.props.toggle}>
                Cancel
              </span>
            ) : null}
            {this.state.sucessType === "" &&
            this.state.selectionValue === "1" ? (
              <Button
                className="save-button"
                color="primary"
                disabled={this.state.isLoading}
                form="myForm"
                key="submit"
                htmltype="submit"
              >
                Submit
              </Button>
            ) : this.state.sucessType === "" &&
              this.state.selectionValue === "2" ? (
              <Button
                className="save-button"
                color="primary"
                disabled={
                  this.state.isLoading ||
                  this.state.CustFirstName.length === 0 ||
                  this.state.CustLastName.length === 0 ||
                  this.state.CustAddress.length === 0 ||
                  this.state.CustTel.toString().length < 8
                }
                form="paiment"
                key="submit"
                htmltype="submit"
              >
                Submit
              </Button>
            ) : this.state.sucessType === "" &&
              this.state.selectionValue === "3" ? (
              <Button
                className="save-button"
                color="primary"
                disabled={
                  this.state.isLoading ||
                  this.state.CustFirstNameOffline.length === 0 ||
                  this.state.CustLastNameOffline.length === 0 ||
                  this.state.CustAddressOffline.length === 0 ||
                  this.state.CustTelOffline.toString().length < 8
                }
                form="offlinePaiment"
                key="submit"
                htmltype="submit"
              >
                Submit
              </Button>
            ) : this.state.sucessType === "2" &&
              this.state.selectionValue === "1" ? (
              <Button
                className="save-button"
                color="primary"
                disabled={this.state.isLoading}
                onClick={() => this.showPaymentForm()}
              >
                Proceed
              </Button>
            ) : (
              <Button
                className="save-button"
                color="primary"
                disabled={this.state.isLoading}
                onClick={() => {
                  this.props.toggle();
                  this.props.onFinish();
                }}
              >
                Ok
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
ModalPayment.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  submit: PropTypes.func,
  session: PropTypes.object
};
export default withRouter(ModalPayment)
