import React from "react";
import {Container, Col, Row} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMapMarkerAlt, faUsers, faHistory, faPaperclip, faPercent, faCheck, faStar } from '@fortawesome/fontawesome-free-solid'
import { faCalendar, faClock } from '@fortawesome/fontawesome-free-regular'
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import axios from "axios";
import API from "../../../helpers/api";
import moment from 'moment';
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import {stateToHTML} from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import empty_pic_course from "../../../assets/img/course-default.png";
import ModalPayment from './../../../components/modals/ModalPayment';
import {notify} from './../../../helpers/common.jsx'
import NotificationAlert from "react-notification-alert";
import ModalRating from './../../../components//modals/ModalRating.jsx';
import ModalConfirmation from './../../../components//modals/ModalConfirmation.jsx';

import TagManager from "react-gtm-module";

import Loader from 'react-loader-spinner';

const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;

export default class SessionDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //tooltipOpen: false,
            sessionObject: {},
            editorState: EditorState.createEmpty(),
            coursePhoto: {
                preview: empty_pic_course,
                data: {},
                container: {}
            },
            loading: false,
            modalPayment: false,
            modalRating: false,
            canRate: false,
            modalUnsubscribe: false,
            sesionToUnsubscribeTo: {},
            studentUnsubscribingId: "",
            connectedIsSubscribed: false
        }
        this.tooltipIds = ["session_name", "session_price", "entry_level", "session_username", "session_classroom", "session_places", "session_date", "session_time", "session_duration", "session_name",  "session_discount"];
    }
    sessionName=(name)=>{
        if(name === "Completed"){
          return "Complete"
        }
        else return name
        }
    async componentDidMount() {
        const access_token = localStorage.getItem('WeCodeLand_jwt');
        axios.defaults.headers.common['Authorization'] = access_token;
        await this.getSession();
        await this.checkRating();
        const profile = JSON.parse(localStorage.getItem("WeCodeLand_profile"));
        const tagManagerArgs = {
          gtmId: "GTM-NGJLSHX",
        };
        console.log("sessionObj", this.state.sessionObject);
        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer({
          dataLayer: {
            event: "sessionView",
            studentId: profile.id,
            sessionId: this.state.sessionObject.id,
            sessionName: this.state.sessionObject.title,
            sessionDomains: this.state.sessionObject.domains,
            studentFirstName: profile.fullName,
            studentEmail: profile.email,
            studentPhone: profile.phone,
            sessionPrice: this.state.sessionObject.price,
          },
        });
    }

    async checkRating() {
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const studentId = localStorage.getItem("WeCodeLand_user");
        const sessionId = this.props.match.params.id;
        try {
          const res = await API.get(`RatingClasses/check`, {
            params: { access_token: accessToken, studentId: studentId,sessionId: sessionId },
          });
          if (res.status === 200) {
            this.setState({ canRate: res.data.rate });
          } else {
            throw res.status;
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }



    getSession = async () => {
        this.setState({
            loading: true,
        });
        const accessToken = localStorage.getItem("WeCodeLand_jwt");
        const sessionId = this.props.match.params.id;

        try {
            const session = await API.get(`Sessions/${sessionId}`, {
                params: {
                    access_token: accessToken,
                    filter: {
                        include :["sessionCourse", "tutors","subscriptionsSession", {
                            relation: "sessionBooking",
                            scope: {
                                include: "bookingRoom"
                            }
                        } ]
                    }
                },
            });

            if (session.status === 200) {
                console.log(session.data);

                this.setState({sessionObject: session.data})

                this.setState({ editorState: EditorState.createWithContent(convertFromRaw(session.data && session.data.sessionCourse && session.data.sessionCourse.syllabus)) });
                //this.setState({ SessionImage: session.SessionImage });


        }
            this.setState({
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });
        }
    };

    getBookingRoom = (session) => {
        let booking = session.sessionBooking;
        return Array.isArray(booking) && booking.find(bk => bk.status === 1);
    };


    convertEditorContentToHtml = (editorContent)=>{
        return draftToHtml(convertToRaw(editorContent))
    };

    unsubscribe=async(session,connectedIsSubscribed,studentId)=>{
        try {
            console.log("session: ", session);
            let result = await API.post(`Subscriptions/addRemove`, null, { params: {
              value: !connectedIsSubscribed,
              classId: session && session.id,
              studentId: studentId
            }});
            if (result.status === 200) {
              console.log("subscribed SUCCESSFULLY");
              notify(
                { type: "success", message: !connectedIsSubscribed? "Subscribed SUCCESSFULLY":"UnSubscribed SUCCESSFULLY" },
                this
              );
              this.getSession();
              this.toggleModalUnsubscribe();
              this.setState({
                loading: false
              });
            } else {
              throw result.status;
            }
          } catch (error) {
            const {
              response: {
                data: {
                  error: { message }
                }
              } = {}
            } = error;
             this.setState({
              loading: false
            });
            notify({ type: "danger", message: message }, this);
          } finally {
  
          }
    }

    subscriptionButtonCLickHandler = async () => {
        const session = this.state.sessionObject;
        const studentId = localStorage.getItem("WeCodeLand_user");
        this.setState({
          loading: true
        });
        const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
        if(connectedIsSubscribed)
        {this.openModalUnsubscribe(session,connectedIsSubscribed,studentId)
        }
        else{
            this.toggleModalPayment();
        }
      };

      toggleModalUnsubscribe = ()=>{
        this.setState({
          modalUnsubscribe: !this.state.modalUnsubscribe
        })
    }
    openModalUnsubscribe = (session,connectedIsSubscribed,studentId)=>{
        this.setState({
          modalUnsubscribe: true,
          sesionToUnsubscribeTo : session,
          studentUnsubscribingId : studentId,
          connectedIsSubscribed : connectedIsSubscribed
        })
    }
      toggleModalPayment=()=>{
        this.setState({
          modalPayment : !this.state.modalPayment
        })
      }
    
      onFinish=async()=>{
        await this.getSession();
      }

      toggleModalRating=()=>{
        this.setState({
          modalRating: !this.state.modalRating
        })
      }
      


    render() {
        /*const {tooltipOpen} = this.state;
        const toggle = () => this.setState(state =>({tooltipOpen: !state.tooltipOpen}));*/

        const session = this.state.sessionObject;
        const studentId = localStorage.getItem("WeCodeLand_user");
        const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
        

        return (

           <div>
               <div className="notification-popup">
                    <NotificationAlert ref="notificationAlert" />
                </div>
               

               <div className="details_content">
                   <Row>
                       <Col xs={12} md={12}>
                           <div className="page_title_section">
                               <h1 className="page_header">Detail Sessions</h1>
                           </div>
                       </Col>
                   </Row>

                   {(Object.keys(this.state.sessionObject).length === 0)?
                      <div className="center-screen">
                        <Loader
                            type="ThreeDots"
                            color="#D43272"
                            height={80}
                            width={80}
                            timeout={900000}
                            style={{display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            minHeight: "100vh",}}
                        />
                        
                        </div>
                        :
                       <Row>
                       <Col>
                           <div className="details_card">


                               <Row>
                               <UncontrolledTooltip placement="top" target="session_name">
                                        {session.title}
                                </UncontrolledTooltip>
                                   <Col md={6}>
                                       <img src={(session && session.SessionImage && Object.keys(session.SessionImage).length > 0) ? `${ SERVERDIR }containers/${ session.SessionImage.container }/download/${ session.SessionImage.name }` : this.state.coursePhoto.preview} className="banner_img"/>
                                   </Col>
                                   <Col md={6}>
                                       <div className="titles_section">
                                           <h4 className="card_main_title text-truncate"><span id="session_name">{session.title}</span></h4>


                                           <h4 className="card_secondary_title text-truncate" id="session_price">Price : {session.price}dt per person</h4>

                                           <h5 className="user_level text-truncate" id="entry_level">{session.difficulty} Level</h5>

                                           <div className="rating-container">
                                               <i className="fa fa-star"></i> <span>{session.rating}</span>
                                           </div>
                                       </div>



                                       <div className="links_section">
                                           <Container>
                                               <Row>
                                                   <Col className="text-truncate" id="session_username" md={4}><FontAwesomeIcon icon={faUser} /> <a target="_blank" href={`${BASEDIR}/profile/${session.tutors && session.tutors.id}`}>{session.tutors && session.tutors.fullName}</a></Col>
                                                   <Col className="text-truncate" id="session_classroom" md={4}><FontAwesomeIcon icon={faMapMarkerAlt} /> {(session.isOnline) ? <a href={`//${session && session.onlineSessionLink && session.onlineSessionLink.replace(/(^\w+:|^)\/\//, '')}`} target="_blank" rel="noopener noreferrer">{"Online"}</a> : <a target="_blank" href={`${BASEDIR}/classroom/${this.getBookingRoom(session) && this.getBookingRoom(session).bookingRoom && this.getBookingRoom(session).bookingRoom.id}`} target="_blank" rel="noopener noreferrer">{this.getBookingRoom(session) && this.getBookingRoom(session).bookingRoom && this.getBookingRoom(session).bookingRoom.name}</a>}</Col>
                                                   <Col className="text-truncate" id="session_places" md={4}><FontAwesomeIcon icon={faUsers} /> <span>{session.nbrStillAvailable} Places left</span></Col>
                                               </Row>
                                               <Row>
                                                   <Col className="text-truncate"  id="session_date" md={4}><FontAwesomeIcon icon={faCalendar} /> <span>{moment(session.start).format("DD/MM")} - {moment(session.end).format("DD/MM")} </span></Col>
                                                   <Col className="text-truncate" id="session_time" md={4}><FontAwesomeIcon icon={faClock} /> <span>{moment.utc(session.startTime).format("HH:mm")} - {moment.utc(session.endTime).format("HH:mm")}</span></Col>
                                                   <Col className="text-truncate"  id="session_duration" md={4}><FontAwesomeIcon icon={faHistory} /> <span>{session.duration} hours duration</span></Col>
                                               </Row>
                                               <Row>
                                                   {session.sessionCourse && session.sessionCourse.file && session.sessionCourse.file.name && session.sessionCourse.file.name.length>0 ?
                                                   
                                                   <Col className="text-truncate" id="session_name" md={8}>
                                                     <FontAwesomeIcon icon={faPaperclip} /> <a href={`${SERVERDIR}Container2s/file/download/${session.sessionCourse && session.sessionCourse.file && session.sessionCourse.file.name}`}>{session.sessionCourse && session.sessionCourse.file && session.sessionCourse.file.originalFileName}</a></Col> : null}
                                                   {session && session.discount && session.discount > 0 ? <Col className="text-truncate" id="session_discount" md={4}><FontAwesomeIcon icon={faPercent} /> <span>{session.discount}% off</span></Col> : null}
                                               </Row>
                                           </Container>
                                       </div>

                                       <div className="actions_section">

                                           <Row>
                                               {/* <button className="btn btn-primary custom_primary_btn"> <FontAwesomeIcon className="btn_icon" icon={faCheck} />  Not Going</button>
                                                */}
                                                <Col>
                                                    <button
                                                    className={(connectedIsSubscribed && connectedIsSubscribed) || ((session && (session.status === "Full" || session.status === "Completed")))  ? "btn btn-primary" : "custom-button outline"}
                                                    onClick={this.subscriptionButtonCLickHandler}
                                                    disabled={session && (session.status === "Full" || session.status === "Completed")}
                                                    >
                                                    <i className={(session && (session.status === "Full" || session.status === "Completed"))?  "fa fa-check-circle" : connectedIsSubscribed ? "fa fa-times" : "fa fa-check"}></i>
                                                    &nbsp;&nbsp;{(session && (session.status === "Full" || session.status === "Completed"))?  this.sessionName(session.status) : connectedIsSubscribed ? "Not Going" : "Participate"}
                                                    </button>
                                                </Col>
                                               {this.state.canRate?
                                               <Col><button className="btn btn-secondary" onClick={this.toggleModalRating}><FontAwesomeIcon className="btn_icon" icon={faStar} /> Rate this course</button></Col>
                                               :<Col></Col>}
                                           </Row>
                                       
                                       </div>
                                   </Col>
                               </Row>

                               <Row>
                                   <Col>
                                       <div className="p_section">
                                           <h3>description</h3>
                                           <p>{session.description}</p>
                                       </div>
                                       <hr/>
                                       {(session.isOnline && session.onlineSessionLink && session.onlineSessionLink.length>0)  && <div><div className="p_section mt-0">
                                           <h3>session Link</h3>
                                           <a href={`//${session && session.onlineSessionLink && session.onlineSessionLink.replace(/(^\w+:|^)\/\//, '')}`} target="_blank" rel="noopener noreferrer">{session && session.onlineSessionLink}</a>
                                       </div>
                                       <hr/>
                                       </div>
                                       }

                                       <div className="p_section">
                                           <h3>Syllabus</h3>
                                           <Editor editorState={this.state.editorState} readOnly={true} toolbarStyle={{display: 'none'}} editorStyle={{border: 0, padding: 0}} />
                                       </div>
                                       <hr/>

                                       <div className="p_section">
                                           <h3>Required Skills</h3>
                                           <ul>


                                               {
                                                   session && session.sessionCourse && session.sessionCourse.prerequisite &&
                                                   session.sessionCourse.prerequisite.map((req, index) => (<li key={index}>{req}</li>))
                                               }
                                           </ul>
                                       </div>
                                       <hr/>

                                       <div className="p_section">
                                           <h3>domaines</h3>
                                           <ul>
                                               {
                                                   session && session.sessionCourse && session.sessionCourse.domains &&
                                                   session.sessionCourse.domains.map((domain, index) => (<li key={domain.id}>{domain.name}</li>))
                                               }
                                           </ul>
                                       </div>


                                   </Col>
                               </Row>


                           </div>
                       </Col>
                   </Row>}
                   
               </div>
               <ModalPayment
                isOpen={this.state.modalPayment}
                toggle={this.toggleModalPayment}
                onFinish={this.onFinish}
                sessionToSubscribeTo={session}/>

                <ModalRating
                isOpen={this.state.modalRating}
                toggle={this.toggleModalRating}
                session={session}
                />
                <ModalConfirmation
                    isOpen={this.state.modalUnsubscribe}
                    toggle={this.toggleModalUnsubscribe}
                    disabledButton={false}
                    confirm={()=>this.unsubscribe(this.state.sesionToUnsubscribeTo,this.state.connectedIsSubscribed,this.state.studentUnsubscribingId)}
                    cancel={this.toggleModalUnsubscribe}
                    title={"Cancel participation"}
                    body={ "Are you sure you want to cancel your participation? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
           </div>

        );
    }
}
