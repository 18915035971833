import React from 'react';
import {

  Row, Col, Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {

} from 'components';
import API from './../../../helpers/api.jsx';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import { notify } from "./../../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import SessionCard from './../../../components/cards/SessionCard.jsx'
import ClassroomCard from '../../../components/cards/ClassroomCard.jsx';
import Loader from 'react-loader-spinner';
import ModalPayment from './../../../components/modals/ModalPayment';
import ModalConfirmation from './../../../components/modals/ModalConfirmation';

var IMGDIR = process.env.REACT_APP_IMGDIR;
var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params && this.props.match.params.id,
      profile: {},
      sessions: [],
      attendedSessions: [],
      ownedRooms: [],
      taughtSessions: [],
      userRole: [],
      limit: 4,
      count: 0,
      totalAttendedSessions: 0,
      totalTaughtSessions: 0,
      totalOwnedClassrooms: 0,
      active: 1,
      mine: false,
      loading: true,
      modalUpgrade: false,
      selectionValue: "",
      modalPayment: false,
      sessionToSubscribeTo: {},
      modalUnsubscribe: false,
      sesionToUnsubscribeTo: {},
      studentUnsubscribingId: "",
      connectedIsSubscribed: false
    };
  }
  async componentWillMount() {
    console.log("mount");
    const access_token = await localStorage.getItem('WeCodeLand_jwt');
    axios.defaults.headers.common['Authorization'] = access_token;
    if (this.state.id === localStorage.getItem('WeCodeLand_user')) {
      this.setState({ mine: true });
    }
    await this.getProfileDetails(this.state.id);
    await this.getAttendedSessions(this.state.id);
    /* this.getEvents(this.state.active); */
  }
  async componentWillReceiveProps(nextProps) {
    const { id } = nextProps.match.params;
    this.setState({ id: id });
    const access_token = localStorage.getItem('WeCodeLand_jwt');
    axios.defaults.headers.common['Authorization'] = access_token;
    if (id === localStorage.getItem('WeCodeLand_user')) {
      this.setState({ mine: true });
    }
    await this.getProfileDetails(id);
    await this.getAttendedSessions(id);
    /* await this.getEvents(this.state.active); */
  }
  getProfileDetails = async (userId) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const profile = await API.get(`Profiles/getUser`, {
        params: { userId: userId, access_token: accessToken }
      });

      if (profile.status === 200) {
        this.setState({
          profile: profile.data.profileInfos,
          userRole: profile.data.role
        }, () => this.getSectionsLists());
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };
  getAttendedSessions = async () => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const userId = this.state.id;
    const limit = this.state.limit;
    const skip = 0;
    try {
      const attendedSessions = await API.get(`Profiles/studentAttendedSessions`, {
        params: { studentId: userId, limit: limit, skip: skip, access_token: accessToken },
      });

      if (attendedSessions.status === 200) {
        this.setState({
          attendedSessions: attendedSessions.data.attendedSessions,
          totalAttendedSessions: attendedSessions.data.attendedCount
        });
        /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };
  getTaughtSessions = async () => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const userId = this.state.id;
    const limit = this.state.limit;
    const skip = 0;
    try {
      const taughtSessions = await API.get(`Profiles/tutorTaughtSessions`, {
        params: { tutorId: userId, limit: limit, skip: skip, access_token: accessToken },
      });

      if (taughtSessions.status === 200) {
        this.setState({
          taughtSessions: taughtSessions.data.taughtSessions,
          totalTaughtSessions: taughtSessions.data.taughtCount
        });
        /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };
  getOwnedRooms = async () => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const userId = this.state.id;
    const limit = this.state.limit;
    const skip = 0;
    try {
      const ownedRooms = await API.get(`Profiles/hostRooms`, {
        params: { hostId: userId, limit: limit, skip: skip, access_token: accessToken },
      });

      if (ownedRooms.status === 200) {
        this.setState({
          ownedRooms: ownedRooms.data.ownedClassrooms,
          totalOwnedClassrooms: ownedRooms.data.count
        });
        /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  getSectionsLists = () => {
    if (this.state.userRole.includes('host')) { this.getOwnedRooms(); }
    if (this.state.userRole.includes('teacher')) {
      this.getTaughtSessions()
    }
  }

  renderObjectTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag.name}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  renderStringTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  unsubscribe=async(session,connectedIsSubscribed,studentId)=>{
    try {
      console.log("session: ", session);
      let result = await API.post(`Subscriptions/addRemove`, null, {
        params: {
          value: !connectedIsSubscribed,
          classId: session && session.id,
          studentId: studentId
        }
      });
      if (result.status === 200) {
        console.log("subscribed SUCCESSFULLY");
        notify(
          { type: "success", message: !connectedIsSubscribed ? "Subscribed SUCCESSFULLY" : "UnSubscribed SUCCESSFULLY" },
          this
        );
        this.getAttendedSessions();
        this.getTaughtSessions();
        this.toggleModalUnsubscribe();
        this.setState({
          loading: false,
          idLoading: ''
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {

    }
  }

  participateButtonCLickHandler = async (session, index) => {
    const studentId = localStorage.getItem("WeCodeLand_user");
    this.setState({
      loading: true,
      idLoading: session.id
    });
    const connectedIsSubscribed = !!(session && session.subscriptionsSession && session.subscriptionsSession.filter(subscription => subscription.studentId === studentId).map(subscription => subscription.going)[0]);
    if(connectedIsSubscribed)
    {this.openModalUnsubscribe(session,connectedIsSubscribed,studentId)}
    else{
      this.setState({
        sessionToSubscribeTo: session
      },()=>this.toggleModalPayment())
    }
  };
  toggleModalUnsubscribe = ()=>{
    this.setState({
      modalUnsubscribe: !this.state.modalUnsubscribe
    })
}
openModalUnsubscribe = (session,connectedIsSubscribed,studentId)=>{
    this.setState({
      modalUnsubscribe: true,
      sesionToUnsubscribeTo : session,
      studentUnsubscribingId : studentId,
      connectedIsSubscribed : connectedIsSubscribed
    })
}

  classroomButtonCLickHandler = async (room) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {

      let result = await API.post(`Rooms/displayMask`, null, {
        params: {
          access_token: accessToken,
          value: room && room.status && room.status.toUpperCase() === "NEW" ? true : false,
          roomId: room && room.id
        }
      });
      if (result.status === 200) {
        console.log("changed SUCCESSFULLY");
        notify(
          { type: "success", message: room && room.status && room.status.toUpperCase() === "NEW" ? "Room published" : "Room placed in drafts" },
          this
        );
        this.getOwnedRooms();
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      this.setState({
        loading: false
      });
      notify({ type: "danger", message: "something went wrong" }, this);
    } finally {

    }
  };

  toggleModalUpgrade = () => {
    this.setState({
      modalUpgrade: !this.state.modalUpgrade,
    });
  };
  selectionChange = (event) => {
    this.setState({ selectionValue: event.target.value });
  }
  goToUpgradeToTeacher = () => {
    this.props.history.push(`${BASEDIR}/upgradeToTutor/${this.state.id}`)
  }
  goToUpgradeToHost = () => {
    this.props.history.push(`${BASEDIR}/upgradeToHost/${this.state.id}`)
  }

  toggleModalPayment=()=>{
    this.setState({
      modalPayment : !this.state.modalPayment
    })
  }

  onFinish=async()=>{
    await this.getAttendedSessions();
    await this.getTaughtSessions();
  }

  render() {
    const { id, mine, profile, attendedSessions, taughtSessions, ownedRooms, totalAttendedSessions, totalOwnedClassrooms, totalTaughtSessions } = this.state;
    if (this.state.loading && this.state.idLoading === "") return <div className="center-screen">
      <Loader
        type="ThreeDots"
        color="#D43272"
        height={80}
        width={80}
        timeout={900000}
        style={{display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",}}
      />
      
    </div>
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>

              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">{mine ? "My Profile" : "User Profile"}</h1>
                </div>

                <div className="float-right">
                  {mine ?
                    <div className="d-flex">
                      <NavLink to={`${BASEDIR}/editProfile/` + id}>
                        <h1><button className="btn btn-primary btn-sm profile-btn button-add-new mr-0">
                          Edit my profile</button></h1>
                      </NavLink>
                      {(!(["teacher", "host"].every(i => this.state.userRole.includes(i)))) && !(profile && profile.upgradeRequest && profile.upgradeRequest.length>0 && profile.upgradeRequest.filter(obj => { return obj.status === "Pending" }).length > 0) ?
                        <h1><button onClick={this.toggleModalUpgrade} className="btn btn-primary btn-sm profile-btn button-add-new">
                          Upgrade my profile</button>
                        </h1>
                        : null}
                    </div>

                    : null}

                </div>
              </div>

              <div className="col-xl-12">
                <section className="box profile-page">
                  <div className="content-body">
                    <div className="col-12">
                      <div className="row uprofile">
                        <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                          <img alt="" src={(profile && profile.image && profile.image.name) ? `${SERVERDIR}Containers/img/download/${profile.image.name}` : `${IMGDIR}/images/profile/profile-general.jpg`} className="img-fluid" />
                        </div>
                        <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                          <h3 className="uprofile-owner">
                            <a href="#!">{profile && profile.fullName}</a>
                          </h3>
                          <div className="clearfix"></div>
                          <div className="social-media-icons">
                            <ul className="list-inline list-unstyled">
                              <li className="list-inline-item">
                                <a
                                  href={(profile && profile.email && profile.email.length > 0 && "mailto:" + profile.email) || "#"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-envelope-square"
                                    style={{
                                      color:
                                        profile && profile.email && profile.email.length
                                          ? "#D43272"
                                          : "#606060",
                                      fontSize: "23px"
                                    }}
                                  ></i>
                                </a>
                              </li>
                              {(profile.linkedinUrl && profile.linkedinUrl.length) && <li className="list-inline-item">
                                <a
                                  href={`//${
                                                        profile.linkedinUrl != null &&
                                                        profile.linkedinUrl.replace(/(^\w+:|^)\/\//, "")
                                                        }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-linkedin-square "
                                    style={{
                                      color: profile.linkedinUrl && profile.linkedinUrl.length
                                        ? "#D43272"
                                        : "#606060",
                                      fontSize: "23px"
                                    }}
                                  ></i>
                                </a>
                              </li>}
                              {(profile.facebookUrl && profile.facebookUrl.length) && <li className="list-inline-item">
                                <a
                                  href={`//${
                                                        profile.facebookUrl != null &&
                                                        profile.facebookUrl.replace(/(^\w+:|^)\/\//, "")
                                                        }`}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                  <i
                                    className="fa fa-facebook-square "
                                    style={{
                                      color: profile.facebookUrl && profile.facebookUrl.length
                                        ? "#D43272"
                                        : "#606060",
                                      fontSize: "23px"
                                    }}
                                  ></i>
                                </a>
                              </li>}
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                          <p className="uprofile-title">{profile && profile.profession}</p>
                          <div className="clearfix"></div>
                          <p className="uprofile-list">
                            <span><i className='i-map'></i> {profile && profile.governorate}</span>
                            <span><i className='i-book-open'></i> {totalAttendedSessions} attended sessions</span>
                            {this.state.userRole && this.state.userRole.includes('teacher') ? <span><i className='i-graduation'></i> {totalTaughtSessions} taught sessions</span> : null}
                            {this.state.userRole && this.state.userRole.includes('host') ? <span><i className='i-location-pin'></i> {totalOwnedClassrooms} owned classrooms</span> : null}

                          </p>
                          {/* <p>{profile && profile.description}</p> */}
                        </div>
                      </div>
                      <div className="row profile-details">
                        {profile && profile.description && profile.description.length > 0 ?
                          <h3 className="font-weight-bold">Biography</h3>
                          : null}
                        <p>{profile && profile.description}</p>
                        {profile && profile.interests && profile.interests.length > 0 ?
                          <div>
                            <hr/>
                            <h3 className="font-weight-bold">Interests</h3>
                            {this.renderObjectTags(profile && profile.interests)}
                          </div>
                          : null}
                        {profile && profile.expertises && profile.expertises.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Expertises</h3>
                            {this.renderObjectTags(profile && profile.expertises)}
                          </div>
                          : null}
                        {profile && profile.education && profile.education.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Education</h3>
                            {this.renderStringTags(profile && profile.education)}
                          </div>
                          : null}
                        {profile && profile.competencies && profile.competencies.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Competencies</h3>
                            {this.renderStringTags(profile && profile.competencies)}
                          </div>
                          : null}
                        {profile && profile.experience && profile.experience.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Experience</h3>
                            {this.renderStringTags(profile && profile.experience)}
                          </div>
                          : null}
                        {profile && profile.subjects && profile.subjects.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Subjects</h3>
                            {this.renderStringTags(profile && profile.subjects)}
                          </div>
                          : null}
                        {profile && profile.certifications && profile.certifications.length > 0 ?
                          <div>
                          <hr/>
                            <h3 className="font-weight-bold">Certifications</h3>
                            {this.renderStringTags(profile && profile.certifications)}
                          </div>
                          : null}
                      </div>
                    </div>

                  </div>
                </section>
              </div>
            </Col>

          </Row>
          {attendedSessions && attendedSessions.length > 0 ?
            <Row>
              <Col xs={12} md={12}>
                <div className="page-title">
                  <div className="float-left">
                    <h1 className="title">Attended Sessions</h1>
                  </div>
                  <div className="float-right see-all-button">
                    <h3 className="font-weight-bold">
                      <Link to={`${BASEDIR}/attendedSessions/${this.state.id}/1`}>See All</Link>
                    </h3>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 cards-list-container">
                        {attendedSessions.slice(0, 4).map((session, key) => {
                          return (
                            <SessionCard
                              key={key}
                              session={session}
                              index={key}
                              buttonCLickHandler={this.participateButtonCLickHandler}
                              subscriptions={session.subscriptionsSession}
                              isDisabled={this.state.loading && this.state.idLoading === session.id}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            null}
          {taughtSessions && taughtSessions.length > 0 ?
            <Row>
              <Col xs={12} md={12}>
                <div className="page-title">
                  <div className="float-left">
                    <h1 className="title">Taught Sessions</h1>
                  </div>
                  <div className="float-right see-all-button">
                    <h3 className="font-weight-bold">
                      <Link to={`${BASEDIR}/taughtSessions/${this.state.id}/1`}>See All</Link>
                    </h3>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 cards-list-container">
                        {taughtSessions.slice(0, 4).map((session, key) => {
                          return (
                            <SessionCard
                              key={key}
                              session={session}
                              index={key}
                              buttonCLickHandler={this.participateButtonCLickHandler}
                              subscriptions={session.subscriptionsSession}
                              isDisabled={this.state.loading && this.state.idLoading === session.id}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            null}
          {ownedRooms && ownedRooms.length > 0 ?
            <Row>
              <Col xs={12} md={12}>
                <div className="page-title">
                  <div className="float-left">
                    <h1 className="title">Owned CLassrooms</h1>
                  </div>
                  <div className="float-right see-all-button">
                    <h3 className="font-weight-bold">
                      <Link to={`${BASEDIR}/ownedClassrooms/${this.state.id}/1`}>See All</Link>
                    </h3>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 cards-list-container">
                        {ownedRooms.slice(0, 4).map((room, key) => {
                          return (
                            <ClassroomCard
                              key={room.id}
                              room={room}
                              index={key}
                              buttonCLickHandler={this.classroomButtonCLickHandler} />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            null}
        </div>
        <Modal
          isOpen={this.state.modalUpgrade}
          toggle={this.toggleModalUpgrade}
          className={this.props.className}
          backdrop={'static'}
          keyboard={false}
        >
          <ModalHeader
            toggle={this.toggleModalUpgrade}
          >
            <span className="bold">Upgrade my profile</span>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="form-group">
                <label className="form-label bold" htmlFor="field-1">
                  Upgrading profile
                    </label>
                <br />
                <label>Please Select one option</label>
                <br />
                <select onChange={this.selectionChange} value={this.state.selectionValue}>
                  <option value="" disabled hidden>No Option Selected</option>
                  {!this.state.userRole.includes("teacher") ? <option value="1">Upgrade to a teacher </option> : null}
                  {!this.state.userRole.includes("host") ? <option value="2">Upgrade to a host </option> : null}
                </select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <span className="cancel-search-modal" onClick={this.toggleModalUpgrade}>Cancel</span>
            <Button
              className="confirm-modal"
              color="primary"
              onClick={this.state.selectionValue === "1" ? () => this.goToUpgradeToTeacher() : () => this.goToUpgradeToHost()}
              disabled={this.state.selectionValue === ""}
            >
              Next
                    </Button>
          </ModalFooter>
        </Modal>
        <ModalPayment
          key={this.state.sessionToSubscribeTo.id}
          isOpen={this.state.modalPayment}
          toggle={this.toggleModalPayment}
          onFinish={this.onFinish}
          sessionToSubscribeTo={this.state.sessionToSubscribeTo}/>
          <ModalConfirmation
                    isOpen={this.state.modalUnsubscribe}
                    toggle={this.toggleModalUnsubscribe}
                    disabledButton={false}
                    confirm={()=>this.unsubscribe(this.state.sesionToUnsubscribeTo,this.state.connectedIsSubscribed,this.state.studentUnsubscribingId)}
                    cancel={this.toggleModalUnsubscribe}
                    title={"Cancel participation"}
                    body={ "Are you sure you want to cancel your participation? This process cannot be undone" }
                    confirmText={"Confirm"}
                />
      
      </div>
    );
  }
}

export default UserProfile;
