import React from "react";
import {
    Label,
    FormGroup
  } from "reactstrap";

class Cgu extends React.Component {
  render() {
    return (
        <div className="cgu">
            <table class="wp-block-table"><tbody><tr><td>
      Les présentes Conditions Générales de Vente et
      d’Utilisation («<strong>CGVU</strong>») ont pour
      objet l’encadrement des modalités de vente et de réservation des <strong>Ticket</strong> d’accès aux différentes formations
      WeCode, sur le site internet <a href="https://www.wecode.tn/">www.wecode.tn</a> (le&nbsp;«&nbsp;<strong>Site </strong>») ainsi que la définition des
      conditions d’accès et d’utilisation des services y afférents par les clients (l’«<strong>Utilisateur</strong>» ou les «&nbsp;<strong>Utilisateurs</strong>&nbsp;»).
      &nbsp;
      Les présentes CGVU sont accessibles sur le Site à la
      rubrique « CGVU » et comprennent également la Politique de Confidentialité
      qui fait l’objet d’un document distinct accessible à la rubrique
      «&nbsp;Privacy Policy&nbsp;/Politique de Confidentialité ». 
      &nbsp;
      Toute inscription ou utilisation du site implique
      l’acceptation sans aucune réserve, ni restriction aucune des présentes CGVU.
      Lors de l’utilisation des services proposés par le Site, chaque utilisateur déclare
      avoir pris connaissance des présentes CGVU en cochant la case portant la
      mention suivante&nbsp;: 
      &nbsp;
      «<em> Je reconnais
      avoir lu et compris les CGVU et je les accepte</em> ».
      </td></tr><tr><td>
          <br/>
      <strong>MENTIONS LÉGALES</strong>
      <strong>&nbsp;</strong>
      Le site internet <a href="https://www.wecode.tn/">www.wecode.tn</a> est édité par
      la Société WeCode Land, société anonyme de droit tunisien, immatriculée au
      Registre de Commerce sous le numéro B01172472018, titulaire du matricule
      fiscal numéro 1580582V, ayant son siège social sis au 63 avenue Habib
      Bourguiba, Immeuble Parnasse, 7ème étage, Bureau 707, 1000 Bab Bhar, Tunis,
      Tunisie, ci- après (l’&nbsp;« <strong>Éditeur</strong>
      »), ayant également qualité de responsable de traitement et en cours
      d’immatriculation auprès de l’Instance Nationale de Protection des Données
      Personnelles («&nbsp;<strong>INPDP</strong>&nbsp;»).
      
      </td></tr><tr><td>
      <br/>
      <strong>OBJET </strong>
      <strong>&nbsp;</strong>
      Le Site est une
      plateforme de vente et de réservation en ligne de place(s) <strong>Ticket </strong>permettant l’accès à des formations professionnelles,
      des événements, des ateliers et workshops dans le domaine du digital et de
      l’économie numérique (le «<strong>Service</strong>»
      ou les «<strong>&nbsp;Services WeCode&nbsp;</strong>»). 
      &nbsp;
      Sont également
      fournis sur le Site, toutes les informations relatives à l’ensemble des
      activités et services de l’Éditeur. 
      </td></tr><tr><td>
      <br/>
      <strong>COMPTE PERSONNEL</strong>
      &nbsp;
      Le Site permet à l’Utilisateur d’avoir un compte
      personnel offrant un accès aux services de vente et réservation en ligne de <strong>Ticket</strong>. L’inscription se fait <em>via </em>Linkedin, Google ou <em>via </em>un formulaire d’inscription en
      ligne. 
      &nbsp;
      La création d’un compte personnel confère la qualité de
      membre et permet à chaque Utilisateur d’enregistrer ses données personnelles
      dont notamment ses moyens de paiement. &nbsp;
      &nbsp;
      En acceptant de s’inscrire aux services réservés,
      l’Utilisateur s’engage à fournir des informations sincères et exactes. 
      </td></tr><tr><td>
      <br/>
      <strong>ACHAT/RESERVATION</strong>
      Le Site permet aux Utilisateurs un accès aux services d’achat d’un ou de plusieurs
      <strong>Ticket</strong> pour les formations professionnelles,
      les événements, les ateliers et workshops proposés et disponibles sur le
      Site. 
      Toutes les offres de services sont valables et
      disponibles conformément aux dates qui y sont indiquées&nbsp;; tant que ladite
      date n’a pas expiré. 
      Les prix sont affichés en Dinars Tunisiens toutes taxes
      comprises et à l’unité. Le Site se réserve le droit de modifier ses prix à
      tout moment, les Services seront néanmoins facturés au tarif en vigueur au moment
      de la achat.
      L’Utilisateur
      aura le choix d’acheter les Services directement en ligne ou bien de procéder
      au paiement ultérieur du Service choisi auprès de l’Editeur.
      Lors de la confirmation de la réservation ou la
      validation de l’achat, l’Utilisateur pourra enregistrer et/ou imprimer son <strong>Ticket</strong>. 
      Un e-mail de confirmation récapitulant les informations
      de l’achat
      portant mention des présentes CGVU lui sera automatiquement adressé. 
      </td></tr><tr><td>
      <br/>
      <strong>MODALITÉS DE PAIEMENT </strong>
      &nbsp;
      La validation de l’achat implique le paiement du prix
      indiqué, qui peut exclusivement s’effectuer par l’un&nbsp;des moyens de
      paiement électroniques suivants&nbsp;:
      &nbsp;
      Carte Bancaire (Visa, Mastercard, Carte
      Bleue)Carte e-Dinar La Poste Tunisienne &nbsp;GP E-WALLET Borne RunPay
      
      
      
      &nbsp;
      Le moyen de paiement utilisé être devra en cours de
      validité au moment de la transaction. 
      &nbsp;
      La sécurité des paiements est assurée par GpgCheckout,
      une plateforme de paiement utilisant les dernières technologies en matière de
      sécurisation et de cryptage et faisant l’objet d’une certification PCI DSS de
      Visa et MasterCard en Afrique et au Moyen orient. 
      <br/>
      Les données personnelles transmises au moment du paiement feront l’objet d’une
      sécurisation par cryptage S.S.L. (Secure Socket Layer) dans le cadre de la
      gestion de la transaction par les entités bancaires concernées.
      <br/>
      </td></tr><tr><td>
      <strong>DROIT DE RÉTRACTATION</strong>
      <strong>&nbsp;</strong>
      Conformément à la législation en vigueur, le droit de
      rétractation ne peut être exercé pour les services fournis et pleinement
      exécutés avant l’expiration du délai légal de rétractation. 
      &nbsp;
      Tout droit de rétractation est par conséquent exclu pour
      l’achat d’un <strong>Ticket</strong>. 
      </td></tr><tr><td>
      <br/>
      <strong>ANNULATION/REMBOURSEMENT</strong>
      <strong>&nbsp;</strong>
      L’annulation des <strong>Ticket</strong> ou des
      Services WeCodepourra se faire à
      l’initiative de l’Utilisateur ou de&nbsp;l’Editeur, dans les conditions
      décrites ci-après&nbsp;: 
      &nbsp;
      <em>Annulation de l’Utilisateur </em>
      &nbsp;
      Les demandes d’annulation de toute réservation/achat
      devront être faites au plus tard une semaine avant le déroulement du Service
      réservé. Au-delà de ce délai aucune annulation ne pourra être faite. 
      Tout Utilisateur pourra procéder à l’annulation de son achat
      à travers l’option «&nbsp;annulation&nbsp;» proposée sur l’e-mail de
      confirmation qui lui a été envoyé ou bien <em>via
      </em>son compte personnel sur le Site. 
      &nbsp;
      <em>Annulation de l’Éditeur</em>
      &nbsp;
      En dehors des cas de force majeure, tels que définis par
      la législation tunisienne en la matière, l’annulation du Service pourra être
      faite à l’initiative de l’Éditeur moyennant notification préalable de
      l’Utilisateur dans un délai maximum de 24 heures avant la date d’exécution
      prévue. 
      L’Utilisateur aura dans ce cas droit au remboursement intégral
      de la somme payée. 
      &nbsp;
      <em>Remboursement</em>
      &nbsp;
      Dans les cas précités, le remboursement de la somme sera
      réalisé par virement sur le compte bancaire associé au moyen de paiement
      utilisé pour effectuer l’achat.
      
      </td></tr><tr><td>
      <br/>
      <strong>OBLIGATIONS DES UTILISATEURS </strong>
      <strong>&nbsp;</strong>
      Dans le cadre de l’utilisation des services fournis par le
      Site, les Utilisateurs s’engagent à&nbsp;:
      respecter les lois et règlementations
      en vigueur ;ne pas usurper l’identité d’un
      tiers&nbsp;;ne publier aucun contenu diffamatoire,
      injurieux ou portant atteinte à la pudeur et à la dignité humaine ;ne porter atteinte, d’aucune manière
      que ce soit, au déroulement normal des services ainsi qu’aux infrastructures
      du Site ;ne pas divulguer à d’autres
      Utilisateurs ses informations de connexion, en particulier son mot de passe
      d’accès à son compte personnel.
      
      
      
      
      </td></tr><tr><td>
      <br/>
      <strong>RESPONSABILITÉ</strong>
      <strong>&nbsp;</strong>
      <em>9.1 Responsabilité de l’Éditeur</em>
      <em>&nbsp;</em>
      L’Éditeur n’accepte aucune responsabilité quant
      aux&nbsp;:
      &nbsp;
      Données et contenus quels qu’ils soient
      publiés par les Utilisateurs du Site&nbsp;; Dommages matériels ou immatériels
      causés aux équipements informatiques des utilisateurs ainsi qu’aux données
      qui y sont stockées, à la suite d’une utilisation, à l’accès, ou au
      téléchargement provenant du Site ; 
      
      &nbsp;
      Et ce, sauf en cas de fraude et/ou de négligence grave,
      en relation avec la fourniture de ses services.&nbsp;&nbsp; 
      &nbsp;
      L’Éditeur ne peut être tenue responsable en cas
      d’indisponibilité du Site et de ses services quelle que soit la raison ou la
      durée de cette indisponibilité. L’accès au Site pourra faire l’objet d’une
      suspension temporaire ou définitive et ce, sans préavis.
      &nbsp;
      <em>9.2 Responsabilité de l’Utilisateur</em>
      &nbsp;
      Les Utilisateurs sont responsables de toute information,
      données, textes, photographies, images, vidéos, messages, dossiers ou autres
      contenus publiés, affichés ou soumis aux services du Site. 
      &nbsp;
      Tout Utilisateur doit s’assure de garder ses
      identifiants de connexion secrets et assume les risques liés à toute
      divulgation, quelle qu’en soit la forme ainsi qu’à l’utilisation de son
      compte personnel. 
      </td></tr><tr><td>
      <br/>
      <strong>PROPRIÉTÉ INTELLECTUELLE</strong>
      &nbsp;
      Les marques, logos, graphismes, photographies, textes
      composant le Site ainsi que tous les contenus qu’il génère et qui y sont
      diffusés restent la propriété exclusive de l’Éditeur ou, selon le cas, de ses
      partenaires, fournisseurs et sociétés affiliées. 
      &nbsp;
      Aucune licence d’utilisation d’une marque, d’un brevet
      ou de toute autre propriété intellectuelle liée aux services fournis par le
      Site n’est transférée aux Utilisateurs qui disposent de ce fait, uniquement
      des droits d’utilisation qui leur sont accordés par l’Éditeur. Ces droits
      sont exclusivement destinés à un usage privé et personnel et ce, pendant la
      durée de l’inscription de l’Utilisateur&nbsp;; toute utilisation à des fins
      commerciales et publicitaires est strictement interdite.
      &nbsp;
      Toute représentation, reproduction et exploitation partielle
      ou intégrale du contenu et des services du Site, sans l’autorisation préalable
      et expresse de l’exploitant, constituerait une contrefaçon et engagerait la
      responsabilité de son auteur et ce, conformément aux dispositions en vigueur
      en la matière. 
      Il est rappelé que l’Utilisateur qui reproduit, copie ou
      publie le contenu protégé doit citer l’auteur et sa source. 
      </td></tr><tr><td>
      <br/>
      <strong>TRAITEMENT DE DONNÉES</strong>
      <strong>&nbsp;</strong>
      L’Éditeur traite, les données collectées ou à collecter
      auprès de l’Utilisateur, en ce compris des données à caractère personnel,
      conformément aux exigences de la législation et la réglementation en vigueur
      ainsi qu’à la Politique de Confidentialité et pour les besoins suivants :
      &nbsp;
      La Fourniture des services proposés sur
      le Site (réservation et achat de Pass/E-billet/Ticket en ligne)&nbsp;;Le maintien, l’analyse et l’améliorer
      des services du Site ;La gestion administrative des comptes
      personnels des utilisateurs ;Notification et communication avec les
      Utilisateurs et l’envoi d’une newsletter avec l’approbation spécifique de l’Utilisateur
      à ce sujet ;A des fins statistiques et ce, pour
      l’optimisation et l’adaptation des services proposés aux besoins des
      Utilisateurs;Respect des obligations légales et aux
      fins de toutes réclamations ou procédures éventuelles en justice.
      
      
      
      
      
      &nbsp;
      En outre, les mêmes données peuvent être communiquées
      et/ou divulguées :
      &nbsp;
      dans les cas prévus par la loi et
      notamment la législation et réglementation relatives à la protection des
      données personnelles ;après anonymisation ou avec
      l’autorisation préalable des personnes concernées&nbsp;;à des tiers exerçant des tâches
      spécifiques en relation avec les finalités susmentionnées et dans le but de
      protéger les intérêts légitimes de l’Éditeur;
      
      
      &nbsp;
      Ces données seront conservées pour la durée nécessaire à
      la fourniture des services proposés par le Site et au vu des finalités
      susmentionnées. La suppression des comptes personnels entraine la suppression
      de toutes données personnelles y afférentes et ce, dans un délai de 90 jours.
      
      &nbsp;
      Conformément à ce qui est prévu par la Politique de
      Confidentialité, l’Utilisateur dispose d’un droit d’accès, de rectification,
      de suppression et d’opposition relativement à ses données personnelles.
      </td></tr><tr><td>
      <br/>
      <strong>MODIFICATION </strong>
      &nbsp;
      L’Éditeur se réserve le droit de modifier
      unilatéralement, à tout moment et sans préavis le contenu des présentes CGVU.
      L’Éditeur s’engage à informer les Utilisateurs de toute modification par
      courrier électronique ainsi qu’à travers sa publication sur le Site.
      &nbsp;
      L’Utilisateur s’oblige alors à prendre connaissance des
      modifications considérées et à les accepter, le cas échéant, selon la même
      procédure d’acceptation que la version précédente.
      &nbsp;
      Les nouvelles
      CGVU prennent effet dès leur mise en ligne sur le Site. 
      </td></tr><tr><td>
      <br/>
      <strong>LOI APPLICABLE – DIFFÉRENDS</strong>
      <strong>&nbsp;</strong>
      Les présentes Conditions Générales de Vente et d’Utilisation
      sont régies et interprétées conformément au droit tunisien.
      &nbsp;
      Tout différend découlant des CGVU ou en relation avec
      celles-ci relèvera de la compétence exclusive des tribunaux de Tunis I.
      </td></tr><tr><td>
      <br/>
      <strong>CONTACT</strong>
      &nbsp;
      Les Utilisateurs pourront soumettre toute question,
      réclamation ou requête relativement à ces CGVU en envoyant une demande écrite
      par courrier électronique à l’adresse <strong>[contact@wecode.land] </strong>ou
      par courrier postal à l’adresse suivante :
      &nbsp;
      <strong>P/WeCode Land</strong>
      <strong>63, Avenue Habib
      Bourguiba</strong>
      <strong>1000, Bab Bhar,
      Tunis, Tunisie.</strong>
      <strong>&nbsp;</strong>
      </td></tr></tbody></table>
    </div>
  );
  }
}
export default Cgu;