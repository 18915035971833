import React from 'react';
import {
  Row, Col, Input, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  TabContent,
  TabPane, Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import API from 'helpers/api.jsx'
import { notify } from 'helpers/common.jsx';
import empty_pic_room from "assets/img/course-default.png";
import empty_pic_host from "assets/img/empty_pic_classroom.png";
import NotificationAlert from 'react-notification-alert';
import TagsInput from 'components/Tags/TagsInput.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classnames from "classnames";
import InputMask from 'react-input-mask';
// import Script from 'react-load-script';
// import Autocomplete from 'react-google-autocomplete';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import ReactGA from 'react-ga';

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

class UpgradeToHost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params && this.props.match.params.id,
      profile: {},
      name: '',
      description: '',
      hostDescription: '',
      phoneNumber: '',
      size: 0,
      startDate: moment(),
      endDate: moment(),
      isFileUploading: false,
      classroomLogo: {
        preview: empty_pic_room,
        data: {},
        container: {}
      },
      coursePhoto: {
        preview: empty_pic_host,
        data: {},
        container: {}
      },
      equipments: [],
      album: [],
      address: {
        name: '',
        area: '',
        city: '',
        state: ''
      },
      location: { lat: 0, lng: 0 },
      modalConfirmAction: false,
      activeTab: "1",
      photoChanged: false,
      inputKey : Date.now(),
      hostPhotoinputKey : 0
    };
    this.notificationAlert = React.createRef();
  }

  getPosition = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }),
      err => console.log(err)
    );
  }

  componentWillMount = async () => {
    ReactGA.initialize('UA-173264282-1');
    const { id } = this.state;
    // set initial position
    this.getPosition();
    // set Authorization header
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    if (id !== localStorage.getItem('WeCodeLand_user')) {
      this.props.history.push({ pathname: BASEDIR + "/dashboard" })
    }
    else { await this.getProfileDetails(id); }

  }
  getProfileDetails = async (userId) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const profile = await API.get(`Profiles/getUser`, {
        params: { userId: userId, access_token: accessToken }
      });

      if (profile.status === 200) {
        if (profile.data.profileInfos && profile.data.profileInfos.upgradeRequest && profile.data.profileInfos.upgradeRequest.length>0 && profile.data.profileInfos.upgradeRequest.filter(obj => { return obj.status === "Pending" }).length > 0) {
          this.props.history.push({ pathname: BASEDIR + "/dashboard" })
        }
        if (profile.data.profileInfos && profile.data.profileInfos.phone > 0 ){
          this.setState({
            phoneNumber: profile.data.profileInfos.phone
          });

        }
        this.setState({
          hostDescription: profile.data.profileInfos.description,
          profile: profile.data.profileInfos
        });
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  selectedEquipments = (equipments) => {
    this.setState({ equipments: equipments })
  }

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value
    })
  }

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date,
      endDate: this.state.startDate.isBefore(this.state.endDate, 'day') ? this.state.endDate : date
    });
  }

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date
    });
  }


  addClassroom = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem('WeCodeLand_jwt');

    const request = {
      room: {
        name: this.state.name,
        description: this.state.description,
        size: this.state.size,
        from: this.state.startDate.format('YYYY-MM-DD'),
        to: this.state.endDate.format('YYYY-MM-DD'),
        location: this.state.location,
        address: this.state.address,
        logo: this.state.classroomLogo.container,
        album: this.state.album,
        equipments: this.state.equipments,
        profileId: localStorage.getItem('WeCodeLand_user')
      },
      profileId: localStorage.getItem('WeCodeLand_user'),
      newRole: "host",
      profile: {
        description: this.state.hostDescription
      }
    };
    if (this.state.photoChanged && this.state.coursePhoto && this.state.coursePhoto.container && Object.keys(this.state.coursePhoto.container).length>0) {
      request.profile.image = this.state.coursePhoto.container
    }
    if (this.state.phoneNumber !== ''){
      request.profile.phone = this.state.phoneNumber
    }

    API
      .post(`upgradeRequests`, request, {
        params: { access_token: accessToken }
      })
      .then(res => {
        if (res.status === 200) {
          ReactGA.event({
            category: 'Upgrade Request',
            action: 'Requested upgrade to Host'
          })
          notify({ type: 'success', message: "success" }, this);
          this.props.history.push(BASEDIR + `/profile/${this.state.id}`);
        } else {
          this.setState({ modalConfirmAction: false })
          throw res.status;
        }
      })
      .catch(error => {
        console.log('error:', error);
        const {
          response: {
            data: {
              error: { message }
            }
          } = {}
        } = error;
        notify({ type: 'danger', message: message === "Authorization Required" ? "You are not able to upgrade" : message }, this);
        this.setState({ modalConfirmAction: false });
      });
  }

  //** UPLOAD COURSE IMAGE */
  photoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { classroomLogo } = this.state;
    let uploadedPhoto;
    if (e.target.name === "classroomLogo") {
      classroomLogo.preview = URL.createObjectURL(file);
      classroomLogo.data = file;
      uploadedPhoto = {
        file: classroomLogo,
        name: "classroomLogo"
      };
    }

    const fileData = new FormData();
    fileData.append('file', uploadedPhoto.file.data);

    try {let imageProfile = await API.post(`${SERVERDIR}Containers/img/upload`, fileData);
    if (imageProfile.status === 200) {
      uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
      await this.setState({
        [uploadedPhoto.name]: uploadedPhoto.file
      });
    } else {
      throw imageProfile.status;
    }} catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      if (message.includes("contentType"))
      notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey : Date.now()
      })
    } finally {
    }
  }

  //** UPLOAD COURSE IMAGE */
  hostPhotoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { coursePhoto } = this.state;
    let uploadedPhoto;
    if (e.target.name === "coursePhoto") {
      coursePhoto.preview = URL.createObjectURL(file);
      coursePhoto.data = file;
      uploadedPhoto = {
        file: coursePhoto,
        name: "coursePhoto"
      };
    }

    // const file = this.state.coursePhoto
    const fileData = new FormData();
    fileData.append('file', uploadedPhoto.file.data);

    try {let imageProfile = await API.post(`${SERVERDIR}Containers/img/upload`, fileData);
    if (imageProfile.status === 200) {
      console.log("uploadedPhoto: ", imageProfile.data);
      // notify({ type: 'success', message: "success" }, this);
      uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
      await this.setState({
        [uploadedPhoto.name]: uploadedPhoto.file,
        photoChanged: true
      });
    } else {
      throw imageProfile.status;
    }} catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      if (message.includes("contentType"))
      notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        hostPhotoinputKey : this.state.hostPhotoinputKey +1
      })
    } finally {
    }
  }

  resetClassroom = () => {
    this.setState({
      name: '',
      description: '',
      size: 0,
      startDate: moment(),
      endDate: moment(),
      isFileUploading: false,
      classroomLogo: {
        preview: empty_pic_room,
        data: {},
        container: {}
      },
      equipments: [],
      album: [],
      address: {
        name: '',
        area: '',
        city: '',
        state: ''
      },
      location: { lat: 0, lng: 0 }
    })
  }

  handelOnUploadFile = async (e) => {
    e.preventDefault();
    const files = e.currentTarget.files
    this.setState({
      isFileUploading: true
    });

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i])
    }


    let filesUploaded = await API.post(`${SERVERDIR}Containers/img/upload`, formData)
    if (filesUploaded.status === 200) {
      // flatten array
      const albumfiles = filesUploaded.data.result.files;
      const album = Object.keys(albumfiles).reduce(function (r, k) {
        return r.concat(albumfiles[k].flat());
      }, []);

      this.setState({
        album: album,
        isFileUploading: false
      })
      notify({ type: 'success', message: "files Uploaded" }, this);
    } else {
      throw filesUploaded.status;
    }
  }

  /**
	 * Get the city and set the city input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
  getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
	/**
	 * Get the area and set the area input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
  getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
	/**
	 * Get the address and set the address input value to the one selected
	 *
	 * @param addressArray
	 * @return {string}
	 */
  getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.setState({
      address: {
        name: (address) ? address : '',
        area: (area) ? area : '',
        city: (city) ? city : '',
        state: (state) ? state : ''
      },
      location: { lng: lngValue, lat: latValue }
    })
  };

  onPlacesSelected = async (result) => {
    const place = (await geocodeByPlaceId(result.place_id))[0];
    // console.log("places: ", place);
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    console.log({
      address: {
        name: (address) ? address : '',
        area: (area) ? area : '',
        city: (city) ? city : '',
        state: (state) ? state : ''
      },
      location: { lng: lngValue, lat: latValue }
    });
     this.setState({
       address: {
         name: ( address ) ? address : '',
         area: ( area ) ? area : '',
     		city: ( city ) ? city : '',
     		state: ( state ) ? state : ''
       },
       location: {lng: lngValue, lat: latValue}
     })
  };
  toggleModalConfirmAction = (e) => {
    e.preventDefault();
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction
    });
  }

  firstSubmit = async (event) => {
    event.preventDefault();
    if (this.state.hostDescription.length === 0) {
      notify({ type: 'danger', message: "Please enter a description" }, this);
    }
    if (this.state.phoneNumber.toString().length !== 8) {

      notify({ type: 'danger', message: "Please enter a phone number" }, this);
    }
    if ((this.state.phoneNumber.toString().length === 8) && (this.state.hostDescription.length > 0)) {
      this.setState({
        activeTab: "2"
      });
    }
  };
  goBack = (event) => {
    event.preventDefault();
    this.setState({
      activeTab: "1",
    });
  };

  updatePhoneValue = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("validity :",target.checkValidity());
    this.setState({
      [name]: String(value).replace(/\s/g, ''),
    });
  }

  handleSubmitButtonClick=(event) =>{
    if (this.myForm.checkValidity() === false) {
        return null;
    }
    else
        this.toggleModalConfirmAction(event);

    this.setState({ validated: true });
}


  render() {
    const { photoChanged, profile, isFileUploading } = this.state;
    return (
      <div id="host-upgrade">
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Nav id="register-tabs" className="justify-content-center" tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "1",
                "custom-nav-link": true
              })}
            >
              1.Founder Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "2",
                "custom-nav-link": true
              })}
            >
              2.Startup information
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content">
              <Row>
                <Col xs={12} md={12}>

                  <div className="page-title">
                    <div className="float-left">
                      <h1 className="title">Upgrade my profile to host</h1>
                    </div>
                  </div>


                  <div className="row margin-0">
                    <div className="col-12">
                      <section className="box ">
                        <div className="content-body pt-4">
                          <form >
                            <div className="row">
                              <div className="mintit-upload-image-container col-md-4">
                                <img className="mintit-upload-image" alt="" src={(profile && profile.image && Object.keys(profile.image).length > 0 && !photoChanged) ? `${SERVERDIR}containers/${profile.image.container}/download/${profile.image.name}` : this.state.coursePhoto.preview} />
                                <div className="mintit-upload-image-overlay">
                                  <p><input type="file" key={this.state.hostPhotoinputKey} name="coursePhoto" id="coursePhoto" onChange={this.hostPhotoChangeHandler} style={{ marginTop: '10px' }} /></p>
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="form-group">
                                  <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Description</label>
                                  <Input type="textarea" rows="6" maxLength="350" cols="50" className="form-control custom-fixed-textarea" name="hostDescription" value={this.state.hostDescription} onChange={this.updateInputValue} id="inputDescription" placeholder="Tell us more about you" required />
                                </div>
                                <div className="form-group">
                                  <label >Phone Number</label>
                                  <InputMask className="form-control" pattern='^[0-9]{2}[ ][0-9]{3}[ ][0-9]{3}$' mask="99 999 999" maskChar={null} name="phoneNumber" value={this.state.phoneNumber} onChange={this.updatePhoneValue} id="inputPhoneNumber" placeholder="Phone Number" required />
                                </div>
                              </div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="row" style={{ justifyContent: "center" }}>
                                <button type="button" onClick={() => this.props.history.push(BASEDIR + `/profile/${this.state.id}`)} className="btn btn-primary" style={{ backgroundColor: "#444444", borderRadius: "7px", BoxShadow: "0px 0px 12px", width: "40%" }}>Cancel</button>
                                <button disabled={isFileUploading} onClick={this.firstSubmit} className="btn btn-primary" style={{ width: "40%", borderRadius: "7px" }}>Next</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </section></div>
                  </div>
                </Col>

              </Row>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="content">
              <Row>
                <Col xs={12} md={12}>

                  <div className="page-title">
                    <div className="float-left">
                      <h1 className="title" style={{ textTransform: "none" }}>Add your first classroom</h1>
                    </div>
                  </div>


                  <div className="row margin-0">
                    <div className="col-12">
                      <section className="box ">
                        <header className="panel_header">
                        </header>
                        <div className="content-body">
                          <form id="myForm" onSubmit={this.addClassroom} method="post" ref={form => this.myForm = form}>
                            <div className="row">

                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                                <div className="form-group">
                                  <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Name</label>
                                  <input type="text" name="name" maxLength="40" onChange={this.updateInputValue} value={this.state.name} className="form-control" id="imputTitle" placeholder="Classroom name" style={{ borderRadius: "4px" }} required />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Description</label>
                                  <Input type="textarea" rows="5" maxLength="350" cols="50" className="form-control custom-fixed-textarea" name="description" value={this.state.description} onChange={this.updateInputValue} id="inputDescription" placeholder="Tell us about the course" required />
                                </div>

                              </div>

                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" >
                                <div className="form-group">
                                  <label htmlFor="inputObjective" style={{ fontWeight: "bold" }}>Logo (optional)</label>
                                  <div className="mintit-upload-image-container">
                                    <img className="mintit-upload-image" alt="" src={this.state.classroomLogo.preview} />
                                    <div className="mintit-upload-image-overlay">
                                      <p><input type="file" key={this.state.inputKey} name="classroomLogo" id="classroomLogo" onChange={this.photoChangeHandler} style={{ marginTop: '10px' }} /></p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="row">
                              <div className="form-group col-md-3">
                                <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Number of places (maximum)</label>
                                <input type="number" min="0" name="size" onChange={this.updateInputValue} value={this.state.size} className="form-control" id="imputTitle" placeholder="Maximum places" style={{ borderRadius: "4px" }} required />
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Start Date</label>
                                <DatePicker
                                  fixedHeight
                                  name="startDate"
                                  dateFormat="DD/MM/YYYY"
                                  selected={this.state.startDate}
                                  onChange={this.handleStartDateChange}
                                  minDate={moment()}
                                  required />
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>End Date</label>
                                <DatePicker
                                  fixedHeight
                                  name="endDate"
                                  dateFormat="DD/MM/YYYY"
                                  selected={this.state.endDate}
                                  onChange={this.handleEndDateChange}
                                  minDate={this.state.startDate}
                                  required />
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Location</label>
                                {/*<Autocomplete
                							className="form-control"
                							onPlaceSelected={ this.onPlaceSelected }
                						/>*/}
                                <GooglePlacesAutocomplete
                                  onSelect={this.onPlacesSelected}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ['tn','LY'],
                                    }
                                    /* types: ['address'] */
                                  }}
                                />
                                {/*<input id="location-autocomplete" type="text" name="address" value={this.state.address} className="form-control" placeholder="Title" style={{ borderRadius: "4px" }} required />*/}
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="imputSkills" style={{ fontWeight: "bold" }}>Equipment</label>
                                <TagsInput key={this.state.equipments.length} placeholder="Enter an equipment and press the + button to add it" selectedTags={this.selectedEquipments} tags={this.state.equipments} />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="imputDomains" style={{ fontWeight: "bold" }}>Classroom’s pictures (you can add one or muliple ones)</label>
                                <Input type="file" multiple name="album" id="inputFile" onChange={this.handelOnUploadFile} style={{ marginTop: '10px' }} />
                              </div>
                            </div>
                            <div className="row" style={{ justifyContent: "center" }}>
                              <button type="button" onClick={this.goBack} className="btn btn-primary" style={{ backgroundColor: "#444444", BoxShadow: "0px 0px 12px", borderRadius: "7px", width: "40%" }}>Cancel</button>
                              <button disabled={isFileUploading} onClick={this.handleSubmitButtonClick} className="btn btn-primary" style={{ width: "40%", borderRadius: "7px" }}>Save</button>
                            </div>
                          </form>
                        </div>
                      </section></div>
                  </div>
                </Col>

              </Row>
            </div>
          </TabPane>
        </TabContent>
        <Modal isOpen={this.state.modalConfirmAction} toggle={this.toggleModalConfirmAction}>
          <ModalHeader>Adding a new classroom</ModalHeader>
          <ModalBody>
            <h3>Are you sure you want to save these changes</h3>
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              form="myForm" key="submit" htmltype="submit"
            >
              {"Confirm"}
            </Button>{" "}
            <Button className="save-button" color="secondary" onClick={this.toggleModalConfirmAction}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UpgradeToHost;
