import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./../../helpers/common.jsx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


class ModalTags extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.close}
        className={this.props.className}
      >
        <ModalHeader
          style={{ padding: "0.5rem 0.5rem" }}
          toggle={this.props.close}
        >
          <span style={{ fontWeight: "bold" }}>{this.props.title}</span>
        </ModalHeader>
        <ModalBody>
          <div>
            {this.props.tags &&
              this.props.tags.length > 0?
             this.props.tags.map((tag, key) => {
                return (
                  tag.name ?
                     <span key={key} className="tags-listing">
                    • {tag.name}
                    <br />
                  </span>
                : <span key={key} className="tags-listing">
                    • {tag}
                    <br />
                  </span>
                );
              })
            :
            ''}
          </div>
        </ModalBody>
        <ModalFooter style={{ alignSelf: "center" }}>
          <Button
            style={{ height: "35px", width: "98px", padding: "0px" }}
            color="primary"
            onClick={this.props.close}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
ModalTags.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  close: PropTypes.func,
  title: PropTypes.string
};
export default props => (
  <AppContext.Consumer>
    {Helper => <ModalTags {...props} helper={Helper} />}
  </AppContext.Consumer>
);
