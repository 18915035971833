import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import API from '../../helpers/api.jsx'
/* import Loader from "react-loader-spinner";
import { members } from 'variables/general/members.jsx'; */
import { notify } from "../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import empty_pic_room from "assets/img/default-logo.png";
/* import { ExportToCsv } from "export-to-csv"; */

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ReactPaginate from 'react-paginate';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import ModalProfilesList from './../../components/modals/ModalProfilesList.jsx';
import NumericInput from 'react-numeric-input';

import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Autosuggest from 'react-autosuggest';
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;
// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.title;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    <span style={{color: "black", fontWeight: "bold"}}>{suggestion.title}</span>
    <span style={{color: "blue"}}>{" "}{moment(suggestion.start).format("DD/MM/YY")}</span>
    <span style={{color: "green"}}>{" "}{suggestion.sessionBooking && suggestion.sessionBooking[0] && suggestion.sessionBooking[0].bookingRoom && suggestion.sessionBooking[0].bookingRoom.name}</span>
    <span style={{color: "purple"}}>{" "}{suggestion.tutors && suggestion.tutors.fullName}</span>
  </div>
);
const shouldRenderSuggestions=() =>{
  return true;
}

class PromoCodeManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      limit: 20,
      count: 0,
      totalCount: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      classroomToPreview: {},
      actionValue: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      idClassroomToAccept: "",
      indexRevoke: "",
      activated: false,
      modalCoupon: false,
      maxUsage: 1,
      price: 0.01,
      expiryDate: moment(),
      profilesList: [],
      modalProfiles: false,
      modalSessions: false,
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      sessionsList: [],
      isSearchResult: false,
      searchQuery: "",
      isLoading: false,
      value: '',
      suggestions: [],
      allSuggestions: [],
      selectedSessionId: "",
      couponType: "1",
    };
  }

  async componentWillMount() {
    await this.getCoupons(this.state.active);
    this.getAllSuggestions();
    this.setState({
      suggestions: this.getSuggestions(this.state.value)
    });
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleDateInput = (date) => {
    this.setState({
      expiryDate: date
    });
  }
  updatePriceInputValue = async (valueAsNumber) => {
    
    this.setState({
      price: valueAsNumber
    })
  }

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getCoupons = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        searchQuery: ""
      });
      const coupons = await API.get(`Coupons/list`, {
        params: {
            limit: limit,
            skip: skip,
            access_token: accessToken,
            /* filter: {
                include :["sessions","users"]
            } */
        },
    });

      if (coupons.status === 200) {
        console.log("coupons content :", coupons.data);
        const pageCount = Math.ceil(coupons.data.count / this.state.limit);
        this.setState({ count: pageCount, totalCount: coupons.data.count });

        this.setState(
          {
            csvData: coupons.data.coupons,
            active: key,
            loading: false,
            coupons: coupons.data.coupons,
          },
          () => {}
        );
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };
 

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedCoupons(selectedPage + 1);
    } else {
      this.getCoupons(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/admin/promoCodeManagement/${selectedPage + 1}`
    );
  };

  toggleModalCoupon=()=>{
     this.setState ({modalCoupon : !this.state.modalCoupon })
  }

  createCoupon= async(e)=>{
    e.preventDefault();
    const request={
        maxNumberUsers: this.state.maxUsage,
        expiryDate: this.state.expiryDate
    }
    if(this.state.couponType==="1"){
      request.price = this.state.price
    }
    if(this.state.couponType==="2"){
      request.sessionId = this.state.selectedSessionId
    }
    try {
      this.setState({
        isLoading: true
      })
      let result = await API.post(`Coupons/newCoupon`, request, {
        params: { access_token: localStorage.getItem("WeCodeLand_jwt") },
      });
      if (result.status === 204) {
        console.log("Action done SUCCESSFULLY");
        notify(
          { type: "success", message: "WeCoin code generated"},
          this
        );
        this.toggleModalCoupon();
        this.getCoupons(1);
        this.setState({
          loading: false
        })
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      await this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {
      this.setState({
        isLoading: false
      })
    }
  }
  openModalProfiles=(users)=>{
    this.setState({
      modalProfiles : !this.state.modalProfiles,
      profilesList : users
    })
  }
  openModalSessions=(sessions)=>{
    this.setState({
      modalSessions : !this.state.modalSessions,
      sessionsList : sessions
    })
  }
  toggleModalProfiles=()=>{
    this.setState({
      modalProfiles : !this.state.modalProfiles
    })
  }
  toggleModalSessions=()=>{
    this.setState({
      modalSessions : !this.state.modalSessions
    })
  }

  getAllSuggestions = async () => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const allSuggestions = await API.get(`Sessions`, {
        params: { access_token: accessToken,
                  filter :{
                            where: {status:"Hosted"},
                            include :["sessionCourse", "tutors","subscriptionsSession", {
                              relation: "sessionBooking",
                              scope: {
                                  include: "bookingRoom"
                              }
                          } ]
                          } 
                }
      });

      if (allSuggestions.status === 200) {
        this.setState({
          allSuggestions: allSuggestions.data
        });
        /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

   // Teach Autosuggest how to calculate suggestions for any given input value.
   getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const {allSuggestions} = this.state;
    const filteredSuggestions = allSuggestions.filter(item =>
      item.title.toLowerCase().slice(0, inputLength) === inputValue
    );
    return filteredSuggestions;
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };
// Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion}) =>{
    console.log("suggestion",suggestion);
    this.setState({
      selectedSessionId : suggestion.id
    })
  };
  typeSelectionChange = (event) => {
    this.setState({couponType: event.target.value});
    if(event.target.value==="1"){
      this.setState({value: ''});
    }
  }

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const {
      coupons,
      actionValue,
      suggestions,
      value
    } = this.state;
    const SessionsInputProps = {
      placeholder: 'No session selected',
      value,
      onChange: this.onChange
    };
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">WeCoin Management</h1>
                </div>
                <div className="float-right">
                  <h1>
                      <Button id="add-button" onClick={this.toggleModalCoupon}>
                        <i className="fa fa-plus"></i>&nbsp; Add new WeCoin code
                      </Button>
                  </h1>
                </div>
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {/* {this.state.isSearchResult ? (
                          <span className="results-count">
                            {this.state.totalCount} results were found
                          </span>
                        ) : null} */}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th /* className="fullName" */>Code used</th>
                              <th className="centered-column-text">Number of usage</th>
                              <th className="centered-column-text">Sessions used in</th>
                              <th className="centered-column-text">Expiry status</th>
                              <th className="centered-column-text">Last date of usage</th>
                              <th className="centered-column-text">
                                Generated code date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(coupons)}

                            {coupons.map((coupon, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    className="text-cell"
                                  >
                                      {coupon && coupon.code}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    <span className="clickable purple" onClick={()=>this.openModalProfiles(coupon && coupon.users)}>{coupon && coupon.users && coupon.users.length} users</span>
                                  </td>
                                  <td className="centered-column-text text-cell">
                                  <span className="clickable purple" onClick={()=>this.openModalSessions(coupon && coupon.sessions)}>{coupon && coupon.users && coupon.sessions.length} sessions</span>
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {coupon && coupon.status}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {moment(coupon && coupon.lastDateUsage).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="centered-column-text">
                                {moment(coupon && coupon.createdAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.state.modalCoupon} toggle={this.toggleModalCoupon}>
            <ModalHeader>Add new WeCoin code</ModalHeader>
            <ModalBody>
            <div>
                    <form id="myForm" onSubmit={this.createCoupon}>
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                            Maximum number of usage
                          </label>
                          <br/>
                          <label>{"Please insert maximum number (at least 1)"}</label>
                          <br/>
                         <input type="number" min="1" name="maxUsage" onChange={this.updateInputValue} value={this.state.maxUsage} className="form-control" placeholder="Maximum usage" style={{ borderRadius: "4px" }} required />
                          <br/>
                          <div className="form-group">
                            <label className="form-label bold">Please Select one option</label>
                            <br/>
                            <select onChange={this.typeSelectionChange} value={this.state.couponType}>
                                <option value="1">Amount </option>
                                <option value="2">For a specific session</option>
                            </select>
                            </div>
                          {(this.state.couponType === "1")?
                            <div>
                            <label className="form-label bold" htmlFor="field-1">
                              Price
                            </label>
                            <br/>
                            <label>{"Please insert the WeCoin code price"}</label>
                            <br/>
                            {/* <input type="number" min="0.1" name="price" onChange={this.updateInputValue} value={this.state.price} className="form-control" id="imputTitle" placeholder="Insert price here" style={{ borderRadius: "4px" }} required /> */}
                            <NumericInput  min={0.1} onChange={this.updatePriceInputValue} value={this.state.price} className="form-control" id="imputTitle" placeholder="Insert price here" style={{ borderRadius: "4px" }} required step={0.1} precision={2}/>
                            <br/>
                          </div>
                          :null}
                          {(this.state.couponType === "2")?
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                             Select targeted session
                          </label>
                          <br/>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      shouldRenderSuggestions={shouldRenderSuggestions}
                      onSuggestionSelected={this.onSuggestionSelected}
                      inputProps={SessionsInputProps}
                    />
                    <br/>
                    </div>
                    :null}
                          <label className="form-label bold" htmlFor="field-1">Expiry Date</label>
                          <br/>
                          <label>{"Please insert the WeCoin code expiry date"}</label>
                          <br/>
                            <DatePicker
                            fixedHeight
                            name="expiryDate"
                            dateFormat="DD/MM/YYYY"
                            selected={this.state.expiryDate}
                            onChange={this.handleDateInput}
                            minDate={moment()}
                            required />
        
                        </div>
                    </form>
                  </div>
            </ModalBody>
            <ModalFooter>
             <span className="cancel-search-modal" onClick={this.toggleModalCoupon}>Cancel</span>
              <Button
                className="save-button"
                color="primary"
                form="myForm" key="submit" htmltype="submit"
                disabled={this.state.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>

          <ModalProfilesList
           isOpen={this.state.modalProfiles}
           toggle={this.toggleModalProfiles}
           close={this.toggleModalProfiles}
           title={"Users who used this WeCoin code"}
           profiles={this.state.profilesList}/>

<Modal
      isOpen={this.state.modalSessions}
      toggle={this.toggleModalSessions}
    >
      <ModalHeader
        style={{ padding: "0.5rem 0.5rem" }}
        toggle={this.toggleModalSessions}
      >
        <span style={{ fontWeight: "bold" }}>Sessions used in</span>
      </ModalHeader>
      <ModalBody>
        <div>
        {this.state.sessionsList.map((session, key) => {
                        return (
          <span
           key={key}
           className="tags-listing">
           {session.title}<br/>
          </span>)})}
        </div>
      </ModalBody>
      <ModalFooter>
      <Button
          color="primary save-button" onClick={this.toggleModalSessions}>
              OK
      </Button>
      </ModalFooter>
    </Modal>
      </div>
    );
  }
}

export default PromoCodeManagement;
